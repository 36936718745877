// SearchForm js
import React, {Component} from 'react';
import { Api } from '../common/variables';
import webservice from '../services/webservice';

// Redux set data
import { connect } from "react-redux";

// Language
import utils from '../common/utils';

class SearchForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchTexts : utils.Language( 'lang_searchProducts', this.props.language ),
			searchText  : '',
			searchSuggestion : []
		};

		setTimeout(
			function() {
				this.setState({
					searchTexts : utils.Language( 'lang_searchProducts', this.props.language )
				});

				var query   = window.location.search.substring( 1 );
				if ( query ) {
					var vars    = query.split( 'keyword=' );
					if ( vars[1] ) {
						let keyword = vars[1].replace(/%20/g, ' ');
							keyword = keyword.replace( /[^a-zA-Z0-9\s]/g, "" );
						this.setState({
							searchText : keyword,
						});
					}
				}
			}
			.bind( this ),
			50
		);
	}

	handlePageChange( suggestion = null ) {
		let searchValue = this.searchValue.value;
		if ( suggestion ) {
			searchValue = suggestion;
		}

		if ( '' !== searchValue || '' === searchValue ) {
			this.setState({
				type       : 'error',
				searchText : searchValue,
			});
			window.location.href = '/search?keyword=' + searchValue;
		} else {
			this.setState({
				type       : 'error',
				searchTexts : utils.Language( 'lang_searchEmptyKeyword', this.props.language )
			});
		}
	}

	ToggleMenu=()=>{
		this.setState({
			isActive: ! this.state.isActive
		})
	}

	// save search input value
	saveSearch = async ( e ) => {
		this.setState({
			searchSuggestion: [],
			searchText: e.replace( /[^a-zA-Z0-9\s.]/g, "" )
		})

		if ( e.replace( /[^a-zA-Z0-9\s.]/g, "" ) ) {
			let params = {
				'lang' : this.props.language,
				'vendorID': this.props.vendorInfos.data.id,
				'keyword' : e.replace( /[^a-zA-Z0-9\s.]/g, "" )
			}
			const getSearchSuggestion = await webservice.httpPost( Api.method.getSearchSuggestion, params );
			if ( 'ok' === getSearchSuggestion.status ) {
				this.setState({
					searchSuggestion: getSearchSuggestion.data,
				})
			}
		} else {
			this.onBlurStatus();
		}
	}

	onBlurStatus = () => {
		setTimeout( () => {
			this.setState({
				searchSuggestion: [],
			})
		}, 100 );
	}

	render() {
		return (
		<div className="wrapper wrapper_1200 menu-right-section">
			<div className="menu-right-section-div">
				<span className={this.state.isActive===true ? 'icon-search showinmobile mobile-search show' : 'icon-search showinmobile mobile-search hide'} onClick={this.ToggleMenu}></span>
				<div className={this.state.isActive===true ? "search_wrapper show" : "search_wrapper hide" + ( 'error' === this.state.type ? 'search_error' : '' )}>
					
					<div className="search_input-wrapper">
						<input type="text" maxLength="50" className="search_input" placeholder={ this.state.searchTexts } value={ this.state.searchText || '' } ref={ el => this.searchValue=el }
							onChange = { e => { this.saveSearch( e.target.value ) } }
							onKeyPress={ event => {
								if ( event.key === 'Enter' ) {
									this.handlePageChange();
								}
							}}
							onBlur={() => {
								this.onBlurStatus();
							}}
						/>
						<button className="btn btn-radius search-btn" onClick={ ( e ) => { this.handlePageChange() } }><span className="icon_search"></span></button>
					</div>
				</div>
				<ul className="autocompletelist">
				{
				( this.state.searchSuggestion && this.state.searchSuggestion.length > 0 ) ?
					this.state.searchSuggestion.map( (suggestion, index) => {
						return (
						<li className="searchsuggestion-list" key={index} onClick={ ( e ) => { ; this.handlePageChange( suggestion ) } }>
						{ suggestion }
						</li>
						)
					})
				: null
				}
				</ul>
			</div>
		</div>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

export default connect( mapStateToProps, null )(SearchForm);