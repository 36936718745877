export const Nepali = {
	lang_productCategories: 'सामाग्रीका प्रकारहरू', // for web only
	lang_viewMore: 'अधिक हेर्नुहोस्', // for web only
	lang_allCategories: 'सामाग्रीका प्रकार', // for web only
	lang_contactUs: 'हामीलाई सम्पर्क गर्नुहोस', // for web only
	lang_systemError: 'प्रणाली त्रुटि।', // for web only
	lang_404Page: 'पृष्ठ भेटिएन।', // for web only
	lang_emailInUse: 'ईमेल ठेगाना पहिले नै प्रयोगमा छ।', // for web only
	lang_itemFound: 'सामाग्रीहरू फेला पर्‍यो', // for web only
	lang_searchResult: 'खोज परिणामहरू', // for web only
	lang_lastname_req: 'अन्तिम नाम आवश्यक छ।', // for web only
	lang_firstname_req: 'पहिलो नाम आवश्यक छ।', // for web only
	lang_sortBy: 'क्रमबद्ध द्वारा:', // for web only
	lang_popularity: 'लोकप्रियता', // for web only
	lang_nearestLocation: 'नजिकको ठेगाना', // for web only
	lang_ResetCodeExpire: 'तपाईंको कोडको समयावधि सकियो, कृपया पुन: बुझाउनुहोस्।', // for web only
	lang_IacceptGharbanau: 'म स्वीकार गर्दछु घरबनाउ डट कम', // for web only
	lang_qnsAnswerBy: 'अन्य प्रश्नहरूको उत्तर', // for web only
	lang_techInfo: "जानकारी", // for web only
	lang_cashOnDeliveryTextFooter: 'अहिले अर्डर गर्नुहोस् र सामाग्री प्राप्त गर्दा नगद भुक्तान गर्नुहोस्।', // for web only
	lang_cashOnDeliveryTextCart: 'अहिले अर्डर गर्नुहोस् र सामाग्री प्राप्त गर्दा नगद भुक्तान गर्नुहोस्।', // for web only
	lang_customerSupport: 'ग्राहक सहायता', // for web only
	lang_customerSupportText: 'हाम्रो टोली तपाईको सेवाको लागि सँधै तयार छ।', // for web only
	lang_onTimeDelivery: 'समयमा ढुवानी', // for web only
	lang_onTimeDeliveryText: 'हाम्रो लक्ष्य चाँडो भन्दा चाँडो तपाईको अर्डर ढुवानी गर्नु हो।', // for web only
	lang_loginToAddCart: 'कृपया कार्टमा थप्नका लागि लगईन गर्नुहोस्।',// for web only
	lang_loginToAddWishlist: 'ईच्छा सूचीमा थप्न कृपया लगईन गर्नुहोस्।', // for web only
	lang_followUs: 'सामाजिक सञ्जाल', // for web only
	lang_gharbanauDotCom: 'Gharbanau.com', // for web only
	lang_footerLocation: 'नयाँ बानेश्वर, काठमाडौं, नेपाल', // for web only
	lang_footerDownloadText: 'Gharbanau.com मोबाइल APP डाउनलोड गर्नुहोस्।', // for web only
	lang_bottomFooterImageInfo: 'यस वेबसाइटमा प्रयोग गरिएका छविहरू र जानकारीलाई कुनै पनि तरिकाले प्रतिलिपि गर्न वा पुन: उत्पादन गर्न कडा निषेध गरिएको छ।', // for web only
	lang_bottomFooterCopyright: 'प्रतिलिपि अधिकार', // for web only
	lang_bottomFooterCopyrightdesc : '| सबै अधिकार सुरक्षित। साइट निर्माण र मर्मत गर्ने:',  // for web only
	lang_companyName: 'ट्यूलिप्स टेक्नोलोजीज', // for web only
	lang_signWithFB: 'फेसबुकको साथ साइन इन गर्नुहोस्', // for web only
	lang_quickLinks: 'लिंकहरू', // for web only
	lang_needLogin: 'तपाईंले कुनै पनि सदस्यका सुविधाहरू पहुँच गर्न अघि लग इन गर्न आवश्यक पर्दछ।', // for web only
	lang_searchEmptyKeyword: 'खोजशब्द खाली छ', // for web only
	lang_emailPhone: "ईमेल/फोन नम्बर", // for web only
	lang_firstName: "पहिलो नाम", // for web only
	lang_lastName: "अन्तिम नाम", // for web only
	lang_productReviews: "सामाग्री समीक्षा",  // for web only
	lang_search: 'खोज्नुहोस्', // for web only
	lang_userPassError: 'तपाईंको प्रयोगकर्ता नाम वा पासवर्ड गलत छ।', // for web only
	lang_PostQuestionsSuccess: 'तपाईंको प्रश्न बुझाइएको छ।', // for web only
	lang_shippingAddressDifferent: 'फरक सामाग्री पाउने ठेगाना', // for web only
	lang_no_shipping: 'ढुवानी सुविधा छैन', // for web only
	lang_phoneDuplicate: 'मोबाइल नम्बर पहिले नै प्रयोगमा छ।', // for web only
	lang_SupplierProduct: 'बिक्रेताको सामाग्री हेर्नुहोस्', // for web only
	lang_404PageDesc: 'तपाईं यहाँ हुनुहुन्छ किनकि युआरएल हाम्रो प्रणालीमा फेला परेन।', // for web only
	lang_404PageDescSec: 'कृपया url जाँच गर्नुहोस् वा निम्न बटन क्लिक गर्नुहोस्।', // for web only
	lang_viewOnMap: 'नक्शामा हेर्नुहोस्', // for web only
	lang_lastUpdatedOn: 'अन्तिम अपडेट गरिएको', // for web only
	lang_newsTitle: 'समाचार', // for web only
	lang_newsNotFound: 'पोष्ट भेटिएन', // for web only
	lang_faqs: 'नियमित सोधिने प्रश्नहरु', // for web only
	lang_priceValidTill: 'मूल्य मान्य', // for web only
	lang_otherNews: 'अन्य समाचार / ब्लगहरू', // for web only
	lang_newsAndBlog: 'समाचार र ब्लगहरू', // for web only
	lang_readMore: 'थप पढ्नुहोस्', // for web only
	lang_logo: 'लोगो',
	lang_Category: 'प्रकार',
	lang_categories: 'सामाग्रीका प्रकार',
	lang_featuredProducts: 'फिचर्ड सामाग्रीहरू',
	lang_nofeaturedProducts: 'फिचर्ड सामाग्रीहरू उपलब्ध छैनन',
	lang_newProducts: 'नयाँ सामाग्रीहरु',
	lang_View: 'हेर्नुहोस्',
	lang_All: 'सबै',
	lang_viewAll: 'सबै हेर्नुहोस्',
	lang_mostPopularProducts: 'लोकप्रिय सामाग्रीहरू',
	lang_home: 'गृह पृष्ठ',
	lang_about: 'हाम्रो बारेमा',
	lang_findSuppliers: 'बिक्रेता खोज्नुहोस्',
	lang_howToSell: 'कसरी बेच्ने ?',
	lang_termsAndConditions: 'नियम र सर्तहरू',
	lang_privacyPolicy: 'गोपनीयता नीति',
	lang_LogIn: 'लग इन',
	lang_LogOut: 'बाहिर निस्कने',
	lang_signUp: 'साइन अप',
	lang_isEmptyQuantity: 'कृपया मात्रा हाल्नुहोस',
	lang_cartEntryError: 'कार्टमा राख्दा समस्या आयो।',
	lang_CartEntrySuccess: 'कार्टमा सामाग्री राखियो।',
	lang_CartUpdateSuccess: 'कार्ट सफलतापूर्वक अपडेट गरियो।',
	lang_Success: 'अर्डर सफल',
	lang_km: ' किमी',
	lang_meter: ' मिटर',
	lang_currency: 'रू.',
	lang_featureBrand: 'फिचर्ड ब्राण्ड',
	lang_brand: 'ब्राण्ड',
	lang_products: 'प्रमाणित सामाग्रीहरू',
	lang_LoadMore: 'अधिक हेर्नुहोस्',
	lang_deleteSuccess: 'मेटाउन सफल भयो',
	lang_deleteError: 'मेटाउन त्रुटि भयो',
	lang_updateSuccess: 'अपडेट सफल भयो',
	lang_updateError: 'अपडेट गर्दा त्रुटि भयो',
	lang_checkoutText: 'चेक आउट गर्नुहोस्',
	lang_taxText: '*सबै करहरू समावेश गरिएको छ',
	lang_recommendedMethod: 'भुक्तानी विधि',
	lang_cashOnDelivery: 'सामाग्री लिदा नगद भुक्तानी विधि',
	lang_cashOnDeliveryText: 'अहिले अर्डर गर्नुहोस् र सामाग्री प्राप्त गर्दा नगद भुक्तान गर्नुहोस्।',
	lang_confirmOrder: 'अर्डर गर्नुहोस्',
	lang_address: 'ठेगाना',
	lang_city: 'न.पा/गा.पा',
	lang_addressDetail: 'ठेगाना विवरण',
	lang_billingAddress: 'बिजक जारी गर्ने ठेगाना',
	lang_billingShippingAddress: 'बिजक जारी गर्ने ठेगाना र सामाग्री प्राप्त गर्ने ठेगाना',
	lang_errorBillingAddress: "बिजक ठेगाना लेखिएको छैन",
	lang_errorShippingAddress: "ढुवानी ठेगाना लेखिएको छैन",
	lang_shippingAddress: 'सामाग्री पाउने ठेगाना',
	lang_Edit: 'सम्पादन गर्नुहोस्',
	lang_Cancel: 'फर्कनुहोस्',
	lang_Ok: 'ठिक छ',
	lang_Submit: 'बुझाउनुहोस्',
	lang_Add: 'हाल्नुहोस',
	lang_cancelOrder: 'के तपाईं यो अर्डर रद्द गर्न चाहनुहुन्छ ?',
	lang_cancelVendorOrder: 'के तपाईं यो सामाग्री रद्द गर्न चाहनुहुन्छ ?',
	lang_update: 'अपडेट गर्नुहोस्',
	lang_fullName: "पुरा नाम",
	lang_errorFullname: 'पुरा नाम राख्नुहोस्',
	lang_phoneNumber: 'फोन नम्बर',
	lang_mobileNumber: 'मोबाइल नम्बर',
	lang_emailAddress: 'इमेल',
	lang_altMobile: 'वैकल्पिक मोबाइल नम्बर',
	lang_altPhone: 'वैकल्पिक फोन नम्बर',
	lang_Designation: 'पद',
	lang_errorMobileNumber: 'मोबाइल नम्बर राख्नुहोस्',
	lang_errorCity: 'न.पा/गा.पा नाम राख्नुहोस्',
	lang_errorCompanyName: 'कम्पनीको नाम राख्नुहोस्',
	lang_CompanyName: 'कम्पनीको नाम',
	lang_PAN: 'पान नं',
	lang_Street: 'सडक',
	lang_errorStreet: 'सडकको नाम राख्नुहोस्',
	lang_region: 'प्रदेश',
	lang_errorRegion: 'प्रदेशको नाम राख्नुहोस्',
	lang_Country: 'देश',
	lang_errorCountry: 'देशको नाम राख्नुहोस्',
	lang_saveAddressSuccess: 'ठेगाना सन्चय भयो',
	lang_gender: 'लिङ्ग',
	lang_profileTitle: 'मेरो विवरण',
	lang_myCart: 'मेरो कार्ट',
	lang_profile: 'विवरण',
	lang_editProfile: 'सम्पादन',
	lang_addressTitle: 'ठेगानाको विवरण',
	lang_NoAddress: 'ढुवानी वा बिजक ठेगाना मिलेको छैन।',
	lang_ThanksMsg: 'खरीदको लागि धन्यबाद',
	lang_ThanksSecondMsg: 'तपाईंको अर्डर नम्बर',
	lang_MakeAmountReadyMsg: 'सामाग्री प्राप्त गर्दा कृपया निम्न रकम तयार राख्नुहोला',
	lang_OrderSummaryLabel: 'अर्डर सारांश',
	lang_ViewOrderLabel: 'अर्डर हेर्नुहोस्',
	lang_Continue: 'जारी राख्नुहोस्',
	lang_ContinueShoppingLabel: 'किनमेल जारी राख्नुहोस्',
	lang_MoreDetailOrder: 'थप विवरणहरूको लागि, तपाईंको वितरण स्थिति अन्तर्गत ट्र्याक गर्नुहोस्',
	lang_NoInternet: 'इन्टरनेट छैन',
	lang_ContinueShopping: 'किनमेल जारी राख्नुहोस्',
	lang_CartUpdate: 'कृपया चेकआउट गर्न पहिले अपडेट गर्नुहोस्',
	lang_CartEmpty: 'तपाईंको कार्टमा कुनै वस्तु छैन।',
	lang_CartEmptyMessage: 'कार्ट खाली छ, तपाईंले चेकआउट गर्न सक्नुहुन्न।',
	lang_dashboardTitle: 'ड्यासबोर्ड',
	lang_Wishlist: 'इच्छा सुची',
	lang_orderpage: 'अर्डर',
	lang_addWishlist: 'सामाग्री सफलतापूर्वक इच्छा सूचीमा थपियो।',
	lang_removeWishlist: 'सामाग्री सफलतापूर्वक इच्छा सूचीबाट हटाइयो।',
	lang_viewedProducts: 'हेरिएका सामाग्रीहरू',
	lang_myReviews: 'मेरो समीक्षा',
	lang_emptyViewedProduct: 'तपाईंले कुनै सामाग्री हेर्नु भएको छैन।',
	lang_emptyUserWishlist: 'तपाईंले आफ्नो ईच्छा सूचीमा कुनै पनि सामाग्री राख्नु भएको छैन।',
	lang_emptyDeliveredItem: 'अहिलेसम्म कुनै सामाग्री वितरित भएको छैन।',
	lang_emptyReviewRatingMessage: 'सामाग्री समीक्षा',
	lang_orders: 'अर्डर',
	lang_settingsAccount: 'खाता सेटिङ',
	lang_AccountText: 'खाता',
	lang_settingsShipping: 'ढुवानीको सेटिंग्स',
	lang_myProducts: 'मेरो सामाग्रीहरू',
	lang_productNotFound: 'सामाग्री भेटिएन',
	lang_supplierProductNotFound: 'यो बिक्रेताले सामाग्री राख्नु भएको छैन ।',
	lang_otherSupplierProduct: 'अन्य बिक्रेताको सामाग्री हेर्नुहोस्।',
	lang_paymentMethod: 'भुक्तानी विधि',
	lang_memberSince: 'सदस्यता मिती',
	lang_emptyProducts: 'कुनै सामाग्री थपिएको छैन',
	lang_editAddress: 'ठेगाना सम्पादन गर्नुहोस्',
	lang_defaultBilling: 'पूर्वनिर्धारित बिजक ठेगाना',
	lang_defaultShipping: 'पूर्वनिर्धारित ढुवानी ठेगाना',
	lang_nearestLandmark: 'नजिकको ल्यान्डमार्क',
	lang_sameBillingShipping: 'ढुवानी ठेगाना र बिजक ठेगाना एउटै हो',
	lang_yes: "हुन्छ",
	lang_no: "होईन",
	lang_soldby: "बिक्रेता",
	lang_ratingsReviews: "रेटिंग्स र समीक्षा",
	lang_description: "विवरण",
	lang_Certification: "प्रमाणीकरण",
	lang_Attachment: "ब्रोशर तथा स्पेसिफिकेसनहरु",
	lang_Delivery: "वितरण विकल्प",
	lang_ReturnWarranty: "फिर्ता र वारेन्टी",
	lang_WarrantyPeriod: "वारेन्टी अवधि",
	lang_WarrantyPolicy: "वारेन्टी नीति",
	lang_ExchangePeriod: "फिर्ता अवधि",
	lang_ExchangePolicy: "फिर्ता नीति",
	lang_NoWarranty: "कुनै वारेन्टी छैन",
	lang_Specification: "बिस्तृत विवरण",
	lang_footerHome: 'गृह पृष्ठ',
	lang_footerWishlist: 'इच्छा सुची',
	lang_footerCart: 'कार्ट',
	lang_footerAccount: 'खाता',
	lang_writeReview: 'समीक्षा लेख्नुहोस्',
	lang_orderDetails: 'अर्डरको विवरण',
	lang_btnCancelOrder: 'यो अर्डर रद्द गर्नुहोस्',
	lang_btnCancelItem: 'यो सामाग्रीलाई रद्द गर्नुहोस्',
	lang_recentOrders: 'हालैका अर्डर',
	lang_orderDate: 'मिति',
	lang_orderItems: 'सामाग्रीहरू',
	lang_orderDetail: 'विवरण',
	lang_orderStatus: 'स्थिति',
	lang_orderStatusPending: 'नयाँ अर्डर',
	lang_orderStatusProcessing: 'प्रशोधन हुँदै',
	lang_orderStatusShipped: 'ढुवानी हुँदै',
	lang_orderStatusDelivered: 'वितरित',
	lang_orderStatusCancelled: 'रद्द गरिएको',
	lang_updateStatus: 'यस अर्डरको स्थिति अपडेट गर्नुहोस्?',
	lang_errorRegularPrice: 'सामाग्रीको मूल्य खाली हुन सक्क्दैन',
	lang_errorCategory: 'प्रकार चयन गर्नुहोस्',
	lang_errorProductName: 'सामाग्रीको नाम खाली हुन सक्क्दैन',
	lang_selectCategory: 'प्रकार चयन गर्नुहोस्',
	lang_errorDefaultFee: 'पूर्वनिर्धारित ढुवानी शुल्क प्रविष्ट गर्नुहोस्',
	lang_errorDuplicateCategory: 'चयन गरिएको प्रकार पहिले नै प्रयोग गरिएको थियो',
	lang_ConfirmDeleteAll: 'के तपाईं निश्चित रूपले सबै मेटाउन चाहनुहुन्छ?',
	lang_submitReview: 'समीक्षा बुझाउनुहोस्',
	lang_ratenReview: 'तपाईंको विक्रेतालाई समीक्षा गर्नुहोस्',
	lang_shippingText: 'ढुवानी हुँदैछ',
	lang_shippingFee: 'ढुवानी शुल्क',
	lang_subTotal: 'जम्मा',
	lang_Total: 'कुल',
	lang_Apply: 'सन्चय गर्नुहोस्',
	lang_Order: 'अर्डर',
	lang_addToCart: 'कार्टमा राख्नुहोस्',
	lang_searchProducts: 'सामाग्रीहरू खोज्नुहोस्',
	lang_totalRecord: 'पूरा रेकर्ड',
	lang_homeDelivery: 'ढुवानी',
	lang_backtohome: 'गृह पृष्ठ',
	lang_saveChanges: 'सन्चय गर्नुहोस्',
	lang_errorUsername: 'ईमेल वा मोबाईल नम्बर राख्नुहोस्।',
	lang_errorPassword: 'पासवर्ड राख्नुहोस्।',
	lang_loginMessage: 'तपाईंले कुनै पनि पहुँच गर्नु अघि लग इन गर्न आवश्यक पर्दछ।',
	lang_forgotPass: 'पासवर्ड बिर्सनुभयो',
	lang_password: 'पासवर्ड',
	lang_NewPassword: 'नयाँ पासवर्ड',
	lang_ConfirmPassword: 'पुन पासवर्ड',
	lang_Alreadylogin: "पहिले नै सदस्य हुनुहुन्छ? लग इन गर्नुहोस्।",
	lang_signUpMessage: "तपाईंको खाता छैन भने साइन अप गर्नुहोस्।",
	lang_emailOrPhone: "ईमेल वा मोबाइल नम्बर",
	lang_review: "समीक्षा",
	lang_purchasedOn: "खरीद मिति",
	lang_buyNow: "खरिद गर्नुहोस्",
	lang_uploadImage: "फोटो अपलोड गर्नुहोस्",
	lang_changeImage: "फोटो बदल्नुहोस्",
	lang_uploadPImage: "सामाग्रीको फोटो",
	lang_uploadCertification: "प्रमाणपत्र अपलोड गर्नुहोस्",
	lang_changeCertification: "प्रमाणपत्र परिवर्तन गर्नुहोस्",
	lang_uploadCImage: "प्रमाणपत्रको फोटो",
	lang_ExtraCImage: "ब्रोशर तथा अन्य सामाग्री",
	lang_Facebook: "फेसबुक",
	lang_Twitter: "ट्वीटर",
	lang_Instagram: "इन्स्टाग्राम",
	lang_CompanyRegistration: "कम्पनी दर्ताको प्रमाणपत्र",
	lang_AccountInformation: "खाताको जानकारी",
	lang_BannerImage: "ब्यानर फोटो",
	lang_AboutCompany: "कम्पनीको बारेमा",
	lang_WhyChooseUs: "हाम्रा मुख्य विशेषताहरु",
	lang_CompanyDocument: "कम्पनीको कागत पत्र",
	lang_PANRegistration: "पानको प्रमाणपत्र",
	lang_ContactPerson: "सम्पर्क व्यक्ति",
	lang_ResetPassword: "रिसेट पासवर्ड",
	lang_ResetPasswordIntro: "कृपया तपाईंको ईमेल तल हाल्नुहोस्। तपाईंको ईमेलमा हामी पासवर्ड रिसेट गर्ने निर्देशन पठाउने छौ।",
	lang_ResetCode: "रिसेट कोड",
	lang_ResetCodeIntro: "कृपया तपाईंको ईमेलमा प्राप्त गर्नुभएको रिसेट कोड हाल्नुहोस्।",
	lang_SendResetCode: "रिसेट कोड पठाउनुहोस्",
	lang_NewPasswordIntro: "कृपया तपाईंको खातामा लग इन गर्न नयाँ पासवर्ड हाल्नुहोस्।",
	lang_AddNew: "नयाँ",
	lang_DefaultFee: "पूर्वनिर्धारित शुल्क",
	lang_DefaultDistance: "पूर्वनिर्धारित दूरी",
	lang_IncrementPerKM: "प्रति किमी वृद्धि",
	lang_KM: " किमी",
	lang_Days: " दिन",
	lang_IMBuyer: "म ग्राहक हुँ",
	lang_IMSeller: "म विक्रेता हुँ",
	lang_orderDetailText: "सामाग्री र ढुवानी शुल्क",
	lang_Suppliers: "बिक्रेता",
	lang_searchSuppliers: "बिक्रेता खोज्नुहोस्",
	lang_noSuppliers: "कुनै बिक्रेता भेटिएन।",
	lang_blankAccount: 'नमस्ते, घरबनाउ डट कममा तपाईंलाई स्वागत छ !',
	lang_logSignMessage: 'लग ईन वा साइन अप गर्नुहोस्।',
	lang_moreaboutus: 'हाम्रो बारेमा जानकारी',
	// lang_terms: 'म घरबनाउ डट कमका नियम र सर्तहरू स्वीकार गर्दछु',
	lang_GeneralInfo: 'सामान्य जानकारी',
	lang_productNameEng: 'सामाग्रीको नाम अंग्रेजीमा',
	lang_productNameNep: 'सामाग्रीको नाम नेपालीमा',
	lang_descriptinoEng: 'अंग्रेजीमा वर्णन',
	lang_descriptinoNep: 'नेपालीमा वर्णन',
	lang_selectBrand: 'ब्राण्ड छान्नुहोस',
	lang_selectUnit: 'एकाइ चयन गर्नुहोस्',
	lang_RegularPrice: 'नियमित मूल्य',
	lang_SalePrice: 'बिक्री मूल्य',
	lang_Unit: 'एकाइ',
	lang_MinimumOrderQuantity: 'न्यूनतम अर्डर मात्रा',
	lang_Attributes: 'विशेषताहरु',
	lang_ItemSize: 'आकार',
	lang_ItemWeight: 'तौल',
	lang_ItemWidth: 'चौडाई',
	lang_ItemHeight: 'उचाई',
	lang_ItemLength: 'लम्बाइ',
	lang_Dimension: 'आयाम',
	lang_Inventory: 'स्टक',
	lang_SKU: 'एस के यु',
	lang_stockStatus: 'स्टकको स्थिति',
	lang_Warranty: 'वारेन्टी',
	lang_WarrantyType: 'वारेन्टीको प्रकार',
	lang_WarrantyPeriodEng: 'वारेन्टी अवधि अङ्ग्रेजीमा',
	lang_WarrantyPeriodNep: 'वारेन्टी अवधि नेपालीमा',
	lang_WarrantyPolicyEng: 'वारेन्टी नीति अङ्ग्रेजीमा',
	lang_WarrantyPolicyNep: 'वारेन्टी नीति नेपालीमा',
	lang_Exchange: 'फिर्ता',
	lang_ExchangeType: 'फिर्ताको प्रकार',
	lang_ExchangePeriodEng: 'फिर्ता अवधि अङ्ग्रेजीमा',
	lang_ExchangePeriodNep: 'फिर्ता अवधि नेपालीमा',
	lang_ExchangePolicyEng: 'फिर्ता नीति अङ्ग्रेजीमा',
	lang_ExchangePolicyNep: 'फिर्ता नीति नेपालीमा',
	lang_ShippingSetting: 'ढुवानीको सेटिङ',
	lang_ShippingType: 'ढुवानी प्रकार',
	lang_ShippingInfo: 'ढुवानीको बारे जानकारी',
	lang_DeliveryDays: 'अनुमानित वितरण मिति',
	lang_DefaultShipping: 'प्रति एकाइ पूर्वनिर्धारित ढुवानी शुल्क(रु)',
	lang_DefaultDistanceKM: 'पूर्वनिर्धारित दूरी(किमी)',
	lang_IncrementKM: 'प्रति किलोमिटर शुल्क वृद्धि',
	lang_MinRange: 'न्यूनतम परिमाण',
	lang_MaxRange: 'अधिकतम परिमाण',
	lang_AddShipping: '+ ढुवानी शुल्क दायरा थप्नुहोस्',
	lang_questionAnswer: 'प्रश्न उत्तर',
	lang_Uploading: 'अपलोड गर्दै...',
	lang_save: 'सन्चय गर्नुहोस्',
	lang_shippingFeeRange: 'ढुवानी शुल्क सीमा',
	lang_tryDifferentKeywords: 'अन्य शब्दहरु प्रयोग गरी पुन खोजी गर्नुहोस्।',
	lang_filterProduct: 'सामाग्रीहरु फिल्टर गर्नुहोस्',
	lang_errorAddress: 'ठेगाना खाली छ',
	lang_Nepali: 'नेपाली | Nepali',
	lang_English: 'अंग्रेजी | English',
	lang_Draft: 'खेस्रा',
	lang_Published: 'प्रकाशित भएको',
	lang_Help: 'मद्दत',
	lang_QuestionsAbout: 'यस सामाग्रीको बारेमा प्रश्न र उत्तरहरू',
	lang_PostQuestions: 'सामाग्रीको बारेमा प्रश्न थप्नुहोस्',
	lang_FillYourQuestion: 'तपाईंको प्रश्न भर्नुहोस्',
	lang_loginToAsk: 'कुनै प्रश्न सोध्नु अघि तपाईंले लग इन गर्नुपर्छ।',
	lang_changePassword: 'पासवर्ड परिवर्तन',
	lang_oldPassword: 'पुरानो पासवर्ड',
	lang_newPassword: 'नयाँ पासवर्ड',
	lang_confirmPassword: 'पासवर्ड पुष्टि गर्नुहोस्',
	lang_by: 'द्वारा',
	lang_Unanswered: 'अनुत्तरित',
	lang_GiveAnswer: 'जवाफ दिनुहोस्',
	lang_noConnectionTitle: 'इन्टरनेट छैन',
	lang_noConnection: 'कृपया इन्टर्नेट जडान जाँच गर्नुहोस्',
	lang_Refresh: 'रिफ्रेस गर्नुहोस्',
	lang_searchNo: 'माफ गर्नुहोस्, कुनै मिल्दो सामाग्री फेला परेन',
	lang_gotoCart: 'कार्टमा जानुहोस्',
	lang_conformationCartDelete: 'के तपाईं निश्चित रूपमा कार्टबाट सामाग्री हटाउन चाहनुहुन्छ?',
	lang_loading: 'लोड हुँदै...',
	lang_switchLanguage: 'Switch Language',
	lang_qty: 'परिमाण',
	lang_Iaccept: 'म स्वीकार गर्दछु,',
	lang_on: 'मा',
	lang_vendor: 'विक्रेता',
	lang_placedOn: 'मिती',
	lang_paidBy: 'भुक्तानी विधि',
	lang_of: 'न.',
	lang_emailDuplicate: 'इमेल पहिले नै दर्ता भैसकेको छ।',
	lang_resultsFor: 'परिणाम',
	lang_history: 'पुरानो खोज',
	lang_errorResetPassword: 'ईमेल ठेगाना भर्नुहोस्',
	lang_noQuestionAnswer: 'अहिलेसम्म कुनै प्रश्नहरू छैनन्।',
	lang_GlobalLoading: 'लोड हुँदै...',
	lang_reviewSucceed: 'सफलतापूर्वक समीक्षा गरियो।',
	lang_reviewFailed: 'समीक्षामा गल्ती।',
	lang_ResetCodeEmptyMessage: 'रिसेट कोड हाल्नुहोस्।',
	lang_ResetCodeInvalid: 'रिसेट कोड मिलेको छैन',
	lang_ResetCodeError: 'केहि गलत भयो कृपया पुन: प्रयास गर्नुहोस्।',
	lang_passwordChanged: 'सफलतापूर्वक पासवर्ड परिवर्तन भयो।',
	lang_passwordResetPasswordError: 'पासवर्ड र पुन पासवर्ड एउटै राख्नुहोस्।',
	lang_oldPasswordRequired: 'पुरानो पासवर्ड आवश्यक छ।',
	lang_PasswordRequired: 'पासवर्ड आवश्यक छ।',
	lang_confirmPasswordRequired: 'पुन पासवर्ड राख्नुहोस्।',
	lang_errorFullname_shipping: 'ढुवानीमा पूर्ण नामको गल्ती',
	lang_errorMobileNumber_shipping: 'ढुवानीमा मोबाइल नम्बर खाली हुन सक्क्दैन',
	lang_errorRegion_shipping: 'ढुवानीमा प्रदेशको नाम खाली हुन सक्क्दैन',
	lang_errorCity_shipping: 'ढुवानीमा शहरको नाम खाली हुन सक्क्दैन',
	lang_errorAddress_shipping: 'ढुवानीमा ठेगानाको नाम खाली हुन सक्क्दैन',
	lang_successPasswordChanged: 'पासवर्ड सफलतापूर्वक परिवर्तन भयो',
	lang_ques: 'प्रश्न',
	lang_answ: 'उत्तर',
	lang_product: 'सामाग्री',
	lang_price: 'मूल्य',
	lang_productUpdate: 'सामाग्री सफलतापूर्वक अपडेट गरियो',
	lang_settingupdate: 'सेटिंग सफलतापूर्वक अपडेट गरियो',
	lang_invalidLoginCredentials: 'लग इन प्रमाणहरू मिलेन',
	lang_AddAddress: 'ठेगाना लेख्नुहोस्',
	lang_results: 'परिणाम',
	lang_price_high_to_low: 'उच्च देखी कम मुल्य सम्म',
	lang_price_low_to_high: 'कम देखी उच्च मुल्य सम्म',
	lang_nearest_to_farthest: 'नजिक देखी टाढाको',
	lang_farthest_to_nearest: 'टाढा देखी नजिकको',
	lang_male: 'पुरुष',
	lang_female: 'महिला',
	lang_others: 'अन्य',
	lang_pending: 'नयाँ अर्डर',
	lang_processing: 'प्रशोधन हुँदै',
	lang_shipped: 'ढुवानी हुँदै',
	lang_delivered: 'वितरित',
	lang_wishlistRemove: 'के तपाइँ यस वस्तुलाई इच्छा सुचीबाट हटाउन चाहानुहुन्छ?',
	lang_terms: 'कृपया नियम र सर्तहरू स्वीकार गर्नुहोस्',
	lang_fullName_req: 'पूरा नाम आवश्यक छ',
	lang_mobileNumber_req: 'मोबाइल नम्बर आवश्यक छ',
	lang_emailAddress_req: 'ईमेल आवाश्यक छ',
	lang_password_req: 'पासवर्ड आवश्यक छ।',
	lang_invalidemail: 'अवैध ईमेल ठेगाना।',
	lang_passReset_emptyEmail: 'ईमेल राख्नुहोस्।',
	lang_passReset_invalidEmail: 'ईमेल ठेगाना मिलेन।',
	lang_passReset_serverError: 'केही गल्ति भयो कृपया पुन: प्रयास गर्नुहोस्।',
	lang_totalShippingCost: 'कुल ढुवानी शुल्क',
	lang_sku: 'एस्.के.यु',
	lang_width: 'चौडाई',
	lang_length: 'लम्बाइ',
	lang_height: 'उचाई',
	lang_size: 'आकार',
	lang_weight: 'तौल',
	lang_minOrderError: 'न्यूनतम अर्डर मात्रा',
	lang_rating: 'रेटिंग',
	lang_ratings: 'रेटिंग्स',
	lang_cms: 'सेमी',
	lang_free: 'सित्तैमा',
	lang_paid: 'तिर्न पर्ने',
	lang_sellerWarranty: 'वारेन्टी छ',
	lang_sellernoWarranty: 'वारेन्टी छैन',
	lang_hasExchange: 'एक्सचेन्ज छ',
	lang_hasNoExchange: 'एक्सचेन्ज छैन',
	lang_inStock: 'स्टकमा छ',
	lang_outStock: 'स्टकमा छैन',
	lang_or: 'वा',
	lang_youMayLike: 'अन्य सम्बन्धित सामाग्रीहरु',
	lang_textCopied: 'प्रतिलिपि गरिएको',
	lang_invalidOldPAssword: 'अवैध पुरानो पासवर्ड',
	lang_in: 'मा',
	lang_profileUpdate: 'विवरण सफलतापूर्वक अपडेट भयो',
	lang_addressSave: 'सफलतापूर्वक सन्चय भयो',
	lang_state: 'प्रदेश',
	lang_errorState_shipping: 'कृपया शिपिंग राज्य प्रविष्ट गर्नुहोस्',
	lang_errorState: 'कृपया राज्य प्रविष्ट गर्नुहोस्',
	lang_conformationProductDelete: 'के तपाइँ यस समाग्री हटाउन चाहनुहुन्छ ?',
	lang_conformationSettingDelete: 'के तपाइँ यो सेटिंग हटाउन चाहनुहुन्छ ?',
	lang_profileImage: 'प्रोफाइल छवि',
	lang_district: 'जिल्ला',
	lang_noQuestion: 'एउटा पनि प्रश्न सोधिएको छैन ।',
	lang_latestUpdate: 'पछिल्लो अद्यावधिक',
	lang_verified_supplier: 'प्रमाणित',
	lang_verified: 'प्रमाणीत',
	lang_notVerified: 'प्रमाणित नभएको',
	lang_verifiedInDropDown: 'प्रमाणित भएको',
	lang_pricing: 'शुल्क निती',
	lang_shippingExtraInfo: 'थप लाग्नेछ',
	lang_fbLogin: 'फेस्बुक लग्-इन',
	lang_DealershipCertificate: 'डिलरशिप प्रमाणपत्र',
	lang_SetLocation: 'खोजी गर्ने स्थान चयन गर्नुहोस्',
	lang_searchLocation: 'ठेगाना खोज्नुस्',
	lang_allSuppliers: 'सबै बिक्रेताहरु',
	lang_choose: 'छनौट गर्नुहोस्',	
	lang_priceList: 'मुल्य सुची',
	lang_validateCode: 'कोड प्रमाणित गर्नुहोस्',
	lang_validate: 'प्रमाणित गर्नुहोस्',
	lang_error_validation_code: 'प्रमाणित कोड ६ अङ्कको नम्बर हुनुपर्छ ।',
	lang_invalid_validation_code: 'अवैध कोड',
	lang_validateCode_title: 'हामीले तपाईंको मोबाईलमा एसएमएस मार्फत पठाएको ६ अङ्कको नम्बर प्रविष्ट गर्नुहोस्।',
	// lang_changeCompanyAddress: 'कम्पनी ठेगाना सेट गर्न क्लिक गर्नुहोस्',
	lang_changeCompanyAddress: 'कम्पनी ठेगाना',
	lang_ViewAllCategories: 'सबै सामाग्रीका प्रकार',
	lang_callNow: 'कल',
	lang_socialShare: 'सेयर गर्नुहोस्',
	lang_callNowSupplier: 'कल बिक्रेता',
	lang_sendMail: 'मेल पठाउनुहोस्',
	lang_tabDelivery: 'ढुवानी',
	lang_tabWarranty: 'वारेन्टी',
	lang_tabExchange: 'फिर्ता',
	lang_questionPlaceholder: 'तपाईंको प्रश्न यहाँ टाइप गर्नुहोस् र सबमिट क्लिक गर्नुहोस्।',
	lang_becomeASeller: 'विक्रेता बन्नुहोस्',
	lang_popularSearches: 'लोकप्रिय खोजीहरू',
}
