// PageNotFound js
import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Banner from '../components/content/banner/banner';
import MetaTags from 'react-meta-tags';

// Language
import utils from '../common/utils';

// Redux set data
import { connect } from "react-redux";

class PageNotFound extends Component {
	render() {
		return (
			<div className="page page-404">
        	<Banner data={404} />

				<div className="wrapper wrapper_1200">
					{/*For Meta tags Dynamic content*/}
					<MetaTags>
						<title>Gharbanau.com - Page not found</title>
						<meta name="keywords" content="page not found, gharbanau" />
						<meta
						name="description"
						content="A marketplace for construction materials for Nepal"
						/>
					</MetaTags>

					<div className="message-404">
						<div className="h5">{ utils.Language( 'lang_404Page', this.props.language ) }</div>
						<h1>{ utils.Language( 'lang_404PageDesc', this.props.language ) }</h1>
						<h4>{ utils.Language( 'lang_404PageDescSec', this.props.language ) }</h4>
						<Link to="/" className="btn btn-primary">{ utils.Language( 'lang_backtohome', this.props.language ) }</Link>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state
})

export default connect( mapStateToProps, null )(PageNotFound);