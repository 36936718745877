// common files for functions
import webservice from '../services/webservice';
import { Api } from '../common/variables';

// Language
import utils from '../common/utils';

// get current location position
export async function getMyLocation() {
	const userSetLocation = localStorage.getItem( 'gharbanu_location' );
	if ( userSetLocation ) {
		const location = JSON.parse( userSetLocation );
		return location.location;
	} else {
		return { latitude: 27.68949664604984, longitude: 85.33426255740814 };
		// return new Promise((resolve,reject)=>{
		// 	const location = window.navigator && window.navigator.geolocation

		// 	if ( location ) {
		// 		location.getCurrentPosition( (position) => {
		// 			resolve({
		// 				latitude: position.coords.latitude,
		// 				longitude: position.coords.longitude,
		// 			})
		// 			return;
		// 		}, (error) => {
		// 			reject({ latitude: 27.6712947, longitude: 85.3384283 })
		// 			return;
		// 		})
		// 	}
		// })
	}
}

// get current location position
export async function getVendorInfos() {
	const userSetLocation = localStorage.getItem( 'gharbanu_location' );

	let locations = {};
	if ( userSetLocation ) {
		const location = JSON.parse( userSetLocation );
		locations = location.location;
	} else {
		locations = { latitude: 27.68949664604984, longitude: 85.33426255740814 };
	}

	let host = 'tulips.gharbanau.com';
	if ( 'localhost' === window.location.hostname ) {
		if ( Api.customUrl ) {
			host = 'dev.gharbanau.com';
		}
	} else {
		host = window.location.hostname;
	}

	let params = {
		Website: host
	}

	let getVendorIDs = await webservice.httpPost( Api.method.getVendorIDs, params );
	if ( 'ok' === getVendorIDs.status ) {
		// parameter
		let params = {
			lang: 'En',
			Latitude: locations.latitude,
			Longitude: locations.longitude,
			SupplierSlug: getVendorIDs.data.Company_Slug
		}

		// fetch api data
		let getSupplierDetails = await webservice.httpPost( Api.method.getSupplierDetails, params );
		if ( 'ok' === getSupplierDetails.status ) {
			if ( Api.customUrl ) {
				return {
					status: 'ok',
					data: {
						id: 0,
						slug: '',
						website: '',
						vendor_email: '',
						contact_email: '',
						BannerArrayImage: [],
						termsandconditionlink: '',
						Primary_Color: getSupplierDetails.data.Primary_Color,
						Secondary_Color: getSupplierDetails.data.Secondary_Color,
						use_product_as_landing: false
					}
				}
			} else {
				return getSupplierDetails;
			}
		} else {
			return {
				status: 'error',
				data: {
					id: 0,
					slug: '',
					website: '',
					vendor_email: '',
					contact_email: '',
					Primary_Color: '',
					Secondary_Color: '',
					BannerArrayImage: [],
					termsandconditionlink: '',
					use_product_as_landing: false
				}
			}
		}
	} else {
		return {
			status: 'error',
			data: {
				id: 0,
				slug: '',
				website: '',
				vendor_email: '',
				contact_email: '',
				Primary_Color: '',
				Secondary_Color: '',
				BannerArrayImage: [],
				termsandconditionlink: '',
				use_product_as_landing: false
			}
		}
	}
}

// Add to cart item
export async function addItemToCart( itemID, quantity, lang ) {
	var alrtMsg = '';
	if ( ! lang ) {
		lang = 'En';
	}

	const userdetails = localStorage.getItem( 'userdetail' );
	if ( userdetails ) {
		let params = {
			ProductID: itemID,
			Quantity: parseInt( quantity )
			// Quantity: parseInt(quantity) + parseInt(1)
		}

		let cartSucceed = await webservice.httpPost( Api.method.addToCart, params );
		if ( 'ok' === cartSucceed.status ) {
			if ( 'added' === cartSucceed.cart_status ) {
				alrtMsg = utils.Language( 'lang_CartEntrySuccess', lang );
			} else {
				alrtMsg = utils.Language( 'lang_CartUpdateSuccess', lang );
			}

			return {
				message: alrtMsg,
				productsApi: true,
				count: cartSucceed.data.count
			}
		} else {
			return {
				productsApi: true,
				message: utils.Language( 'lang_cartEntryError', lang )
			}
		}
	} else {
		document.getElementById("LoginPopup").click();
		return {
			productsApi: true,
			message: '' // message: utils.Language( 'lang_loginToAddCart', lang )
		}
	}
}

//add to wishlist
export async function addToWishlist( productID, productsArray, lang ) {
	if ( ! lang ) {
		lang = 'En';
	}

	const userdetails = localStorage.getItem( 'userdetail' );
	if ( userdetails ) {
		var addedToWishlist = await webservice.httpPost( Api.method.addToWishlist, { itemId: productID });
		if ( 'singleproduct' === productsArray ) {
			if ( "ok" === addedToWishlist.status ) {
				return {
					wishlistStatus : true,
					productsApi: true,
					message : utils.Language( 'lang_addWishlist', lang )
				}
			}
		} else {
			if ( "ok" === addedToWishlist.status ) {
				let index =  productsArray.findIndex( p => p.id === productID );
				let newArray = [...productsArray];
				newArray[index]['wishlistStatus'] = true;
				return {
					productsArray: newArray,
					productsApi: true,
					message: utils.Language( 'lang_addWishlist', lang )
				}
			}
		}
	} else {
		document.getElementById("LoginPopup").click();
		return {
			productsApi: true,
			message: '' // message: utils.Language( 'lang_loginToAddWishlist', lang )
		}
	}
}

//remove from wishlist
export async function removeFromWishlist( productID, productsArray, lang )  {
	if ( ! lang ) {
		lang = 'En';
	}

	const userdetails = localStorage.getItem( 'userdetail' );
	if ( userdetails ) {
		var deleteItem = await webservice.httpPost( Api.method.deleteWishlistItem, { itemId: productID } );
		if ( 'singleproduct' === productsArray ) {
			if ( "ok" === deleteItem.status ) {
				return {
					wishlistStatus : false,
					productsApi: true,
					message : utils.Language( 'lang_removeWishlist', lang )
				}
			}
		} else {
			if ( "ok" === deleteItem.status ) {
				let index =  productsArray.findIndex( p => p.id === productID );
				let newArray = [...productsArray];
				newArray[index]['wishlistStatus'] = false;
				return {
					productsArray: newArray,
					productsApi: true,
					message: utils.Language( 'lang_removeWishlist', lang )
				}
			}
		}
	}
}