// SingleProduct js
import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import webservice from '../services/webservice';
import { Api } from '../common/variables';
/*import ProductImages from '../suppliers/singleproduct/ProductImages';*/
import ProductMeta from '../suppliers/singleproduct/ProductMeta';
import ProductDescriptionRating from '../suppliers/singleproduct/ProductDescriptionRating';
import RelatedProducts from '../suppliers/singleproduct/RelatedProducts';
// import OtherProducts from '../suppliers/singleproduct/OtherProducts';
import QuestionAnswer from '../suppliers/singleproduct/QuestionAnswer';
import MetaTags from 'react-meta-tags';
import Loader from '../common/Loader';
import ImageGallery from 'react-image-gallery';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { isMobile, isBrowser } from 'react-device-detect';

// Language
import utils from '../common/utils';

// Redux set data
import { connect } from "react-redux";
import { removeFromWishlist, addToWishlist, addItemToCart } from '../services/common';
import { setCartCount, setAlertMessage } from '../action/commonAction';
let imagesAttach = [];

class SingleProduct extends Component {
	constructor() {
		super()
		this.state = {
			singleProduct: false,
			questionAnswer: null,
			showIndex: false,
			relatedproducts: null,
			category_slug: false,
			isUserLogin : false,
			showBullets: false,
			infinite: true,
			showThumbnails: true,
			lightboxImages: [],
			attachment: [],
			metakeys: [],
			showFullscreenButton: false,
			showGalleryFullscreenButton: false,
			sameSellerProducts: true,
			showPlayButton: false,
			showGalleryPlayButton: false,
			showNav: true,
			ratingAndReview: null,
			rating: 0,
			isLoading: true,
			photoIndex: 0,
			ogImage: '',
      		isOpen: false
		}
	}

	async componentDidMount() {
		window.scrollTo({top: 0});

		var str = Api.backEndapiUrl;
		var strrepl = str.replace(/\/+$/, '');
		this.setState({ url : strrepl + this.props.match.url });

		const userdetails = localStorage.getItem( 'userdetail' );
		if ( userdetails ) {
			const details = JSON.parse( userdetails );
			if ( details.userID ) {
				this.setState({
					isUserLogin : true,
				});
			}
		}

		let params = {
			lang: this.props.language,
			ProductSlug: this.props.match.params.slug,
			Latitude: this.props.geoLocation.latitude,
			Longitude: this.props.geoLocation.longitude
		}
		let products = await webservice.httpPost( Api.method.getProductDetail, params );

		if ( products.status === 'ok' ) {
			if ( isMobile ) {
				// window.location = "gharbanauapp://SingleProduct/" + products.data.id;
				if ( document.getElementById( "appLink" ) ) {
					document.getElementById( "appLink" ).href = "gharbanauapp://SingleProduct/" + products.data.id;
				}
				
			}

			if ( products.data.attachment_fullpath ) {
				imagesAttach.push( products.data.attachment_fullpath );
			}

			if ( products.data.category_name ) {
				this.setState({ metakeys: [...this.state.metakeys, products.data.category_name] })
			}

			if ( products.data.brand ) {
				this.setState({ metakeys: [...this.state.metakeys, products.data.brand] })
			}

			var myobj = document.getElementById("footer_bottom-right");
			if ( true === products.data.soldby.subscribed && 0 === this.props.vendorInfos.data.id ) {
	  			myobj.style.display = 'none';
			} else {
	  			myobj.style.display = 'block';
			}

			this.setState({
				products: products.data,
				itemId: products.data.id,
				// featimages: products.data.images2 ? products.data.images2[0].image_fullpath : '',
				// imgGallery: images,
				// productsname: products.data.name,
				category_slug: products.data.category_slug,
				category_name: products.data.category_name,
				meta_description: products.data.meta_description,
				singleProduct: true,
				productMeta: {
					salePrice: products.data.salePrice,
					regularPrice: products.data.regularPrice,
					rating: 0,
					name: products.data.name,
					// distance: products.data.distance,
					// distanceType: products.data.distanceType,
					brand: products.data.brand,
					shippingInfo: products.data.shippingInfo,
					shippingType: products.data.shippingType,
					itemInStock: products.data.itemInStock,
					isVerified: products.data.isVerified,
					is_verified: products.data.is_verified,
					soldby: products.data.soldby,
					minorder: ( products.data.minimumOrderQuantity && '0' !== products.data.minimumOrderQuantity ) ? products.data.minimumOrderQuantity : 1,
					itemId: products.data.id,
					units: products.data.units,
					weight: products.data.item_weight,
					categoryinfo: products.data.category_description,
					update_date: products.data.update_date,
					updated_days: products.data.update_date_days,
					salePriceValidTill: products.data.salePriceValidTill,
				},
				deliveryOptions: {
					deliveryTime: products.data.deliveryTime,
					hasWarranty: products.data.hasWarranty,
					warrantyPeriod: products.data.warrantyPeriod,
					warrantyPolicy: products.data.warrantyPolicy,
					exchangePeriod: products.data.exchangePeriod,
					exchangePolicy: products.data.exchangePolicy
				},
				productdetails: {
					name: products.data.name,
					description: products.data.description,
					sku: products.data.sku,
					brand: products.data.brand,
					rating: 0,
					height: products.data.item_height,
					length: products.data.item_length,
					size: products.data.item_size,
					width: products.data.item_width,
					weight: products.data.item_weight
				}
			})

			// this.getSupplierOtherProducts( products.data.id, products.data.soldby.supplier_id, products.data.category_id );
			this.getProductMoreDetails( products.data.id, products.data.category_id, products.data.seller_id );
			this.getProductImages( products.data.id, products.data.category_id );
			this.getRelatedProducts( products.data.id, products.data.category_id );
		} else {
			document.location.href = Api.backEndapiUrl;
		}

		let QAParam = {
			productID: this.state.itemId
		}
		let questionAnswer = await webservice.httpPost( Api.method.getQuestionAnswerList, QAParam );
		if ( 'ok' === questionAnswer.status ) {
			this.setState({
				questionAnswer: questionAnswer.data
			})
		}

		if ( 'error' === questionAnswer.status ) {
			if ( 'QUESTION_ANSWER_NOT_FOUND' === questionAnswer.message ) {
				this.setState({
					questionAnswer: null
				})
			}
		}
	}

	// Get product images
	getProductImages = async ( productID, CategoryID ) => {
		let imagesParam = {
			Product_Id  : productID,
			Category_Id : CategoryID
		}

		const images = [];
		let getProductImages = await webservice.httpPost( Api.method.getProductImages, imagesParam );
		if ( 'ok' === getProductImages.status ) {
			if ( getProductImages.data.images ) {
				getProductImages.data.images.map( ( img, index ) => {
					return(
						images.push({
							original : img,
							thumbnail : img
						})
					)
				});
			}

			this.setState({
				imgGallery     : images,
				ogImage        : getProductImages.data.images[0],
				lightboxImages : getProductImages.data.images
			})
		}
	}

	// Get product moredetails
	getProductMoreDetails = async ( productID, CategoryID, sellerID ) => {
		let detailsParam = {
			Product_Id  : productID,
			Category_Id : CategoryID,
			Seller_Id   : sellerID
		}
		let getProductMoreDetails = await webservice.httpPost( Api.method.getProductMoreDetails, detailsParam );

		if ( 'ok' === getProductMoreDetails.status ) {
			if ( getProductMoreDetails.data.certification ) {
				getProductMoreDetails.data.certification.map( ( img, index ) => {
					return(
						imagesAttach.push( img )
					)
				});
			}

			this.setState({
				attachment: imagesAttach,
				wishListStatus: getProductMoreDetails.data.wishlistStatus,
				ratingAndReview: getProductMoreDetails.data.ratingAndReview,
				rating : getProductMoreDetails.data.rating ? getProductMoreDetails.data.rating : 0
			})
		}

		this.setState({ isLoading: false })
	}

	// get product related products
	getRelatedProducts = async ( productID, CategoryID ) => {
		let relatedParam = {
			Product_Id  : productID,
			Category_Id : CategoryID,
			lang        : this.props.language,
			Latitude    : this.props.geoLocation.latitude,
			Longitude   : this.props.geoLocation.longitude
		}

		if ( true === isBrowser ) {
			relatedParam.isBrowser = isBrowser;
		}

		let getRelatedProducts = await webservice.httpPost( Api.method.getRelatedProducts, relatedParam );
		if ( 'ok' === getRelatedProducts.status ) {
			this.setState({
				relatedproducts: getRelatedProducts.data
			})
		}
	}

	// Add to cart item
	addItemToCart = async ( productID, minQty, redirect ) => {
		if ( minQty === 0 ) {
			minQty = 1;
		}

		const userdetails = localStorage.getItem( 'userdetail' );
		const newdata = await addItemToCart( productID, minQty, this.props.language );
		if ( redirect && userdetails ) {
			window.location.href = '/' + redirect;
		} else {
			this.props.setCartCount( newdata.count );
			this.props.setAlertMessage( newdata.message );
		}
	}

	//add to wishlist
	addToWishlist = async ( productID, pageStatus ) => {
		let param = '';
		if ( 'singleproduct' === pageStatus ) {
			param = pageStatus;
		} else if ( 'relatedProducts' === pageStatus ) {
			param = this.state.relatedproducts;
		}

		const newdata = await addToWishlist( productID, param, this.props.language );
		this.setState({
			singleProduct : newdata.productsApi,
			wishListStatus: newdata.wishlistStatus
		});
		if ( newdata.productsArray && 'relatedProducts' === pageStatus ) {
			this.setState({
				relatedproducts: newdata.productsArray
			});
		}

		this.props.setAlertMessage( newdata.message );
	}

	//remove from wishlist
	removeFromWishlist = async ( productID, pageStatus ) => {
		let param = '';
		if ( 'singleproduct' === pageStatus ) {
			param = pageStatus;
		} else if ( 'relatedProducts' === pageStatus ) {
			param = this.state.relatedproducts;
		}

		const newdata = await removeFromWishlist( productID, param, this.props.language );
		this.setState({
			singleProduct : newdata.productsApi,
			wishListStatus: newdata.wishlistStatus
		})
		if ( newdata.productsArray && 'relatedProducts' === pageStatus ) {
			this.setState({
				relatedproducts: newdata.productsArray
			});
		}

		this.props.setAlertMessage( newdata.message );
	}

	// Refresh data in same page after related product click
	refreshData = () => {
		setTimeout(
			function() {
				imagesAttach = [];
				this.setState({
					relatedproducts: [],
				});
				this.componentDidMount();
				// window.scrollTo({top: 0, behavior: 'smooth'});
				this.setState({ isLoading: true });
			}
			.bind( this ),
			100
		);
	}

	render() {
		if ( ! this.state.singleProduct ) {
			return null;
		}
		const { photoIndex, isOpen } = this.state;

		return (
			<div className="single-product category_wrapper-single">
				{/*For Meta tags Dynamic content*/}
				<MetaTags>
					<title>
						{ this.state.productdetails.name ? this.state.productdetails.name : '' }
					</title>
					<meta name="keywords" content={ this.state.metakeys }/>
					<meta
					name="description"
					content={ this.state.meta_description ? this.state.meta_description : 'A marketplace for construction materials for Nepal' }
					/>
					<meta property="og:url" content={ window.location.href } />
					<meta property="og:type" content="Product Details" />
					<meta property="og:description" content={ this.state.meta_description ? this.state.meta_description : 'A marketplace for construction materials for Nepal' } />
					<meta property="og:title" content={ this.state.productdetails.name } />
					{
						(this.state.ogImage) ?
						<meta property="og:image" content={ this.state.ogImage } />
						: null
					}
				</MetaTags>
				{
					( ! this.state.isLoading ) ?
					<div className="wrapper wrapper_1200 padding_side_15">
						<div className="breadcrumb-div">
							<span className="miniTitle breadcrumb-item"><Link to="/" className="link">{ utils.Language( 'lang_home', this.props.language ) } </Link></span>
							<span className="miniTitle breadcrumb-item"><Link to={ '/category/' + this.state.category_slug } className="link">{ this.state.category_name }</Link></span>
							<span className="miniTitle breadcrumb-item">{ this.state.productdetails.name }</span>
						</div>

						<div className="product-meta-wrapper">
							<div className="row">
								<div className="col-lg-5 col-md-6">
									<ImageGallery
									items={ this.state.imgGallery }
									showBullets={this.state.showBullets}
									showFullscreenButton={this.state.showFullscreenButton && this.state.showGalleryFullscreenButton}
									showPlayButton={this.state.showPlayButton && this.state.showGalleryPlayButton}
									showThumbnails={this.state.showThumbnails}
									showIndex={this.state.showIndex}
									showNav={this.state.showNav}
									onThumbnailClick={( event, index ) => this.setState({ photoIndex: index })}
									onSlide={( currentIndex ) => this.setState({ photoIndex: currentIndex })}
									onClick={() => this.setState({ isOpen: true })}
									/>
									{ isOpen && (
										<Lightbox
										mainSrc={this.state.lightboxImages[photoIndex]}
										nextSrc={this.state.lightboxImages[(photoIndex + 1) % this.state.lightboxImages.length]}
										prevSrc={this.state.lightboxImages[(photoIndex + this.state.lightboxImages.length - 1) % this.state.lightboxImages.length]}
										onCloseRequest={() => this.setState({ isOpen: false })}
										onMovePrevRequest={() =>
											this.setState({
												photoIndex: (photoIndex + this.state.lightboxImages.length - 1) % this.state.lightboxImages.length
											})
										}
										onMoveNextRequest={() =>
											this.setState({
												photoIndex: (photoIndex + 1) % this.state.lightboxImages.length
											})
										}
										/>
									)}
									<div id="myPortal" />
								</div>

								<ProductMeta
									productdata={ this.state.productMeta }
									deliveryOptions={ this.state.deliveryOptions }
									wishlistStatus={ this.state.wishListStatus }
									rating={ this.state.rating }
									addToWishlist={ this.addToWishlist }
									removeFromWishlist={ this.removeFromWishlist }
									addItemToCart={ this.addItemToCart }
									userType={ this.props.userType }
									attachment={ this.state.attachment }
									language={ this.props.language }
									isUserLogin={ this.state.isUserLogin }
									shareUrl={ this.state.url }
									/>
							</div>
						</div>

						<div className="product-description">
							<div className="row" style={{marginTop: 10}}>
								<div className="col-lg-8 col-md-6">
									<ProductDescriptionRating
									data={ this.state.productdetails }
									ratingreview={ this.state.ratingAndReview }
									language={ this.props.language }
									/>

									{
										( 'seller' !== this.props.userType ) ?
											<QuestionAnswer
											itemId={ this.state.itemId }
											data={ this.state.productdetails }
											questionAnswer={ this.state.questionAnswer }
											language={ this.props.language }
											setAlertMessage={ (message) => this.props.setAlertMessage(message) }
											/>
										: null
									}
								</div>

								<div className="col-lg-4 col-md-6">
								</div>
							</div>
						</div>
					</div>
					: <Loader />
				}

				{
					( this.state.relatedproducts != null && ! this.state.isLoading && this.state.relatedproducts.length > 0 ) ? 
						<RelatedProducts
						data={ this.state.relatedproducts }
						refreshData={ this.refreshData }
						addToWishlist={ this.addToWishlist }
						removeFromWishlist={ this.removeFromWishlist }
						addItemToCart={ this.addItemToCart }
						userType={ this.props.userType }
						language={ this.props.language }
						/>
					: null
				}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({
	setCartCount: ( count ) => dispatch( setCartCount( count ) ),
	setAlertMessage: ( message ) => dispatch( setAlertMessage( message ) )
});

export default connect( mapStateToProps, mapDispatchToProps )(SingleProduct);