// AddressDetailsEdit js
import React, {Component} from 'react';
import { Button, FormGroup, FormControl, Form } from "react-bootstrap";
import webservice from '../../services/webservice';
import { Api } from '../../common/variables';
import { Select2 } from "select2-react-component";
import { isMobile } from 'react-device-detect';

// Language
import utils from '../../common/utils';

// Redux set data
import { connect } from "react-redux";
import { setAlertMessage } from '../../action/commonAction';

class AddressDetailsEdit extends Component {
	constructor(props) {
		super(props)
		this.state = {
			cityErr: null,
			regionErr: null,
			addressErr: null,
			fullnameErr: null,
			mobilenumErr: null,
			shippingcityErr: null,
			shippingregionErr: null,
			shippingaddressErr: null,
			shippingfullnameErr: null,
			shippingmobilenumErr: null,
			productCategoriesApi: false,
			districts: this.props.districts,
			billingAddress: {
				Full_Name: this.props.addressDetails.Billing_Address && this.props.addressDetails.Billing_Address.Full_Name ? this.props.addressDetails.Billing_Address.Full_Name : '',
				Mobile_Number: this.props.addressDetails.Billing_Address && this.props.addressDetails.Billing_Address.Mobile_Number ? this.props.addressDetails.Billing_Address.Mobile_Number : '',
				Alt_Number: this.props.addressDetails.Billing_Address && this.props.addressDetails.Billing_Address.Alt_Number ? this.props.addressDetails.Billing_Address.Alt_Number : '',
				Region: this.props.addressDetails.Billing_Address && this.props.addressDetails.Billing_Address.Region ? this.props.addressDetails.Billing_Address.Region : '',
				City: this.props.addressDetails.Billing_Address && this.props.addressDetails.Billing_Address.City ? this.props.addressDetails.Billing_Address.City : '',
				Address: this.props.addressDetails.Billing_Address && this.props.addressDetails.Billing_Address.Address ? this.props.addressDetails.Billing_Address.Address: '',
				Address_Id: this.props.addressDetails.Billing_Address && this.props.addressDetails.Billing_Address.Address_Id ? this.props.addressDetails.Billing_Address.Address_Id: ''
			},
			shippingAddress: {
				Full_Name: this.props.addressDetails.Shipping_Address && this.props.addressDetails.Shipping_Address.Full_Name ? this.props.addressDetails.Shipping_Address.Full_Name : '',
				Mobile_Number: this.props.addressDetails.Shipping_Address && this.props.addressDetails.Shipping_Address.Mobile_Number ? this.props.addressDetails.Shipping_Address.Mobile_Number : '',
				Alt_Number: this.props.addressDetails.Shipping_Address && this.props.addressDetails.Shipping_Address.Alt_Number ? this.props.addressDetails.Shipping_Address.Alt_Number : '',
				Region: this.props.addressDetails.Shipping_Address && this.props.addressDetails.Shipping_Address.Region ? this.props.addressDetails.Shipping_Address.Region : '',
				City: this.props.addressDetails.Shipping_Address && this.props.addressDetails.Shipping_Address.City ? this.props.addressDetails.Shipping_Address.City : '',
				Address: this.props.addressDetails.Shipping_Address && this.props.addressDetails.Shipping_Address.Address ? this.props.addressDetails.Shipping_Address.Address : '',
				Address_Id: this.props.addressDetails.Shipping_Address && this.props.addressDetails.Shipping_Address.Address_Id ? this.props.addressDetails.Shipping_Address.Address_Id : ''
			},
			message: null,
			differentFromBilling: false
		}
	}

	handleSubmit = async ( event ) => {
		event.preventDefault();
		this.setState({
			cityErr: null,
			regionErr: null,
			addressErr: null,
			fullnameErr: null,
			mobilenumErr: null,
			shippingcityErr: null,
			shippingregionErr: null,
			shippingaddressErr: null,
			shippingfullnameErr: null,
			shippingmobilenumErr: null,
		});

		if ( this.state.billingAddress.Full_Name === '' ) {
			this.setState({
				fullnameErr: utils.Language( 'lang_fullName_req', this.props.language )
			});
		}

		if ( this.state.billingAddress.Mobile_Number === '' ) {
			this.setState({
				mobilenumErr: utils.Language( 'lang_errorMobileNumber', this.props.language )
			});
		}

		if ( this.state.billingAddress.Address === '' ) {
			this.setState({
				addressErr: utils.Language( 'lang_errorAddress', this.props.language )
			});
		}

		if ( this.state.billingAddress.City === '' ) {
			this.setState({
				cityErr: utils.Language( 'lang_errorCity', this.props.language )
			});
		}

		// if ( this.state.billingAddress.Region === '' ) {
		// 	this.setState({
		// 		regionErr: utils.Language( 'lang_errorRegion', this.props.language )
		// 	});
		// }

		if ( this.state.differentFromBilling ) {
			if ( this.state.shippingAddress.Full_Name === '' ) {
				this.setState({
					shippingfullnameErr: utils.Language( 'lang_fullName_req', this.props.language )
				});
			}

			if ( this.state.shippingAddress.Mobile_Number === '' ) {
				this.setState({
					shippingmobilenumErr: utils.Language( 'lang_errorMobileNumber', this.props.language )
				});
			}

			if ( this.state.shippingAddress.Address === '' ) {
				this.setState({
					shippingaddressErr: utils.Language( 'lang_errorAddress', this.props.language )
				});
			}

			if ( this.state.shippingAddress.City === '' ) {
				this.setState({
					shippingcityErr: utils.Language( 'lang_errorCity', this.props.language )
				});
			}

			// if ( this.state.shippingAddress.Region === '' ) {
			// 	this.setState({
			// 		shippingregionErr: utils.Language( 'lang_errorRegion', this.props.language )
			// 	});
			// }

			if ( this.state.shippingAddress.Full_Name === '' || this.state.shippingAddress.Mobile_Number === '' || this.state.shippingAddress.Address === '' || this.state.shippingAddress.City === '' ) {
				return;
			}
		}

		if ( this.state.billingAddress.Full_Name === '' || this.state.billingAddress.Mobile_Number === '' || this.state.billingAddress.Address === '' || this.state.billingAddress.City === '' ) {
			return;
		}

		if ( isMobile ) {
			this.state.billingAddress.Region = 36;
			this.state.shippingAddress.Region = 36;
		}

		if ( false === this.state.differentFromBilling ) {
			this.state.shippingAddress.Address = this.state.billingAddress.Address;
			this.state.shippingAddress.Alt_Number = this.state.billingAddress.Alt_Number;
			this.state.shippingAddress.City = this.state.billingAddress.City;
			this.state.shippingAddress.Full_Name = this.state.billingAddress.Full_Name;
			this.state.shippingAddress.Mobile_Number = this.state.billingAddress.Mobile_Number;
			this.state.shippingAddress.Region = this.state.billingAddress.Region;
		}

		let param = {
			Billing : this.state.billingAddress,
			Shipping: this.state.shippingAddress
		}

		let updateUserAddress = await webservice.httpPost( Api.method.saveAddress, param );

		if ( updateUserAddress.status === 'ok' ) {
			//display toast message and update data in frontend
			this.props.setAlertMessage( utils.Language( 'lang_saveAddressSuccess', this.props.language ) );
			this.props.onUpdate();
			this.props.closeModal();
		}
	}

	render() {
		return (
			<form onSubmit={this.handleSubmit}>
				<FormGroup controlId="email">
					<div className="row">
						<div className="col-sm-6">
						<FormGroup controlId="fName">
							<Form.Label>{ utils.Language( 'lang_fullName', this.props.language ) } <span className="error-msg">*</span></Form.Label>
							<FormControl
							autoFocus
							type="text"
							value={this.state.billingAddress.Full_Name}
							onChange={ (e) => {
								const fullName = Object.assign({}, this.state.billingAddress, { Full_Name: e.target.value });
								this.setState({ billingAddress: fullName });
							}}
							className="inputBox"
							/>
							{
								( this.state.fullnameErr ) ?
								<div className="error-msg"><p>{ this.state.fullnameErr }</p></div>
								: null
							}
							</FormGroup>
						</div>

						
						<div className="col-sm-6">
						<FormGroup controlId="">
							<Form.Label>{ utils.Language( 'lang_mobileNumber', this.props.language ) } <span className="error-msg">*</span></Form.Label>
							<FormControl
							type="number"
							value={this.state.billingAddress.Mobile_Number}
							onChange={ (e) => {
								const Mobile_Number = Object.assign({}, this.state.billingAddress, { Mobile_Number: e.target.value });
								this.setState({ billingAddress: Mobile_Number });
							}}
							className="inputBox"
							/>
							{
								( this.state.mobilenumErr ) ?
								<div className="error-msg"><p>{ this.state.mobilenumErr }</p></div>
								: null
							}
						</FormGroup>
						</div>
						<div className="col-sm-6">
						<FormGroup controlId="">
							<Form.Label>{ utils.Language( 'lang_altMobile', this.props.language ) }</Form.Label>
							<FormControl
							type="number"
							value={this.state.billingAddress.Alt_Number}
							onChange={ (e) => {
								const Alt_Number = Object.assign({}, this.state.billingAddress, { Alt_Number: e.target.value });
								this.setState({ billingAddress: Alt_Number });
							}}
							className="inputBox"
							/>
						</FormGroup>
						</div>

						{
							( ! isMobile ) ?
								<div className="col-sm-6">
								<Form.Group controlId="formGridDistrict">
									<Form.Label>{ utils.Language( 'lang_district', this.props.language ) } <span className="error-msg">*</span></Form.Label>
									<Select2
									className={'drop-up'}
									menuPlacement= "top"
									data={this.props.districts}
									value={this.state.billingAddress.Region}
									placeholder={ utils.Language( 'lang_choose', this.props.language ) }
						    		update={value => { const Region = Object.assign({}, this.state.billingAddress, { Region: value });
												this.setState({ billingAddress: Region });
											}
										}
						    			>
									</Select2>
									{
										( this.state.regionErr ) ?
										<div className="error-msg"><p>{ this.state.regionErr }</p></div>
										: null
									}
								</Form.Group>
								</div>
							: null
						}

						<div className="col-sm-6">
						<FormGroup controlId="fName">
							<Form.Label>{ utils.Language( 'lang_address', this.props.language ) } <span className="error-msg">*</span></Form.Label>
							<FormControl
							type="text"
							value={this.state.billingAddress.Address}
							onChange={ (e) => {
								const Address = Object.assign({}, this.state.billingAddress, { Address: e.target.value });
								this.setState({ billingAddress: Address });
							}}
							className="inputBox"
							/>
							{
								( this.state.addressErr ) ?
								<div className="error-msg"><p>{ this.state.addressErr }</p></div>
								: null
							}
						</FormGroup>
						</div>						

						<div className="col-sm-6">
						<FormGroup controlId="">
							<Form.Label>{ utils.Language( 'lang_city', this.props.language ) } <span className="error-msg">*</span></Form.Label>
							<FormControl
							type="text"
							value={this.state.billingAddress.City}
							onChange={ (e) => {
								const City = Object.assign({}, this.state.billingAddress, { City: e.target.value });
								this.setState({ billingAddress: City });
							}}
							className="inputBox"
							/>
							{
								( this.state.cityErr ) ?
								<div className="error-msg"><p>{ this.state.cityErr }</p></div>
								: null
							}
						</FormGroup>
						</div>
						
					</div>
					<div className="differentshipping">
						<FormGroup className="customCheckbox">
							<input type="checkbox" id="termsoption" name="chekoptions" className="inputboxes" onChange={ (e) => this.setState({ 'differentFromBilling': e.target.checked }) } />
								<label className="labelRadio" htmlFor="termsoption">{ utils.Language( 'lang_shippingAddressDifferent', this.props.language ) }</label>
						</FormGroup>
					</div>
					<div className={ ( this.state.differentFromBilling !== true ) ? 'hidden' : null }>
						<div className="row">
							<div className="col-sm-6">
							<FormGroup controlId="">
								<Form.Label>{ utils.Language( 'lang_fullName', this.props.language ) } <span className="error-msg">*</span></Form.Label>
								<FormControl
								autoFocus
								type="text"
								value={this.state.shippingAddress.Full_Name}
								onChange={ (e) => {
									const fullName = Object.assign({}, this.state.shippingAddress, { Full_Name: e.target.value });
									this.setState({ shippingAddress: fullName });
								}}
								className="inputBox"
								/>
								{
									( this.state.shippingfullnameErr ) ?
									<div className="error-msg"><p>{ this.state.shippingfullnameErr }</p></div>
									: null
								}
								</FormGroup>
							</div>
							<div className="col-sm-6">
							<FormGroup controlId="fName">
								<Form.Label>{ utils.Language( 'lang_mobileNumber', this.props.language ) } <span className="error-msg">*</span></Form.Label>
								<FormControl
								type="number"
								value={this.state.shippingAddress.Mobile_Number}
								onChange={ (e) => {
									const Mobile_Number = Object.assign({}, this.state.shippingAddress, { Mobile_Number: e.target.value });
									this.setState({ shippingAddress: Mobile_Number });
								}}
								className="inputBox"
								/>
								{
									( this.state.shippingmobilenumErr ) ?
									<div className="error-msg"><p>{ this.state.shippingmobilenumErr }</p></div>
									: null
								}
							</FormGroup>
							</div>

							<div className="col-sm-6">
							<FormGroup controlId="">
								<Form.Label>{ utils.Language( 'lang_altMobile', this.props.language ) }</Form.Label>
								<FormControl
								type="number"
								value={this.state.shippingAddress.Alt_Number}
								onChange={ (e) => {
									const Alt_Number = Object.assign({}, this.state.shippingAddress, { Alt_Number: e.target.value });
									this.setState({ shippingAddress: Alt_Number });
								}}
								className="inputBox"
								/>
							</FormGroup>
							</div>

							{
								( ! isMobile ) ?
									<div className="col-sm-6">
									<Form.Group controlId="formGridDistrict">
										<Form.Label>{ utils.Language( 'lang_district', this.props.language ) } <span className="error-msg">*</span></Form.Label>
										<Select2
										data={this.props.districts}
										value={this.state.shippingAddress.Region}
										placeholder={ utils.Language( 'lang_choose', this.props.language ) }
							    		update={value => { const Region = Object.assign({}, this.state.shippingAddress, { Region: value });
													this.setState({ shippingAddress: Region });
												}
											}
							    			>
										</Select2>
										{
											( this.state.shippingregionErr ) ?
											<div className="error-msg"><p>{ this.state.shippingregionErr }</p></div>
											: null
										}
									</Form.Group>
									</div>
								: null
							}

							<div className="col-sm-6">
							<FormGroup controlId="fName">
								<Form.Label>{ utils.Language( 'lang_address', this.props.language ) } <span className="error-msg">*</span></Form.Label>
								<FormControl
								type="text"
								value={this.state.shippingAddress.Address}
								onChange={ (e) => {
									const Address = Object.assign({}, this.state.shippingAddress, { Address: e.target.value });
									this.setState({ shippingAddress: Address });
								}}
								className="inputBox"
								/>
								{
									( this.state.shippingaddressErr ) ?
									<div className="error-msg"><p>{ this.state.shippingaddressErr }</p></div>
									: null
								}
							</FormGroup>
							</div>
							<div className="col-sm-6">
							<FormGroup controlId="">
								<Form.Label>{ utils.Language( 'lang_city', this.props.language ) } <span className="error-msg">*</span></Form.Label>
								<FormControl
								type="text"
								value={this.state.shippingAddress.City}
								onChange={ (e) => {
									const City = Object.assign({}, this.state.shippingAddress, { City: e.target.value });
									this.setState({ shippingAddress: City });
								}}
								className="inputBox"
								/>
								{
									( this.state.shippingcityErr ) ?
									<div className="error-msg"><p>{ this.state.shippingcityErr }</p></div>
									: null
								}
							</FormGroup>
							</div>
						</div>
					</div>
				</FormGroup>
				<div className="btnWrapper">
					<Button className="btn btn-success" type="submit">{ utils.Language( 'lang_update', this.props.language ) }</Button>
				</div>
			</form>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	setAlertMessage: ( message ) => dispatch( setAlertMessage( message ) )
});

export default connect( null, mapDispatchToProps )(AddressDetailsEdit);