// Checkout js
import React, {Component} from 'react';
import webservice from '../services/webservice';
import { Api } from '../common/variables';

// Redux set data
import { connect } from "react-redux";

class CheckOut extends Component {
	constructor() {
		super()
		this.state = {
		   pageDetailApi : false,
		}
	}

	async componentDidMount() {
		let params = {
			lang     : this.props.language,
			pageSlug : 'privacy',
		}

		let getPageDetail = await webservice.httpPost( Api.method.getPageDetail, params );
		if ( getPageDetail.status === 'ok' ) {
			this.setState({
				pageTitle     : getPageDetail.data.title,
				pageContent   : getPageDetail.data.content,
				pageDetailApi : true,
			})
		}
	}

	render() {
		if ( ! this.state.pageDetailApi ) {
			return null;
		}

		return (
			<div className="wrapper wrapper_1200">
				{ ( this.state.pageTitle ) ? <h1>{ this.state.pageTitle }</h1> : null }

				{ ( this.state.pageContent ) ? this.state.pageContent : null }
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

export default connect( mapStateToProps, null )(CheckOut);