import { English } from "../common/lang/english";
import { Nepali } from "../common/lang/nepali";

const utils = {
	Language:( key, languageType ) => {
		if ( 'En' === languageType ) {
			return English[key];
		} else {
			return Nepali[key];
		}
	}
}

export default utils;