// CartList js
import React, {Component} from 'react';
import { Link } from 'react-router-dom';

// Language
import utils from '../../common/utils';

class CartList extends Component {
	render() {
		return (
			<div className="cart-items userList " key={this.props.id}>
				<div className="cartItems">
					<div className="hideinmobile cartproduct-image">
						<div className="imgWrapper ">
							<img src={ this.props.img } alt="productimage" />
						</div>
					</div>
					<div className="productdetails show-in-desktop">
						<div className="showinmobile cartproduct-image">
							<div className="imgWrapper showinmobile">
								<img src={ this.props.img } alt="productimage" />
							</div>							
						</div>
						<Link to={ '/products/' + encodeURIComponent( this.props.slug ) }>
							<h3>{ this.props.name }</h3>
						</Link>
						{
							this.props.description ?
							<p>{ this.props.description }</p>
							: null
						}
						<div className="cart-shiptext">
							{ ( this.props.vendorName ) ? <span className="cart-shipname"><span className="lightText">{ utils.Language( 'lang_soldby', this.props.language ) }: </span>{ this.props.vendorName }</span> : null }
						</div>
					</div>
				</div>

				<div className="cartPrice show-in-desktop">
					<div className="price">
						<span className="currency">{ utils.Language( 'lang_currency', this.props.language ) }</span>  { new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format( this.props.price ) }
						<span className="smallText">{ utils.Language( 'lang_taxText', this.props.language ) }</span>
						{
							( 'paid' === this.props.shipping_type ) ?
							<span className="shippingprice">
								{ utils.Language( 'lang_shippingFee', this.props.language ) }: {
									( '0.00' === this.props.shippingprice || null === this.props.shippingprice ) ?
									utils.Language( 'lang_shippingExtraInfo', this.props.language )
									:
									new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format( this.props.shippingprice )
								}
							</span>
							:
							<span className="shippingprice">{ utils.Language( 'lang_shippingFee', this.props.language ) } : { utils.Language( 'lang_' + this.props.shipping_type, this.props.language ) }</span>
						}
					</div>
				</div>

				<div className="cartQty show-in-desktop">
					<div className={ this.props.pointerEvent ? 'pointer-event quantity' : 'quantity' }>
						<input type="button" value="-" className="button-minus" data-field="quantity" onClick={ ( e, id, min ) => { this.props.stepQtyDecrement( this.props.quantity, this.props.id, this.props.minimum_quantity ) } } />
						<input type="number" id={ "qtyUpdate" + this.props.id } className="inputBox" name="quantity" value={ this.props.quantity } min={ this.props.minimum_quantity } max={ this.props.maximum_quantity } onChange={ (e, id) => { this.props.onQtyChange( e, this.props.id ) } } />
						<input type="button" value="+" className="button-plus" data-field="quantity" onClick={ ( e, id, max ) => { this.props.stepQtyIncrement( this.props.quantity, this.props.id, this.props.maximum_quantity ) } } />
					</div>
				</div>

				<div className="cartAmnt show-in-desktop">
					<span className="currency">{ utils.Language( 'lang_Total', this.props.language ) }: { utils.Language( 'lang_currency', this.props.language ) }</span> { new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format( this.props.price * this.props.quantity ) }
				</div>

				<div className="cart-delete show-in-desktop">
					<span className="icon-times-circle" onClick={ (e, id) => { if ( window.confirm( utils.Language( 'lang_conformationCartDelete', this.props.language ) ) )  this.props.onRemoveClick( e, this.props.id )} }></span>
				</div>
			<div className="show-in-mobile full-width">
				<div className="productdetails">
					<div className="cartproduct-image">
						<div className="imgWrapper">
							<img src={ this.props.img } alt="productimage" />
						</div>							
					</div>
					<div className="cartproduct-details">
							<Link to={ '/products/' + encodeURIComponent( this.props.slug ) }>
								<h3>{ this.props.name }</h3>
							</Link>
							{
								this.props.description ?
								<p>{ this.props.description }</p>
								: null
							}
							<div className="cart-shiptext">
								{ ( this.props.vendorName ) ? <span className="cart-shipname"><span className="lightText">{ utils.Language( 'lang_soldby', this.props.language ) }: </span>{ this.props.vendorName }</span> : null }
							</div>
							<div className="price">
								<span className="currency">{ utils.Language( 'lang_currency', this.props.language ) }</span>  { new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format( this.props.price ) }
								<span className="smallText">{ utils.Language( 'lang_taxText', this.props.language ) }</span>
								{
									( 'paid' === this.props.shipping_type ) ?
									<span className="shippingprice">
										{ utils.Language( 'lang_shippingFee', this.props.language ) }: {
											( '0.00' === this.props.shippingprice || null === this.props.shippingprice ) ?
											utils.Language( 'lang_shippingExtraInfo', this.props.language )
											:
											new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format( this.props.shippingprice )
										}
									</span>
									:
									<span className="shippingprice">{ utils.Language( 'lang_shippingFee', this.props.language ) } : { utils.Language( 'lang_' + this.props.shipping_type, this.props.language ) }</span>
								}
							</div>

							<div className={ this.props.pointerEvent ? 'pointer-event quantity' : 'quantity' }>
								<input type="button" value="-" className="button-minus" data-field="quantity" onClick={ ( e, id, min ) => { this.props.stepQtyDecrement( this.props.quantity, this.props.id, this.props.minimum_quantity ) } } />
								<input type="number" id={ "qtyUpdate" + this.props.id } className="inputBox" name="quantity" value={ this.props.quantity } min={ this.props.minimum_quantity } max={ this.props.maximum_quantity } onChange={ (e, id) => { this.props.onQtyChange( e, this.props.id ) } } />
								<input type="button" value="+" className="button-plus" data-field="quantity" onClick={ ( e, id, max ) => { this.props.stepQtyIncrement( this.props.quantity, this.props.id, this.props.maximum_quantity ) } } />
							</div>
					</div>					
				</div>

				<div className="cartAmnt">
					<div className="cart-delete">
						<span className="" onClick={ (e, id) => { if ( window.confirm( utils.Language( 'lang_conformationCartDelete', this.props.language ) ) )  this.props.onRemoveClick( e, this.props.id )} }>Remove</span>
					</div>
					<span className="currency">{ utils.Language( 'lang_Total', this.props.language ) }: { utils.Language( 'lang_currency', this.props.language ) } { new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format( this.props.price * this.props.quantity ) }</span>
				</div>

				</div>
			</div>
		);
	}
}

export default CartList;