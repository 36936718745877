import { Api  } from '../common/variables';

const webservice = {
	httpGet: (endPoint, param) => {
		return fetch( Api.baseurl+endPoint + '?' + param, {
			method: 'GET',
			ignoreAuthModule: true
		}).then((response) => response.json())
			.then((responseJson) => {
				return responseJson;
			})
			.catch((error) => {
				return error;
		});
	},

	httpPost: async (endPoint, param) => {
		const userData = await localStorage.getItem( 'userdetail' );
		
		let headerParams = {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Host': 'api.gharbanau.com'
		}

		if ( userData != null ) {
			const parsedUserData = JSON.parse( userData );
			headerParams = {
				'Accept': 'application/json',
				'Authorization': 'Bearer ' + parsedUserData.token,
				'Content-Type': 'application/json',
				'Host': 'api.gharbanau.com'
			}
		}

		return fetch( Api.baseurl+endPoint, {
			method: 'POST',
			headers: headerParams,
			body: JSON.stringify( param )
		}).then((response) =>{
			if (response.headers.get('content-type').match(/application\/json/)) {
				return response.json();
			}
			return response;
		})
		.then((responseJson) => {
			return responseJson;
		})
		.catch((error) => {
			return error;
		});
	},

	httpPostBack: async (endPoint, param) => {
		let headerParams = {
			'Accept': 'application/json',
			'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
			'Host': 'api.gharbanau.com'
		}

		return fetch( Api.backEndapiUrl+endPoint, {
			method: 'POST',
			headers: headerParams,
			body: `EmailAddress=${param.username}&Password=${param.password}`
		}).then((response) =>{
			if (response.headers.get('content-type').match(/application\/json/)) {
				return response.json();
			}
			return response;
		})
		.then((responseJson) => {
			return responseJson;
		})
		.catch((error) => {
			return error;
		});
	}
}

export default webservice;