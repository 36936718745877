// QuestionAnswer js
import React, {Component} from 'react';
import webservice from '../../services/webservice';
import { Api } from '../../common/variables';

// Language
import utils from '../../common/utils';

// Redux set data
import { connect } from "react-redux";

class QuestionAnswer extends Component {
	constructor(props) {
		super()
		this.state = {
			question: '',
		}
	}

	// post question
	postQuestion = async ( question ) => {
		const userdetails = localStorage.getItem( 'userdetail' );
		var userdata = JSON.parse( userdetails );

		if ( userdetails ) {
			let postParam = {
				itemId: this.props.itemId,
				userId: userdata.userID,
				userQuestion: question,
				vendorEmail: ( this.props.vendorInfos.data.vendor_email ) ? this.props.vendorInfos.data.vendor_email : this.props.vendorInfos.data.contact_email,
				vendorID : this.props.vendorInfos.data.id
			}

			let PostNewAnswer = await webservice.httpPost( Api.method.postProductQuestion, postParam );
			if ( 'ok' === PostNewAnswer.status ) {
				this.props.setAlertMessage( utils.Language( 'lang_PostQuestionsSuccess', this.props.language ) );
				this.setState({
					question: ''
				})
			}
		} else {
			this.props.setAlertMessage( utils.Language( 'lang_loginToAsk', this.props.language ) );
			document.getElementById("LoginPopup").click();
		}
	}

	render() {
		return (
		<div className="full-width" style={{marginTop: 20}}>
			<div className="descriptionSpecification" style={{marginTop: 0}}>
				<div className="qaAdd">
					<h3>{ utils.Language( 'lang_QuestionsAbout', this.props.language ) }</h3>
					<div className="qaInputbox">
						<input type="text" placeholder={ utils.Language( 'lang_questionPlaceholder', this.props.language ) } value={ this.state.question } className="qaInput" onChange= { e => { this.setState({ question : e.target.value }); } } />
						<button className="btn btn-danger"
						onClick = {
							(e) => {
								if ( this.state.question ) {
									this.postQuestion( this.state.question );
								} else {
									alert( utils.Language( 'lang_PostQuestions', this.props.language ) );
								}
							}
						}>{ utils.Language( 'lang_Submit', this.props.language ) }</button>
					</div>
				</div>

				<div className="qaOther">
					{
						( this.props.questionAnswer ) ?
							this.props.questionAnswer.map((item,index) => {
								if ( ! item.answer ) {
									return false;
								}

								return(
								<div className="question-answer" key={ index }>
									{ ( 0 === index ) ? <p>{ utils.Language( 'lang_qnsAnswerBy', this.props.language ) }</p> : null }
									{
										( item.answer ) ?
										<div className="questionanswerlist">
											<div className="qaList">
												<span className="qaQuestion">{ item.question }</span>
												<span className="questionby">{ item.questionby }</span>
												<span className="questiondate">{ item.questiondate }</span>
											</div>
											<div className="ansList" key={ index }>
												<span className="answer">{ item.answer }</span>
												<span className="answerby">{ item.answerby }</span>
												<span className="answerdate">{ item.answerdate }</span>
											</div>
										</div>
										: null
									}
								</div>
								)
							})
						: null
					}
				</div>
			</div>
		</div>
		);
	}
}


const mapStateToProps = state => ({
	...state
});

export default connect(mapStateToProps, null)(QuestionAnswer);