// Page js
import React, { Component } from 'react';
import webservice from '../../services/webservice';
import { Api } from '../../common/variables';
import renderHTML from 'react-render-html';
import MetaTags from 'react-meta-tags';
import Loader from '../../common/Loader';
import Swiper from 'react-id-swiper';

import { FacebookShareButton, TwitterShareButton, EmailShareButton, TwitterIcon, FacebookIcon, EmailIcon, } from "react-share";
// import {
// 	withScriptjs,
// 	withGoogleMap,
// 	GoogleMap,
// 	Marker
// } from "react-google-maps";

// Redux set data
import { connect } from "react-redux";

// Language
import utils from '../../common/utils';


{/* commented for google api*/}
{/* const { compose, withProps } = require("recompose");
const MyMapComponent = compose(
	withProps({
		// googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyAvL-t2e8e1GA7PT6rpUh5rqoCq5Cy8Sc8&v=3.exp&libraries=geometry,drawing,places",
		googleMapURL: GoogleMapLink,
		loadingElement: <div style={{ height: `100%` }} />,
		containerElement: <div style={{ height: `400px` }} />,
		mapElement: <div style={{ height: `100%` }} />
	}),
	withScriptjs,
	withGoogleMap
)(props => (
	<GoogleMap defaultZoom={13} defaultCenter={{ lat: props.lat, lng: props.lng }}>
		<Marker position={{ lat: props.lat, lng: props.lng }} />
	</GoogleMap>
));*/}

class Page extends Component {
	constructor() {
		super()
		this.state = {
			pageDetailApi: false,
			photoIndex: 0,
			isOpen: false,
			url: '',
			latitude: '',
			longitute: '',
			bannerImage: [],
			contact_phone: '',
			contact_person: '',
			content_mobile: '',
			contact_email: '',
			contact_phone_alt: '',
			contact_designation: '',
			template: '',
			isUserLogin: true,
			vendor_phone_alt: null,
			vendor_phone: null,
		}
	}

	async componentDidMount() {
		var str = Api.backEndapiUrl;
		var strrepl = str.replace(/\/+$/, '');
		var pretyurl = strrepl + this.props.match.url.replace(this.props.match.params.slug, '');
		this.setState({ url: pretyurl.replace(/\/+$/, '') });

		const userdetails = localStorage.getItem('userdetail');
		if (userdetails) {
			const details = JSON.parse(userdetails);
			if (details.userID) {
				this.setState({
					isUserLogin: true,
				});
			}
		}

		let params = {
			lang     : this.props.language,
			pageSlug : this.props.match.params.slug,
			Latitude : this.props.geoLocation.latitude,
			Longitude: this.props.geoLocation.longitude,
			vendorID : this.props.vendorInfos.data.id,
			supplierSlug: this.props.vendorInfos.data.slug
		}

		let getPageDetail;
		if ( 'about-company' === this.props.match.params.slug || 'why-choose-us' === this.props.match.params.slug || 'contact-us' === this.props.match.params.slug ) {
			getPageDetail = await webservice.httpPost(Api.method.getSupplierPageDetail, params);
		} else {
			getPageDetail = await webservice.httpPost( Api.method.getPageDetail, params );
		}

		if (getPageDetail.status === 'ok') {			
			if ( ( 'about-company' === this.props.match.params.slug && false === getPageDetail.data.getSupplierDetails.data.AboutCompany ) || ( 'why-choose-us' === this.props.match.params.slug && false === getPageDetail.data.WhyChooseUs ) || ( 'contact-us' === this.props.match.params.slug  && false === getPageDetail.data.ContactUs ) ) {
				document.location.href = Api.backEndapiUrl;
			}

			if ( 'about-company' === this.props.match.params.slug || 'why-choose-us' === this.props.match.params.slug || 'contact-us' === this.props.match.params.slug ) {
				this.setState({
					pageDetailApi: true,
					pageTitle: getPageDetail.data.title,
					pageContent: getPageDetail.data.content,
					metaTitle: getPageDetail.data.meta_title,
					metaKeywords: getPageDetail.data.meta_keywords,
					metaDescriptions: getPageDetail.data.meta_descriptions,
					latitude: getPageDetail.data.latitude,
					longitude: getPageDetail.data.longitude,
					contact_phone: getPageDetail.data.contact_phone,
					contact_person: getPageDetail.data.contact_person,
					contact_email: getPageDetail.data.contact_email,
					user_contact_email: getPageDetail.data.user_contact_email,
					contact_fullname: getPageDetail.data.contact_fullname,
					bannerImage: getPageDetail.data.BannerArrayImage,
					content_mobile: getPageDetail.data.content_mobile,
					contact_phone_alt: getPageDetail.data.contact_phone_alt,
					contact_designation: getPageDetail.data.contact_designation,
					name: getPageDetail.data.name,
					address_list: getPageDetail.data.addressList,
					vendor_verified: getPageDetail.data.vendor_verified,
					is_subscribed: getPageDetail.data.is_subscribed,
					vendor_phone: getPageDetail.data.vendor_phone,
					vendor_phone_alt: getPageDetail.data.vendor_phone_alt,
					vendor_file: getPageDetail.data.vendor_file,
					template : 'withheader'
				})
			} else {
				this.setState({
					pageDetailApi    : true,
					pageTitle        : getPageDetail.data.title,
					pageContent      : getPageDetail.data.content,
					metaTitle        : getPageDetail.data.meta_title,
					metaKeywords     : getPageDetail.data.meta_keywords,
					template         : getPageDetail.data.template,
					bannerImage      : this.props.vendorInfos.data.BannerArrayImage,
					metaDescriptions : getPageDetail.data.meta_descriptions
				})
			}
		} else {
			document.location.href = Api.backEndapiUrl;
		}

		if (document.getElementById("priceList")) {
			document.getElementById("priceList").parentElement.classList.add('long-wrapper');
		}
	}

	render() {
		const params = {
			navigation: {
				nextEl: '.swiper-button-next.swiper-button-white', // Add your class name for next button
				prevEl: '.swiper-button-prev.swiper-button-white' // Add your class name for prev button
			},
			autoplay: {
				delay: 8000,
			},
			spaceBetween: 0,
			loop: this.state.bannerImage.length > 1 ? true : false,
			lazy: true,
			preloadImages: false,
			watchOverflow: true,
		}

		return (
			<div className={ ( 'centercontent' === this.state.template ) ? "suppliers-single-page howtosellPage" : "suppliers-single-page"}>
				{/*For Meta tags Dynamic content*/}
				<MetaTags>
					<title>{this.state.metaTitle ? this.state.metaTitle : ''}</title>
					<meta name="keywords" content={this.state.metaKeywords ? this.state.metaKeywords : 'terms and condition'} />
					<meta property="og:url" content={window.location.href} />
					<meta property="og:type" content="Page" />
					<meta
						name="description"
						content={this.state.metaDescriptions ? this.state.metaDescriptions : ''}
					/>
					<meta property="og:description" content={this.state.metaDescriptions ? this.state.metaDescriptions : ''} />
					<meta property="og:title" content={this.state.pageTitle ? this.state.pageTitle : 'Page'} />
				</MetaTags>

				{
					(this.state.pageDetailApi) ?
						<div className="termswrap">
							{
								(this.state.bannerImage.length > 0 && 'withheader' === this.state.template) ?
									<Swiper
										{...params}
										wrapperClass={'SwiperBanner'}
										spaceBetween={0}
										slidesPerView={1}
										onSlideChange={() => console.log('slide change')}
										onSwiper={(swiper) => console.log(swiper)}
									>
										{
										(this.state.bannerImage.length > 0) ?
											this.state.bannerImage.map((data, index) => {
												return (
												<div
													className="banners swiper-slide swiper-lazy"
													// style={{ backgroundImage: `url(${data.BannerImage})` }}
													data-background={data.BannerImage}
													key={index}
												>
												<div className="swiper-lazy-preloader"></div>
													{
														(data.BannerLink) ?
														<a href={data.BannerLink} rel="noopener noreferrer">
															<img
																alt='banner'
																className="swiper-lazy"
																data-src={data.BannerImage}
															/>
															<div className="swiper-lazy-preloader"></div>
														</a>
														:
														<div>
															<img
																alt='banner'
																className="swiper-lazy"
																data-src={data.BannerImage}
															/>
															<div className="swiper-lazy-preloader"></div>
														</div>
													}
												</div>
												);
											})
										: null
										}
									</Swiper>
									: null
							}
							{
							(this.state.pageTitle && 'withheader' !== this.state.template) ?
								<div className="banner">
									<div className="image_sec bgColor_sec" key="1">
										<div className="bannercontent">
											<div className="wrapper wrapper_1200">
												<span className="title-small-banner">{this.state.pageTitle}</span>
											</div>
										</div>
									</div>
								</div>
							: null
							}

							<div className="innerContent">
								<div className="wrapper wrapper_1200">
									{ ( this.state.pageTitle && this.state.bannerImage.length !== 0 && 'withheader' === this.state.template) ? <h2 className="innerContent-title">{ this.state.pageTitle } </h2> : null }

									{
										(this.state.latitude && this.state.longitude) ?
											<div className={'supplier-map-section'}>
												<div className="row">
													<div className="col-sm-4 col-lg-4">
														{
															(true === this.state.isUserLogin && true === this.state.is_subscribed) ?
																<div className={'supplier-content-section suppliers-contents suppliers-list'}>
																	<div className={'suppliers-office'}>
																		{(this.state.name) ? <h3> {this.state.name}</h3> : ''}
																		{(this.state.address_list) ? <p>{this.state.address_list}</p> : ''}

																		{
																			(this.state.user_contact_email) || (this.state.vendor_phone) || (this.state.vendor_phone_alt) || (this.state.website) ?
																				<div className="suppliers-phone">
																					{(this.state.user_contact_email) ? <div className="soldby-phn">
																						<a className="" href={"mailto:" + this.state.user_contact_email}><span className="icon-mailing"></span> {this.state.user_contact_email}</a>
																					</div>
																						: null}

																					{(this.state.vendor_phone) ? <div className="soldby-phn">
																						<a className="click-to-call" href={"callto:" + this.state.vendor_phone}><span className="icon-call-answer"></span> {this.state.vendor_phone}</a>
																					</div>
																						: null}

																					{(this.state.vendor_phone_alt) ? <div className="soldby-phn">
																						<a className="click-to-call" href={"callto:" + this.state.vendor_phone_alt}><span className="icon-call-answer"></span> {this.state.vendor_phone_alt}</a>
																					</div>
																						: null}

																					{(this.state.website) ? <div className="soldby-phn">
																						<a className="" href={this.state.website} target="_blank" rel="noopener noreferrer"><span className="icon-globe"></span> {this.state.website}</a>
																					</div>
																						: null}
																				</div>
																				: null
																		}
																	</div>

																	<div className={'contact-details suppliers-contact-person'}>
																		{(this.state.contact_fullname) ? <div className={'soldby_icon soldby-seller'}><span className="icon"><span className="icon-user"></span></span> {this.state.contact_fullname}</div> : ''}

																		{(this.state.contact_phone) ? <div className={'soldby_icon soldby-seller'}><span className="icon"><span className="icon-call-answer"></span></span> {this.state.contact_phone}</div> : null}

																		{(this.state.contact_phone_alt) ? <div className={'soldby_icon soldby-phn'}><a className="click-to-call" href={"callto:" + this.state.vendor_phone_alt}><span className="icon"><span className="icon-call-answer"></span></span> {this.state.contact_phone_alt}</a></div> : null}


																		{(this.state.contact_email) ?
																			<div className={'soldby_icon soldby-mailing'}><a className="" href={"mailto:" + this.state.contact_email}><span className="icon"><span className="icon-mailing"></span></span> {this.state.contact_email}</a></div>
																			:
																			null
																		}
																		{(this.state.contact_designation) ? <div className={'soldby_icon soldby-seller'}><span><strong>Designation:</strong> {this.state.contact_designation}</span></div> : null}
																	</div>
																</div>
																: null
														}
													</div>
													<div className="col-sm-8 col-lg-8">
														{/* <MyMapComponent
															lat={parseFloat(this.state.latitude)}
															lng={parseFloat(this.state.longitude)}
														/> */}
													</div>
												</div>
											</div>
											: null
									}
									{
										(this.state.pageContent) ?
											<div className={'supplier-content-section'}>
												{renderHTML(this.state.pageContent)}
											</div>
											: null
									}
									<div className="socialShareWrap">
										<span className="social-text">
											{utils.Language('lang_socialShare', this.props.language)}
										</span>
										<EmailShareButton url={this.state.url}>
											<EmailIcon size={20} round={false} />
										</EmailShareButton>
										<FacebookShareButton url={this.state.url}>
											<FacebookIcon size={20} round={false} />
										</FacebookShareButton>
										<TwitterShareButton url={this.state.url}>
											<TwitterIcon size={20} round={false} />
										</TwitterShareButton>
										{/* <PinterestShareButton url={this.state.url}>
											<PinterestIcon size={20} round={false} />
										</PinterestShareButton> */}
									</div>
								</div>
							</div>
						</div>
					: <Loader />
				}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

export default connect(mapStateToProps, null)(Page);