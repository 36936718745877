import React, { useState } from "react";
// import { Link } from 'react-router-dom';
import { Button, FormGroup, FormControl, Form } from "react-bootstrap";
import webservice from '../../services/webservice';
import { Api } from '../../common/variables';

// Language
import utils from '../../common/utils';

export default function ForgotPassword( props ) {
	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState('');
	const [token, setToken] = useState( '' );
	const [resetcodesuccess, serverResetCodeSent] = useState( '' );
	const [resetValidToken, serverValidTokenCheck] = useState('');
	const [resetValidTokenerror, serverValidTokenCheckError] = useState('');
	const [password, setPassword] = useState('');
	const [passworderror, setPassworderror] = useState('');
	const [confirmpassword, setconfirmPassword] = useState('');
	const [confirmpassworderror, setconfirmPassworderror] = useState('');
	const [matchpassworderror, setmatchPassword] = useState('');
	const [cartmessage, setCartMessage] = useState('');

	const validateForm = () => {
		return email.length > 0;
	}

	const handleResetSubmit = async ( event ) => {
		event.preventDefault();
		setEmailError( '' );

		if ( email === '' ) {
			return;
		}

		//user authentication
		let credentials = {
			userEmailAddress: email
		}

		// Send token in email
		let authentication = await webservice.httpPost( Api.method.sendResetCodeToEmail, credentials );

		if ( authentication.status === 'ok' ) {
			serverResetCodeSent( true );
			// setCartMessage( utils.Language( 'lang_ResetCodeIntro', props.language ) );
		} else {
			if ( 'NO_MATCHING_EMAIL' === authentication.message ) {
				setEmailError( utils.Language( 'lang_invalidemail', props.language ) );
			}
			if ( 'SYSTEM_ERROR' === authentication.message ) {
				setEmailError( utils.Language( 'lang_systemError', props.language ) );
			}
		}
	}

	const handleValidToken = async ( event ) => {
		event.preventDefault();

		if ( token === '' ) {
			serverValidTokenCheckError( utils.Language( 'lang_ResetCodeEmptyMessage', props.language ) );
		} else {
			serverValidTokenCheckError( '' );
		}

		if ( email === '' || token === '' ) {
			return;
		}

		//user authentication
		let credentials = {
			userEmailAddress : email,
			resetCode        : token
		}

		// Validate token
		let authentication = await webservice.httpPost( Api.method.validateResetCode, credentials );
		if ( authentication.status === 'ok' ) {
			serverValidTokenCheck( true );
			// setCartMessage( utils.Language( 'lang_reviewSucceed', props.language ) );
		} else {
			if ( 'INVALID_RESET_CODE' === authentication.message ) {
				serverValidTokenCheckError( utils.Language( 'lang_ResetCodeInvalid', props.language ) );
			}
			if ( 'CODE_EXPIRE' === authentication.message ) {
				serverValidTokenCheckError( utils.Language( 'lang_ResetCodeExpire', props.language ) );
			}
			if ( 'EMPTY_USEREMAIL_RESET_CODE' === authentication.message ) {
				serverValidTokenCheckError( utils.Language( 'lang_ResetCodeError', props.language ) );
			}
		}
	}

	const handleResetPassword = async ( event ) => {
		event.preventDefault();

		if ( password === '' ) {
			setPassworderror( utils.Language( 'lang_PasswordRequired', props.language ) );
		} else {
			setPassworderror( '' );
		}

		if ( confirmpassword === '' ) {
			setconfirmPassworderror( utils.Language( 'lang_confirmPasswordRequired', props.language ) );
		} else {
			setconfirmPassworderror( '' );
		}

		if ( confirmpassword !== password && confirmpassword !== '' && password !== '' ) {
			setmatchPassword( utils.Language( 'lang_passwordResetPasswordError', props.language ) );
		} else {
			setmatchPassword( '' );
		}

		if ( password === '' || confirmpassword === '' || confirmpassword !== password ) {
			return;
		}

		//user authentication
		let credentials = {
			userEmail    : email,
			New_Password : password
		}

		// Reset password
		let authentication = await webservice.httpPost( Api.method.resetUserPassword, credentials );
		if ( authentication.status === 'ok' ) {
			setCartMessage( utils.Language( 'lang_passwordChanged', props.language ) );
			serverResetCodeSent( '' );
			serverValidTokenCheck( '' );
			props.signInSection();
		}
	}

	const alertMessage = () => {
		setTimeout(
			function() {
				setCartMessage( '' );
			},
			3000
		);
	}

	return (
		<div>
			<div className="modalHeader">
				<Button className="close" onClick={ props.exitPopup }>&times;</Button>
				{
					( ! resetcodesuccess && ! resetValidToken ) ?
					<p>{ utils.Language( 'lang_ResetPassword', props.language ) }</p>
					: null
				}
				{
					( resetcodesuccess && ! resetValidToken ) ?
					<p>{ utils.Language( 'lang_ResetCode', props.language ) }</p>
					: null
				}
				{
					( resetcodesuccess && resetValidToken ) ?
					<p>{ utils.Language( 'lang_newPassword', props.language ) }</p>
					: null
				}
			</div>
			<div className="modalBody">
				<div className="login">
					{
						( ! resetcodesuccess && ! resetValidToken ) ?
						<div className="forgotPwd">
							<p>{ utils.Language( 'lang_ResetPasswordIntro', props.language ) }</p>

							<form onSubmit={handleResetSubmit}>
								<FormGroup controlId="email">
									<Form.Label>{ utils.Language( 'lang_emailAddress', props.language ) } <span className="error-msg">*</span></Form.Label>
									<FormControl
									autoFocus
									type="email"
									value={email}
									onChange={e => setEmail(e.target.value)}
									className="inputBox"
									/>
									<div className="error-msg"><p>{ emailError }</p></div>
								</FormGroup>
								<div className="btnWrapper">
									<Button className="btn btn-success" disabled={!validateForm()} type="submit">
										{ utils.Language( 'lang_SendResetCode', props.language ) }
									</Button>
									<Button className="btn btn-danger" onClick={ props.signInSection }>
										{ utils.Language( 'lang_Cancel', props.language ) }
									</Button>
								</div>
							</form>
						</div>
						: null
					}
					{
						( resetcodesuccess && ! resetValidToken ) ?
						<div className="forgotPwd">
							<p>{ utils.Language( 'lang_ResetCodeIntro', props.language ) }</p>

							<form onSubmit={handleValidToken}>
								<FormGroup controlId="email">
									<Form.Label>{ utils.Language( 'lang_ResetCode', props.language ) } <span className="error-msg">*</span></Form.Label>
									<FormControl
									autoFocus
									type="text"
									value={token}
									onChange={e => setToken(e.target.value)}
									className="inputBox"
									maxLength="9"
									/>
									<div className="error-msg"><p>{ resetValidTokenerror }</p></div>
								</FormGroup>
								<div className="btnWrapper">
									<Button className="btn btn-success" type="submit">{ utils.Language( 'lang_Submit', props.language ) }</Button>
									<Button className="btn btn-danger" onClick={ props.signInSection }>
										{ utils.Language( 'lang_Cancel', props.language ) }
									</Button>
								</div>
							</form>
						</div>
						: null
					}
					{
						( resetcodesuccess && resetValidToken ) ?
						<div className="forgotPwd">
							<p>{ utils.Language( 'lang_NewPasswordIntro', props.language ) }</p>

							<form onSubmit={handleResetPassword}>
								<FormGroup controlId="password">
									<Form.Label>{ utils.Language( 'lang_newPassword', props.language ) } <span className="error-msg">*</span></Form.Label>
									<FormControl
									value={password}
									onChange={e => setPassword(e.target.value)}
									type="password"
									className="inputBox"
									/>
									<div className="error-msg"><p>{ passworderror }</p></div>
								</FormGroup>
								<FormGroup controlId="confirmpassword">
									<Form.Label>{ utils.Language( 'lang_ConfirmPassword', props.language ) } <span className="error-msg">*</span></Form.Label>
									<FormControl
									value={confirmpassword}
									onChange={e => setconfirmPassword(e.target.value)}
									type="password"
									className="inputBox"
									/>
									<div className="error-msg"><p>{ confirmpassworderror }</p></div>
									<div className="error-msg"><p>{ matchpassworderror }</p></div>
								</FormGroup>
								<div className="btnWrapper">
									<Button className="btn btn-success" type="submit">{ utils.Language( 'lang_Submit', props.language ) }</Button>
									<Button className="btn btn-danger" onClick={ props.signInSection }>
										{ utils.Language( 'lang_Cancel', props.language ) }
									</Button>
								</div>
							</form>
						</div>
						: null
					}
				</div>
			</div>
			{
				( cartmessage ) ?
					<div className="alertmessage">{ alertMessage() }{ cartmessage }</div>
				:
				null
			}
		</div>
	);
}