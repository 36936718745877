//SiteSearch js
import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import webservice from '../services/webservice';
import { Api } from '../common/variables';
import noImage from '../assets/images/no-image-available.png';
import Pagination from "../components/Pagination";
import MetaTags from 'react-meta-tags';
import { isBrowser } from 'react-device-detect';

// Language
import utils from '../common/utils';

// Redux set data
import { connect } from "react-redux";
import { removeFromWishlist, addToWishlist, addItemToCart } from '../services/common';
import { setCartCount, setAlertMessage } from '../action/commonAction';

class SearchSite extends Component {
	constructor() {
		super()
		this.state = {
			filteredProductsApi : false,
			initDoneLoader      : true,
			limit               : 25,
		}

		this.myDivToFocus = React.createRef();
	}

	async componentDidMount() {
		var query   = window.location.search.substring( 1 );
		var vars    = query.split( '=' );
		let keyword = vars[1].replace(/%20/g, ' ');
			keyword = keyword.replace( /[^a-zA-Z0-9\s]/g, "" );
		this.setState({
			searchText : keyword,
		})

		if ( keyword || '' === keyword ) {
			let params = {
				Keyword   : keyword,
				limit     : this.state.limit,
				lang      : this.props.language,
				SortBy    : 'price-highest-first',
				vendorID  : this.props.vendorInfos.data.id,
				Latitude  : this.props.geoLocation.latitude,
				Longitude : this.props.geoLocation.longitude
			}

			if ( true === isBrowser ) {
				params.isBrowser = isBrowser;
			}

			let products = await webservice.httpPost( Api.method.getDataBySearch, params );
			if ( products ) {
				if ( '' !== products.message ) {
					this.setState({
						products            : products.data,
						totalproducts       : 0,
						filteredProductsApi : true,
						initDoneLoader      : false,
						keyword             : keyword,
						paginationtype      : 'list',
					})
				} else {
					this.setState({
						products            : products.data,
						totalproducts       : products.total_records,
						filteredProductsApi : true,
						initDoneLoader      : false,
						keyword             : keyword,
						paginationtype      : 'list',
					})
				}
			}
		} else {
			this.setState({
				initDoneLoader      : false,
			})
		}
	}	

	searchBySort = async( e ) => {
		e.preventDefault();
		let params = {
			SortBy    : this.menu.value,
			limit     : this.state.limit,
			Keyword   : this.state.keyword,
			lang      : this.props.language,
			vendorID  : this.props.vendorInfos.data.id,
			Latitude  : this.props.geoLocation.latitude,
			Longitude : this.props.geoLocation.longitude,
		}

		if ( true === isBrowser ) {
			params.isBrowser = isBrowser;
		}

		let products = await webservice.httpPost( Api.method.getDataBySearch, params );
		if ( products ) {
			if ( '' !== products.message ) {
				this.setState({
					products            : products.data,
					totalproducts       : 0,
					filteredProductsApi : true,
					keyword             : this.state.keyword,
					CurrentPage         : 1,
					paginationtype      : 'filter',
				})
			} else {
				this.setState({
					products            : products.data,
					totalproducts       : products.total_records,
					filteredProductsApi : true,
					keyword             : this.state.keyword,
					CurrentPage         : 1,
					paginationtype      : 'filter',
				})
			}
		}
		const currentPage = 1;		
		const paginationData = {
			totalRecords: parseInt( this.state.totalproducts ),
			pageLimit: this.state.limit,
			pageNeighbours: 1,
			onPageChanged: this.onFilterPageChanged,
		};		
		this.setState({ currentPage }, () => this.onPageChanged(paginationData));
	}

	onPageChanged = async( data ) => {
		let params = {
			limit       : this.state.limit,
			Keyword     : this.state.keyword,
			lang        : this.props.language,
			vendorID    : this.props.vendorInfos.data.id,
			Offset      : undefined !== data.currentPage ? ( data.currentPage - 1 ) * data.pageLimit : 0,
			CurrentPage : undefined !== data.currentPage ? data.currentPage : 1,
			Latitude    : this.props.geoLocation.latitude,
			Longitude   : this.props.geoLocation.longitude,
			SortBy      : this.menu.value,
		}

		if ( true === isBrowser ) {
			params.isBrowser = isBrowser;
		}

		let products = await webservice.httpPost( Api.method.getDataBySearch, params );
		if ( products ) {
			if ( '' !== products.message ) {
				this.setState({
					products            : products.data,
					totalproducts       : 0,
					filteredProductsApi : true,
					keyword             : this.state.keyword,
					paginationtype      : 'list',
				})
			} else {
				this.setState({
					products            : products.data,
					totalproducts       : products.total_records,
					filteredProductsApi : true,
					keyword             : this.state.keyword,
					paginationtype      : 'list',
				})
			}

			const id      = 'scrolle_here';
			const yOffset = -160; 
			const element = document.getElementById( id );
			const y       = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
			window.scrollTo({top: y, behavior: 'smooth'});
		}
	}

	onFilterPageChanged = async( data ) => {
		let params = {
			limit       : this.state.limit,
			Keyword     : this.state.keyword,
			lang        : this.props.language,
			vendorID  : this.props.vendorInfos.data.id,
			Offset      : undefined !== data.currentPage ? ( data.currentPage - 1 ) * data.pageLimit : 0,
			CurrentPage : undefined !== data.currentPage ? data.currentPage : 1,
			Latitude    : this.props.geoLocation.latitude,
			Longitude   : this.props.geoLocation.longitude,
			SortBy      : this.menu.value,
		}

		if ( true === isBrowser ) {
			params.isBrowser = isBrowser;
		}

		let products = await webservice.httpPost( Api.method.getDataBySearch, params );
		if ( products ) {
			if ( '' !== products.message ) {
				this.setState({
					products            : products.data,
					totalproducts       : 0,
					filteredProductsApi : true,
					keyword             : this.state.keyword,
					paginationtype      : 'filter',
				})
			} else {
				this.setState({
					products            : products.data,
					totalproducts       : products.total_records,
					filteredProductsApi : true,
					keyword             : this.state.keyword,
					paginationtype      : 'filter',
				})
			}

			const id      = 'scrolle_here';
			const yOffset = -160; 
			const element = document.getElementById( id );
			const y       = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
			window.scrollTo({top: y, behavior: 'smooth'});
		}
	}

	// Add to cart item
	addItemToCart = async ( productID, minQty ) => {
		if ( minQty === 0 ) {
			minQty = 1;
		}

		const newdata = await addItemToCart( productID, minQty, this.props.language );
		this.props.setCartCount( newdata.count );
		this.props.setAlertMessage( newdata.message );
	}

	//add to wishlist
	addToWishlist = async ( productID ) => {
		const newdata = await addToWishlist( productID, this.state.products, this.props.language );

		if ( newdata.productsArray ) {
			this.setState({
				products: newdata.productsArray,
				productCategoriesApi: newdata.productsApi
			});
		}
		this.props.setAlertMessage( newdata.message );
	}

	//remove from wishlist
	removeFromWishlist = async ( productID ) => {
		const newdata = await removeFromWishlist( productID, this.state.products, this.props.language );

		this.setState({
			products: newdata.productsArray,
			productCategoriesApi: newdata.productsApi
		});
		this.props.setAlertMessage( newdata.message );
	}

	renderProducts() {
		const products = [];
		for ( let i = 0; i < 24; i++ ) {
			// Try avoiding the use of index as a key, it has to be unique!
			products.push(
				<div className="col-lg-2 col-md-3 col-sm-4 col-6" key={i}>
					<div className="categoryList" style={{boxShadow: 'none', alignItems: 'center',}}>
						<div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100px',}}>
							<img src={ require('../assets/images/placeholder.png') } alt="productsimage" style={{width: '30px'}} />
						</div>
						<div style={{width: '70%', backgroundColor: '#ddd', height: '10px', borderRadius: '3px', marginLeft: 'auto', marginRight: 'auto', marginBottom: '5px'}}>
						</div>
						<div style={{width: '50%', backgroundColor: '#ddd', height: '10px', marginLeft: 'auto', marginRight: 'auto', borderRadius: '3px',}}>
						</div>
					</div>
				</div>
			);
		}
		return products;
	}

	render() {
		return (
			<div className="category_wrapper" ref={this.myDivToFocus} id="scrolle_here">
				{/*For Meta tags Dynamic content*/}
				<MetaTags>
					<title>
						Gharbanau - A marketplace for construction materials for Nepal
					</title>
					<meta name="keywords" content="search, gharbanau" />
					<meta
					name="description"
					content="A marketplace for construction materials for Nepal"
					/>
				</MetaTags>

				<div className="wrapper wrapper_1200 padding_side_15">					
					<div className="row">
						<div className="col-sm-12 searchsitelist">
							<div className="miniTitle-div breadcrumb-section">
								<div className="breadcrumb-div">
									<span className=" breadcrumb-item"><b>{ utils.Language( 'lang_searchResult', this.props.language ) }: { this.state.searchText }</b></span>
								</div>

								<div className="section_sort">
									<div className="section_sort-div">
										<select defaultValue="price-highest-first" ref = {(input)=> this.menu = input} onChange={ this.searchBySort }>
											<option value="price-highest-first">{ utils.Language( 'lang_price_high_to_low', this.props.language ) }</option>
											<option value="price-lowest-first">{ utils.Language( 'lang_price_low_to_high', this.props.language ) }</option>
											<option value="popularity">{ utils.Language( 'lang_popularity', this.props.language ) }</option>
											<option value="latest-update">{ utils.Language( 'lang_latestUpdate', this.props.language ) }</option>
										</select>
									</div>
								</div>
							</div>

							<div className="category_wrapper-list">
							{
								( ! this.state.initDoneLoader ) ?
								<div className="row">
									{
										( this.state.products )
										?
											this.state.products.map( (products, index) => {
												return(
													<div className="col-lg-2 col-md-3 col-sm-4 col-6" key={ products.id }>
														<div className="categoryList" id={ products.id }>
															{
																( 'seller' !== this.props.userType ) ?
																<div className="wishlist-div">
																	{
																		( true === products.wishlistStatus ) ?
																		<div class="wishlist wishlist-added" title="Wishlist" onClick={ () => { this.removeFromWishlist( products.id ) } }><span className="icon-heart-black icon-heart-red icon-fav"></span></div>
																		:
																		<div class="wishlist" title="Wishlist" onClick={() => { this.addToWishlist( products.id ) } }><span className="icon-heart-black icon-fav"></span></div>
																	}
																</div>
																: null
															}
															<Link to={'/products/'+encodeURIComponent(products.slug)} className="link">
																<div className="imgWrapper">
																	<img src={ products.imageUrl ? products.imageUrl : noImage } alt="categoryimage" />
																</div>
																<span className="product-name">{products.name}</span>
																<div className="priceSec">
																	{
																		( products.regularPrice !== products.salePrice && products.salePrice !== '0.00'  ) ?
																			<div><span className="regularprice">{ utils.Language( 'lang_currency', this.props.language ) }{ new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format( products.regularPrice ) }</span>
																			<span className="saleprice">{ utils.Language( 'lang_currency', this.props.language ) }{ new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format( products.salePrice ) }
																				{
																					( products.salePriceValidTill ) ?
																						<div className="singleBrands validtilldate">
																							<span className="smallText">({ utils.Language( 'lang_priceValidTill', this.props.language ) } { products.salePriceValidTill })</span>
																						</div>
																					: null
																				}
																			</span></div>
																		:
																		<span className="saleprice">{ utils.Language( 'lang_currency', this.props.language ) }{ new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format( products.regularPrice ) }</span>
																	}
																</div>
															</Link>

															<div className="starrating-div">
															{
																( this.state.rating ) ?
																	<StarRatings
																	rating={ parseFloat( this.state.rating ) }
																	starRatedColor="#ff6f00"
																	starHoverColor="#ff6f00"
																	numberOfStars={5}
																	name='rating'
																	starDimension="20px"
																	starSpacing="0"
																	/>
																: null
															}
															</div>
															{
																( 'seller' !== this.props.userType ) ?
																	<div data-mh className="productDetail productDetailnew">
																		<button type="button" className="btn btn-danger btn-add-cart" onClick={() => { this.addItemToCart( products.id, parseInt( products.minimum_quantity ) ) }}><span className="icon-cart-add"></span>{ utils.Language( 'lang_addToCart', this.props.language ) }</button>
																	</div>
																: null
															}
														</div>
													</div>
												)
											})
										: 
											<div className="col-12 text-center blank-item">
												<p>{ utils.Language( 'lang_productNotFound', this.props.language ) }</p>
											</div>
									}
								</div>
								: <div className="row">{ this.renderProducts() }</div>
							}
							</div>

							{
								( this.state.totalproducts > 0 ) ?
									<div className="pagination_wrapper">
										<span className="pagination-items">{ this.state.totalproducts } { utils.Language( 'lang_itemFound', this.props.language ) }</span>
										{ 
											( this.state.totalproducts > this.state.limit  && 'list' === this.state.paginationtype ) ?
												<Pagination
													totalRecords={ parseInt( this.state.totalproducts ) }
													pageLimit={ parseInt( this.state.limit ) }
													pageNeighbours={1}
													onPageChanged={ this.onPageChanged }
												/>
											: null
										}
										{ 
											( this.state.totalproducts > this.state.limit  && 'filter' === this.state.paginationtype ) ?
												<Pagination
													totalRecords={ parseInt( this.state.totalproducts ) }
													pageLimit={ parseInt( this.state.limit ) }
													pageNeighbours={1}
													onPageChanged={ this.onFilterPageChanged }
												/>
											: null
										}
									</div>
								: ''
							}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({
	setCartCount: ( count ) => dispatch( setCartCount( count ) ),
	setAlertMessage: ( message ) => dispatch( setAlertMessage( message ) )
});

export default connect( mapStateToProps, mapDispatchToProps )(SearchSite);