// ProductDescription js
import React, {Component} from 'react';
// import Pagination from "../Pagination";
import StarRatings from 'react-star-ratings';
import renderHTML from 'react-render-html';

// Language
import utils from '../../common/utils';

class ProductDescription extends Component {
	constructor( props ) {
		super( props );
		this.state = {
			open: false,
		}
	}

	render() {
		return (
			<div className="full-width">
			{
				( this.props.data.description ) || ( this.props.data.sku ) || ( this.props.data.size ) || ( this.props.data.length ) || ( this.props.data.height ) || ( this.props.data.width ) || ( this.props.data.weight ) ?
				<div className="descriptionSpecification">
					<div className="description-wrapper">

						{
							( this.props.data.description ) ?
							<div className="description">
								<div className="full-width">
									<h3>{ utils.Language( 'lang_description', this.props.language ) }</h3>

									<div className="entry-content">
										{ renderHTML( this.props.data.description ) }
									</div>
								</div>
							</div>
							:
							null
						}


						{
							( this.props.data.sku ) || ( this.props.data.size ) || ( this.props.data.length ) || ( this.props.data.height ) || ( this.props.data.width ) || ( this.props.data.weight ) ?
							<div className="specification">
								<h3>{ utils.Language( 'lang_Specification', this.props.language ) }</h3>
								<div className="row">
									{
										( this.props.data.sku ) ? 
										<div className="col-md-3">
											<span className="specification-type">{ utils.Language( 'lang_SKU', this.props.language ) }</span>
											<div className="brandsku">
												{this.props.data.sku}
											</div>
										</div>
										:
										null
									}


									{
										( this.props.data.size ) ? 
										<div className="col-md-3">
											<span className="specification-type">{ utils.Language( 'lang_ItemSize', this.props.language ) }</span>
											<div className="brandsize">
												{this.props.data.size}
											</div>
										</div>
										:
										null
									}


									{
										( this.props.data.length ) ? 
										<div className="col-md-3">
											<span className="specification-type">{ utils.Language( 'lang_ItemLength', this.props.language ) }</span>
											<div className="brandsize">
												{this.props.data.length}
											</div>
										</div>
										:
										null
									}


									{
										( this.props.data.height ) ? 
										<div className="col-md-3">
											<span className="specification-type">{ utils.Language( 'lang_ItemHeight', this.props.language ) }</span>
											<div className="brandsize">
												{ ( this.props.data.height ) ? this.props.data.height : '-' }
											</div>
										</div>
										:
										null
									}


									{
										( this.props.data.width ) ? 
											<div className="col-md-3">
											<span className="specification-type">{ utils.Language( 'lang_ItemWidth', this.props.language ) }</span>
											<div className="brandsize">
												{this.props.data.width}
											</div>
										</div>
										:
										null
									}


									{
										( this.props.data.weight ) ? 
										<div className="col-md-3">
											<span className="specification-type">{ utils.Language( 'lang_ItemWeight', this.props.language ) }</span>
											<div className="brandunit">
												{this.props.data.weight}
											</div>
										</div>
										:
										null
									}
								</div>
							</div>
							:
							null
						}
					</div>
				</div>
				:
				null
			}


				{
					( this.props.ratingreview ) ?
					<div className="descriptionSpecification">
						<div className="ratingReview">
							<h3>{ utils.Language( 'lang_ratingsReviews', this.props.language ) }</h3>

							<div className="filterSort">
								<div className="pdp-mod-filterSort">
									<span className="title">{ utils.Language( 'lang_productReviews', this.props.language ) }</span>
								</div>
							</div>
							{
							this.props.ratingreview.map((item,index) => {
								return(
									<div className="mod-reviews full-width" key={index}>
										<div className="item">
											<div className="top">
												<div className="start-ratings">
													{
														( item.rating ) ?
															<StarRatings
															rating={ parseFloat( item.rating ) }
															starRatedColor="#ff6f00"
															starHoverColor="#ff6f00"
															numberOfStars={5}
															name='rating'
															starDimension="15px"
															starSpacing="0"
															/>
														: null
													}
												</div>
												<span className="title right">{ item.date }</span>
											</div>
											<div className="middle">
												<span>by { item.name }</span>
												{/*<span>
													<span className="icon-tick colorGreen"></span>
													<span className="verify colorGreen">Verified Purchase</span>
												</span>*/}
											</div>
											<div className="item-content">
												<div className="content">{ item.comment }</div>
											</div>
										</div>
									</div>
								)
							})
							}
						</div>
					</div>
					: null
				}
			</div>
		);
	}
}

export default ProductDescription;