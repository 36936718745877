/* eslint-disable jsx-a11y/anchor-is-valid */
// Header.js
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown'
// import DropdownButton from 'react-bootstrap/DropdownButton'
import Popup from "reactjs-popup";
import LoginForm from '../pages/userpages/Login';
import webservice from '../services/webservice';
import { Api } from '../common/variables';
import SearchForm from '../components/SearchForm';
import MetaTags from 'react-meta-tags';
// import MapPopup from '../components/MapPopup';
import { isMobile, isIOS } from 'react-device-detect';
import mobileimage from '../assets/images/banner_hamburger_300.jpg';

// Language
import utils from '../common/utils';

// Redux set data
import { connect } from "react-redux";
import { setCartCount, setAlertMessage, setCurrentLanguage, setUserType } from '../action/commonAction';
// let lastScrollY = 0;
let ticking = false;

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: 0,
            isOpen: null,
            userdetail: null,
            userLoginAlertMsg: null,
            dashBoardUrl: null,
            ipaddress: null,
            isHome: '',
            shortname: '',
            headerMenus: [],
            headerMenuApi: false,
            isBecomeASeller: false,
            Device_Browser: '',
        };
        
        this.openModalPopup = this.openModalPopup.bind(this);
        this.closeModalPopup = this.closeModalPopup.bind(this);
        // this.handlePageChange = this.handlePageChange.bind(this);

        this.navOpen = this.navOpen.bind(this);
        this.navClose = this.navClose.bind(this);
    }

    navOpen() {
        this.setState({ navStatus: "navShow" });
        document.body.classList.add('noScroll');
    }

    navClose() {
        this.setState({ navStatus: "navHide" });
        document.body.classList.remove('noScroll');
    }

    async componentDidMount() {
        // header search status check
        window.addEventListener('scroll', this.handleScroll, true);
        window.addEventListener('click', this.handleScroll, true);
        this.setState({
            isHome: window.location.pathname
        })

        let params = {
            ViewType: 'webmenulist',
            lang: this.props.language
        }

        const language = localStorage.getItem('gharbanu_language');
        if (language) {
            const details = JSON.parse(language);
            this.props.setCurrentLanguage(details.lang);

            // set current language for getting categories
            params.lang = details.lang;
        }

        // if logout from backend then logout in front
        if ("?action=logout" === window.location.search) {
            localStorage.removeItem('userdetail');
        }

        // get userdeatils if login
        const userdetails = localStorage.getItem('userdetail');
        if (userdetails) {
            const details = JSON.parse(userdetails);

            var str = details.FullName;
            var matches = str.match(/\b(\w)/g);
            var acronym = matches.join('');

            this.setState({
                loggedIn: 1,
                userID: details.userID,
                token: details.token,
                profile_image: details.profile_image,
                name: details.FullName,
                shortname: acronym
            });

            if ('seller' === details.User_Type) {
                this.setState({
                    dashBoardUrl: Api.backEndapiUrl + 'vendor/dashboard'
                });
            }

            if ('buyer' === details.User_Type) {
                this.setState({
                    dashBoardUrl: Api.backEndapiUrl + 'user/dashboard'
                });
            }

            this.props.setUserType(details.User_Type);

            let params = {
                userid: details.userID,
                lang: this.props.language
            }
            let getCartCount = await webservice.httpPost(Api.method.getCartCount, params);
            if ('ok' === getCartCount.status) {
                this.props.setCartCount(getCartCount.data.cartItemCount);
            }
        }

        // get all categories
        // let all_categories = await webservice.httpPost( Api.method.getAllCategories, params );
        let all_categories = await webservice.httpPost(Api.method.getFeaturedCategories, params);
        if (all_categories.status === 'ok') {
            this.setState({
                categories: all_categories.data,
                CategoriesApi: true,
            })
        }

        let headerMenu = await webservice.httpPost(Api.method.getHeaderNavMenu);

        if (headerMenu.status === 'ok' && headerMenu.data.length > 0) {
            this.setState({
                headerMenus: headerMenu.data,
                headerMenuApi: true,
            })
        }

        if (isMobile || isIOS) {
            let footerMenu = await webservice.httpPost(Api.method.getFooterNavMenu);
            if (footerMenu.status === 'ok' && footerMenu.data.length > 0) {
                let UpdatePages = [...this.state.headerMenus];
                let addMorePages = UpdatePages.concat(footerMenu.data);
                this.setState({
                    headerMenus: addMorePages,
                    headerMenuApi: true,
                })
            }
        }

        var OS_Name = navigator.appVersion;
        var osName;

        if (OS_Name.indexOf("Win") !== -1) {
            osName = "windows";
        } else if (OS_Name.indexOf("Mac") !== -1) {
            osName = "mac";
        } else if (OS_Name.indexOf("X11") !== -1) {
            osName = "unix";
        } else if (OS_Name.indexOf("Linux") !== -1) {
            osName = "unix";
        } else if (OS_Name.indexOf("SunOS") !== -1) {
            osName = "solaris";
        } else {
            osName = "unknown";
        }

        document.getElementsByTagName('body')[0].classList.add(osName);

        var BrowserDetect = {
            init: function() {
                this.browser = this.searchString(this.dataBrowser) || "Other";
                this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || "Unknown";
            },

            inform: function() {
                BrowserDetect.init();

                var browser = BrowserDetect.browser.toLocaleLowerCase();
                var classNames = 'browser_is_' + browser;
                var browserVersion = browser + '-' + BrowserDetect.version;

                document.getElementsByTagName('body')[0].classList.add(classNames);
                document.getElementsByTagName('body')[0].classList.add(browserVersion);
            },

            informBrowser: function() {
                BrowserDetect.init();
                var browserVersion = BrowserDetect.browser + ', ' + BrowserDetect.version;
                return browserVersion;
            },

            searchString: function(data) {
                for (var i = 0; i < data.length; i++) {
                    var dataString = data[i].string;
                    this.versionSearchString = data[i].subString;

                    if (dataString.indexOf(data[i].subString) !== -1) {
                        return data[i].identity;
                    }
                }
            },

            searchVersion: function(dataString) {
                var index = dataString.indexOf(this.versionSearchString);
                if (index === -1) return;
                return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));
            },

            dataBrowser: [{
                string: navigator.userAgent,
                subString: "Chrome",
                identity: "Chrome"

            }, {
                string: navigator.userAgent,
                subString: "MSIE",
                identity: "Explorer"

            }, {
                string: navigator.userAgent,
                subString: "Firefox",
                identity: "Firefox"
            }, {
                string: navigator.userAgent,
                subString: "Safari",
                identity: "Safari"
            }, {
                string: navigator.userAgent,
                subString: "Opera",
                identity: "Opera"
            }]
        };

        BrowserDetect.inform();

        this.setState({ Device_Browser: osName + ', ' + BrowserDetect.informBrowser() });

        /*Detecting IE EDGE*/
        /**
         * detect IE
         * returns version of IE or false, if browser is not Internet Explorer
         */
        function detectIE() {
            var ua = window.navigator.userAgent;

            var msie = ua.indexOf('MSIE ');
            if (msie > 0) {
                return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
            }

            var trident = ua.indexOf('Trident/');
            if (trident > 0) {
                var rv = ua.indexOf('rv:');
                return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
            }

            var edge = ua.indexOf('Edge/');
            if (edge > 0) {
                return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
            }

            return false;
        }

        var version = detectIE();

        if (version === false) {
            //
        } else if (version >= 12) {
            document.getElementsByTagName('body')[0].classList.add('edgeBrowser');
            this.setState({ Device_Browser: 'edge, ' + version });
        } else {
            this.setState({ Device_Browser: 'IE, ' + version });
            document.getElementsByTagName('body')[0].classList.add('IE_' + version);
        }

        if ("ontouchstart" in document.documentElement) {
            document.getElementsByTagName('body')[0].classList.add('is-touch-device');
        } else {
            document.getElementsByTagName('body')[0].classList.add('no-touch-device');
        }

        // search
        var query = window.location.search.substring(1);
        if ('' !== query && query.includes('keyword=')) {
            var vars = query.split('=');
            let keyword = vars[1].replace(/%20/g, ' ');
            this.setState({
                searchText: keyword,
            });
        }

        await fetch('https://ipapi.co/json/')
            .then(response => response.json())
            .then(data => this.setState({ ipaddress: data.ip }))
            .catch(error => this.setState({ ipaddress: '' }));
    }

    handleOpen = (key) => {
        this.setState({ isOpen: key })
    }

    handleClose = (key) => {
        this.setState({ isOpen: null })
    }

    LogOut = async () => {
        var str = window.location.pathname;
        var replacefirstslash = str.replace('/', "");
        // store login history of user
        let params = {
            'Platform': 2,
            'AuthStatus': 2,
            'ip': this.state.ipaddress,
            'UserId': this.state.userID,
            'Device_Browser': this.state.Device_Browser
        }
        await webservice.httpPost(Api.method.getUserLoginHistory, params);

        localStorage.removeItem('userdetail');

        this.setState({
            loggedIn: null
        });

        document.location.href = Api.backEndapiUrl + 'set-session/?token=' + this.state.token + '&type=0&currenturl=' + replacefirstslash;
    }

    openModalPopup = (e) => {
        this.setState({ open: true });
    }

    openModalPopupBecomease = (e) => {
        this.setState({
            open: true,
            isBecomeASeller: true
        });
    }

    closeModalPopup = (e) => {
        this.setState({
            open: false,
            isBecomeASeller: false
        });
    }

    changeLanguage = (e) => {
        this.props.setCurrentLanguage(e);
        localStorage.setItem('gharbanu_language', JSON.stringify({ 'lang': e }));
        window.location.reload();
    }

    alertMessage = () => {
        setTimeout(
            function() {
                this.props.setAlertMessage('');
            }
            .bind(this),
            2000
        );
    }

    handleScroll = () => {
        // lastScrollY = window.scrollY;
        this.setState({
            isHome: window.location.pathname
        })

        if (!ticking) {
            window.requestAnimationFrame(() => {
                // this.nav.current.style.top = `${lastScrollY}px`;
                this.setState({
                    isHome: window.location.pathname
                })
                ticking = false;
            });

            ticking = true;
        }
    }

  

    render() {  
        return (
            <header className="section-header" ref={this.nav}>
				{/*For Meta tags Dynamic content*/}
				<MetaTags>
				{
					( 'www.gharbanau.com' === window.location.host || 'gharbanau.com' === window.location.host ) ?
						<meta name="robots" content="index, follow" />
					: <meta name="robots" content="noindex, nofollow" />
				}

				</MetaTags>
				{
					( this.props.alertMessages ) ?
						<div className="alertmessage">{ this.alertMessage() }{ this.props.alertMessages }</div>
					:
					null
				}

				{ this.state.userLoginAlertMsg ? <div className="alertmessage">{ this.state.userLoginAlertMsg }</div> : null }

				<nav className="navbar navbar-fixed-top" role="navigation">
					<div className="wrapper wrapper_1200">
						<div className="row">
							<div className="col-12">
								<div className="container-head d-flex">
									<div className="navbar-header float-left">
                                       
									<div className="mobile_menu">
										<button onClick={this.navOpen}>
											<span className="cat_dash showinmobile"></span>
										</button>
									</div>

										<NavLink to="/" className="navbar-brand">
											<img src={require('../assets/images/logo.png')} alt="Logo" />
										</NavLink>

										<div className="menu_bar">
											<div className="nav_left">
                                            
												{
												/*<div className="nav_categories dropdown">
													<Dropdown>
														<div className="dropdown-categories">
															<Dropdown.Toggle id="dropdown-basic" variant="custom">
															<div id="dropdown-basic">
																<span className="cat_text">{ utils.Language( 'lang_allCategories', this.props.language ) }</span>
																<span className="cat_arrow icon-arrow-down"></span>
																</div>
															 </Dropdown.Toggle>
														</div>

														<Dropdown.Menu className="no-radius">
															{
																( this.state.categories ) ?
																	this.state.categories.map( ( cat, index ) => {
																		var dropdownclass = 'btn btn-block no-radius remove-arrow';

																		return (
																			<div className="full-width" key={index}>
																				<DropdownButton
																					drop='right'
																					href={`/category/${encodeURIComponent(cat.slug)}`}
																					title={`${cat.name}`}
																					id={`dropdown-button-drop-${index}`}
																					key={index}
																					onMouseOver = { ()=>{this.handleOpen( index )} }
																					onMouseLeave = { ()=>this.handleClose( index ) }
																					show={ this.state.isOpen === index }
																					className={ dropdownclass }
																				>
																				</DropdownButton>
																			</div>
																		);
																	})
																: null
															}
															<div className="full-width">
																<DropdownButton
																	drop='right'
																	href={`/product-categories/`}
																	title={ utils.Language( 'lang_ViewAllCategories', this.props.language ) }
																	className='btn btn-block remove-arrow cat-viewall'
																>
																</DropdownButton>
															</div>
														</Dropdown.Menu>
													</Dropdown>
												</div>*/
												}
												
												<div className="nav-bar header-nav">
													<div className="nav_link">
														<NavLink to="/product-categories" className="link"><span>{ utils.Language( 'lang_allCategories', this.props.language ) }</span></NavLink>
														<div className="nav_link-detail">
															{
																( this.state.categories ) ?
																	this.state.categories.map( ( cat, index ) => {
																		return (
																			<a href={`/category/${encodeURIComponent(cat.slug)}`} className="link" key={index}>{`${cat.name}`}</a>
																		);
																	})
																: null
															}
															<a href={`/product-categories`} className="link">{ utils.Language( 'lang_ViewAllCategories', this.props.language ) }</a>
														</div>
														
													</div>
													<div className="blackoverlay"></div>
													<div className="nav_link"><NavLink to="/suppliers" activeClassName="active" className="link">{ utils.Language( 'lang_findSuppliers', this.props.language ) }</NavLink></div>
													{
														( this.state.headerMenuApi ) ?
															this.state.headerMenus.map ( ( data, index ) => {
																if ( 'default' === data.Template )  {
																	return (
																		<div key={'default' + index} className="nav_link"><a href={'/page/' + encodeURIComponent( data.Page_Identifier )} key={index} className="link">{ ( 'En' === this.props.language ) ? data.Page_Title_En : data.Page_Title_Np }</a></div>
																	)
																}

																if ( 'withheader' === data.Template) {
																	return (
																		<div key={'withheader' + index} className="nav_link"><a href={'/page-h/' + encodeURIComponent( data.Page_Identifier )} key={index} className="link">{ ( 'En' === this.props.language ) ? data.Page_Title_En : data.Page_Title_Np }</a></div>
																	)
																}

																if ( 'centercontent' === data.Template ) {
																	return (
																		<div key={'centercontent' + index} className="nav_link"><a href={'/page-c/' + encodeURIComponent( data.Page_Identifier )} key={index} className="link">{ ( 'En' === this.props.language ) ? data.Page_Title_En : data.Page_Title_Np }</a></div>
																	)
																} else {
																	return false;
																}
															})
														: null
													}
													{
														( 0 === this.state.loggedIn ) ?
														<a className="link" id="becomeaseller" onClick={this.openModalPopupBecomease}>
															{ utils.Language( 'lang_becomeASeller', this.props.language ) }</a>
														: null
													}
												</div>
											</div>
											<SearchForm />
										</div>
									</div>
									<div id="" className="float-right user-profile-div">
										<div className="nav_right">
                                            {/* removing google map so removing map */}
											{/* <div className="mapicon">{<MapPopup signup={false} />}</div> */}
											<div className="nav_right language-box">
												<div className="switch-language">
													<div className={ ( 'En' === this.props.language ) ? 'active language-wrapper to-english' : 'language-wrapper to-english' } onClick={ ( e ) =>  this.changeLanguage( "En" ) }><span className="flag-img"><img src={require('../assets/images/uk.png')} alt="uk" /></span><span className="flagText menu-label">English</span></div>
													<div className={ ( 'Np' === this.props.language ) ? 'active language-wrapper to-nepali' : 'language-wrapper to-nepali' } onClick={ ( e ) =>  this.changeLanguage( "Np" ) }><span className="flag-img"><img src={require('../assets/images/nepal.png')} alt="np" /></span><span className="flagText menu-label">Nepali</span></div>
													
												</div>
											</div>

											{
												( this.state.loggedIn && 'seller' !== this.props.userType ) ?
												<div className="userpages">
													<NavLink to="/cart" className="nav_right-item nav_right-cart">
														<i className="icon-cart"></i>
														<span className="text-cart menu-label">
															{ utils.Language( 'lang_footerCart', this.props.language ) }
														</span>
														{
															( this.props.cartCount ) ?
															<span className="cart-count-bubble">{ this.props.cartCount }</span>
															: null
														}
													</NavLink>
												</div>
												:
												null
											}
										</div>

										{
											this.state.loggedIn ?
											<div className="user-profile-wrap">
												<Dropdown>
													<div className="dropdown-controller">
														{/*<span className="loggedIn">{ this.state.name }</span>*/}
														<Dropdown.Toggle id="dropdown-basic" variant="user">
															 <div className="userimage-div">
																{
																	( this.state.profile_image ) ?
																	<img src={ this.state.profile_image } alt="profile" />
																	: <span className="icon-user-wrap">{ this.state.shortname }</span>
																}
															</div>
														</Dropdown.Toggle>
                                                        {/* <img src={mobileimage} /> */}
														<span className="menu-label">Profile</span>
													</div>
													<Dropdown.Menu alignRight show>
														<Dropdown.Item href={this.state.dashBoardUrl}>{ utils.Language( 'lang_dashboardTitle', this.props.language ) }</Dropdown.Item>
														<Dropdown.Item onClick={this.LogOut} className="logoutLink"><span className="">{ utils.Language( 'lang_LogOut', this.props.language ) }</span></Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											</div>
										:
											<div className="wrapperdiv">
												{
													( 767 < window.innerWidth ) ?
													<div className="user-profile-wrap">
														<button className="button" id="LoginPopup" onClick={this.openModalPopup}>
														<span className="icon-user-wrap"><i className="icon_user"></i><span>{ utils.Language( 'lang_profile', this.props.language ) }</span></span></button>
													</div>
													: null
												}
											</div>
										}
									</div>									
								</div>
							</div>
							<div className="col-12">
							</div>							
						</div>
					</div>
				</nav>

				<div id="drawers" className={this.state.navStatus}>
					{
						this.state.loggedIn ?
						<div className="user-profile-wrap">
							{/*<div className="userimage-div">
								 {
									( this.state.profile_image ) ?
									<img src={ this.state.profile_image } alt="profile" />
									: <span className="icon-user-wrap">{ this.state.profile_image }</span>
								}                                 
							</div>*/}
                            <div className="por">
                                <img src={mobileimage} alt='mobile logo' />
                                {
                                    this.state.loggedIn ?
                                    <NavLink to="" className="link logoutLink" onClick={this.LogOut} >{ utils.Language( 'lang_LogOut', this.props.language ) }</NavLink>
                                    :
                                    null
                                }
                            </div>
							{/* <span className="loggedIn">{ this.state.name }</span> */}
                            
						</div>
					:
					<div className="">
						{
							( 767 > window.innerWidth ) ?
								<div className="user-profile-wrap">
                                    <img src={mobileimage} alt='mobile logo' />
									<button className="button user-login-button" id="LoginPopup" onClick={this.openModalPopup}>
										{/* <span className="icon-user-wrap"><i className="icon_user"></i><span className="text-profile">Profile</span></span> */}
										<span className="login-text">
                                        <span>
										{ utils.Language( 'lang_LogIn', this.props.language ) }
									    </span>
                                        <span> / </span>
                                        <span> { utils.Language( 'lang_signUp', this.props.language ) }</span></span>
									</button>
                                   
									<Popup
									open={this.state.open}
									closeOnDocumentClick
									onClose={this.closeModalPopup}
									>
										<div className="PopupBox">
											<LoginForm
											Device_Browser = { this.state.Device_Browser }
											onCallBack={this.closeModalPopup}
											language={ this.props.language }
											ipaddress={ this.state.ipaddress }
											setAlertMessage = { (message) => this.props.setAlertMessage( message ) }
											/>
										</div>
									</Popup>
								</div>
							: null
						}
					</div>
					}

					<div className="nav-bar header-nav">                       
						{
							this.state.loggedIn ?
							<a href={this.state.dashBoardUrl} className="link" onClick={this.navClose}>{ utils.Language( 'lang_dashboardTitle', this.props.language ) }</a>
							:
							null
						}
						<NavLink to="/suppliers" activeClassName="active" className="link" onClick={this.navClose}>{ utils.Language( 'lang_findSuppliers', this.props.language ) }</NavLink>
						{
							( this.state.headerMenuApi ) ?
								this.state.headerMenus.map ( ( data, index ) => { 
									if ( 'default' === data.Template )  {
										return (
											<a href={'/page/' + encodeURIComponent( data.Page_Identifier )} key={index} className="link">{ ( 'En' === this.props.language ) ? data.Page_Title_En : data.Page_Title_Np }</a>
										)
									}

									if ( 'withheader' === data.Template) {
										return (
											<a href={'/page-h/' + encodeURIComponent( data.Page_Identifier )} key={index} className="link">{ ( 'En' === this.props.language ) ? data.Page_Title_En : data.Page_Title_Np }</a>
										)
									}

									if ( 'centercontent' === data.Template ) {
										return (
											<a href={'/page-c/' + encodeURIComponent( data.Page_Identifier )} key={index} className="link">{ ( 'En' === this.props.language ) ? data.Page_Title_En : data.Page_Title_Np }</a>
										)
									} else {
										return false;
									}
								})
							: null
						}
						{
							( 0 === this.state.loggedIn ) ?
							<a className="link" id="becomeaseller" onClick={this.openModalPopupBecomease}>
								{ utils.Language( 'lang_becomeASeller', this.props.language ) }</a>
							: null
						}
						{/* {
							this.state.loggedIn ?
							<NavLink to="" className="link logoutLink" onClick={this.LogOut} >{ utils.Language( 'lang_LogOut', this.props.language ) }</NavLink>
							:
							null
						} */}
					</div>

					{/*<div className="nav_right language-box section_sort-div">
						<div className="switch-language">
							<div className={ ( 'En' === this.props.language ) ? 'active language-wrapper to-english' : 'language-wrapper to-english' } onClick={ ( e ) =>  this.changeLanguage( "En" ) }><img src={require('../assets/images/uk.png')} alt="uk" /></div>
							<div className={ ( 'Np' === this.props.language ) ? 'active language-wrapper to-nepali' : 'language-wrapper to-nepali' } onClick={ ( e ) =>  this.changeLanguage( "Np" ) }><img src={require('../assets/images/nepal.png')} alt="np" /></div>
						</div>
					</div>*/}

					<div className="navClose" onClick={this.navClose}>
						<span className="icon-cancel"></span>
					</div>
				</div>
				<div id="mask" className={this.state.navStatus} onClick={this.navClose}></div>
			
				<Popup
				open={this.state.open}
				closeOnDocumentClick={false}
				onClose={this.closeModalPopup}
				>
					<div className="PopupBox">
						<LoginForm
						Device_Browser = { this.state.Device_Browser }
						onCallBack = { this.closeModalPopup }
						language = { this.props.language }
						ipaddress = { this.state.ipaddress }
						setAlertMessage = { (message) => this.props.setAlertMessage( message ) }
						isBecomeASeller = { this.state.isBecomeASeller }
						/>
					</div>
				</Popup>
			</header>
        )
    }
}

const mapStateToProps = state => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    setCartCount: (count) => dispatch(setCartCount(count)),
    setUserType: (usertype) => dispatch(setUserType(usertype)),
    setCurrentLanguage: (lang) => dispatch(setCurrentLanguage(lang)),
    setAlertMessage: (message) => dispatch(setAlertMessage(message))
});

// const history = useHistory();
// const handleBackClick = () => {
//     history.goBack();
// }

export default connect(mapStateToProps, mapDispatchToProps)(Header);