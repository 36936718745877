// ProductMeta js
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import { FormControl } from "react-bootstrap";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
// import noImage from '../../assets/images/no-image-available.png';
import Popup from "reactjs-popup";
import renderHTML from 'react-render-html';
import Lightbox from 'react-image-lightbox';
import { FacebookShareButton, TwitterShareButton, EmailShareButton, TwitterIcon, FacebookIcon, EmailIcon, } from "react-share";
import {BrowserView, MobileView} from 'react-device-detect';

// Language
import utils from '../../common/utils';

class ProductMeta extends Component {
	constructor(props) {
		super(props)
		this.state = {
			productquantity: this.props.productdata.minorder,
			open: false,
			isOpen: false,
			isUserLogin: this.props.isUserLogin,
			photoIndex: 0,
			maxQty: 999999,
			photoIndexCertificate: 0,
			isOpenCertificate: false,
			position: 'fixed',
		}
	}

	async componentDidMount() {
			let btnHeight = document.getElementById("mySinglebtn").offsetTop;
			//console.log(btnHeight)					
			window.onscroll = () => {
			var currentScrollPos = window.pageYOffset;
			//var maxScroll = document.body.scrollHeight - window.innerHeight;
			var maxScroll = document.body.scrollHeight;
			//console.log(maxScroll)
			if (currentScrollPos > btnHeight && currentScrollPos < maxScroll) {
				this.setState({ position: "relative" })
				//console.log(currentScrollPos)
			} 			
			else {
				this.setState({ position: "fixed" })
			}
		}
	}

	openModalPopup = (e) => {
		this.setState({ open: true });
	}

	closeModalPopup = (e) => {
		this.setState({ open: false });
	}

	// on default qty change
	handleQtyChange = event => {
		let { value, min, max } = event.target;
		value = Math.max(Number(min), Math.min(Number(max), Number(value)));

		this.setState({ productquantity: value });
	}

	// On Quantity plus sign click increase qty
	stepQtyIncrement = () => {
		// document.getElementById('qtyUpdate').stepUp();
		// this.setState({ productquantity: this.state.productquantity + 1 });
		if (this.state.productquantity !== this.state.maxQty) {
			this.setState(prevState => ({
				productquantity: parseInt(this.state.productquantity) + 1
			}));
		}
	}

	// On Quantity minus sign click decrease qty
	stepQtyDecrement = () => {
		// document.getElementById('qtyUpdate').stepDown();
		// this.setState({ productquantity: this.state.productquantity - 1 });
		if (this.state.productquantity !== this.props.productdata.minorder) {
			this.setState(prevState => ({
				productquantity: parseInt(this.state.productquantity) - 1
			}));
		}
	}

	showLoginPopup() {
		document.getElementById("LoginPopup").click();
	}

	render() {
		const { photoIndex, isOpen } = this.state;
		const { photoIndexCertificate, isOpenCertificate } = this.state;

		return (
			<div className="col-lg-7 col-md-6">
				<div className="row">
					<div className="col-sm-12 col-lg-6">
						<div className="singletitlesection">
							<h1 className="product-title">{this.props.productdata.name}</h1>

<div className="singleprice show-in-mobile">
	{
		(this.props.productdata.regularPrice !== this.props.productdata.salePrice && this.props.productdata.salePrice !== '0.00') ?
			<div>
				<span className="price">
					{utils.Language('lang_currency', this.props.language)} {new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format(this.props.productdata.salePrice)}
				</span>
				<span className="regularprice">{utils.Language('lang_currency', this.props.language)} {new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format(this.props.productdata.regularPrice)}</span>				
				<div className="saleprice">					
					{
						(this.props.productdata.salePriceValidTill) ?
							<div className="singleBrands validtilldate">
								<span className="smallText">({utils.Language('lang_priceValidTill', this.props.language)} {this.props.productdata.salePriceValidTill})</span>
							</div>
							: null
					}
					{
						( this.props.productdata.updated_days ) ?
							<span className="updatedaysText">{ this.props.productdata.updated_days }</span>
						: null
					}
					<span className="smallText">{utils.Language('lang_taxText', this.props.language)}</span>
				</div>
			</div>
			:
			<div>
				<div className="saleprice">
					<span className="price">
					{utils.Language('lang_currency', this.props.language)} {new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format(this.props.productdata.regularPrice)}
					</span>
					{
						( this.props.productdata.updated_days ) ?
							<span className="updatedaysText">{ this.props.productdata.updated_days }</span>
						: null
					}
					<span className="smallText">{utils.Language('lang_taxText', this.props.language)}</span>
				</div>
			</div>
	}
	{/* {this.props.productdata.units} */}
	Unit
</div>

							{
								(this.props.productdata.is_verified === '1' || this.props.productdata.is_verified === 1) ?
									<div className="at-right-side verified small-verified"> 
									<div className="verified-div">
										<img src={require('../../assets/images/certifications.png')} alt="reward" className="verified-logo" /> {utils.Language('lang_verified', this.props.language)}
									</div>
									</div>
									: null
							}
							<div className="singleRating"> 
								<div className="singleRating-wrapper">
									{							
										( this.props.rating ) ?
										<>
											<StarRatings
											rating={parseFloat(this.props.rating)}
											starRatedColor="#ff6f00"
											starHoverColor="#ff6f00"
											numberOfStars={5}
											name='rating'
											starDimension="20px"
											starSpacing="1px"
											/>
											<span style={{paddingRight: '10px'}}>|</span>
										</>
										: null
									}
									{/* commented removed google api */}
									{/* {
										(this.props.productdata.distance) ?
											<span><div className="proDistance"><a href={
												(this.props.productdata.soldby.latitude && this.props.productdata.soldby.longitude) ?
													'https://maps.google.com/?q=' + this.props.productdata.soldby.name + '&ll=' + this.props.productdata.soldby.latitude + ', ' + this.props.productdata.soldby.longitude + '&z=17'
													:
													'https://maps.google.com/?q=' + this.props.productdata.soldby.name + '&daddr=' + this.props.productdata.soldby.addressList + '&z=17'
											} target="_blank" rel="noopener noreferrer"><span className="icon icon-map-marker-alt"></span> {Number.parseFloat(this.props.productdata.distance).toFixed(2)} {utils.Language('lang_' + this.props.productdata.distanceType, this.props.language)}</a></div></span>
											: null
									} */}
								</div>

								{
									('seller' !== this.props.userType) ?
										(true === this.props.wishlistStatus) ?
											<div className="wishlist wishlist-added" title="Wishlist" onClick={() => { this.props.removeFromWishlist(this.props.productdata.itemId, 'singleproduct') }}><span className="icon-heart icon-heart-red icon-fav"></span></div>
											:
											<div className="wishlist" title="Wishlist" onClick={() => { this.props.addToWishlist(this.props.productdata.itemId, 'singleproduct') }}><span className="icon-heart icon-fav"></span></div>
									
										: null									
								}
							</div>

							<div className="brandWrapper">
								{
									(this.props.productdata.brand) ?
										<div className="singleBrands">
											<span>{utils.Language('lang_brand', this.props.language)}:</span> <a href={ '/search?keyword=' + this.props.productdata.brand } className="link">{this.props.productdata.brand}</a>
										</div>
										: null
								}

								{
									(this.props.productdata.update_date) ?
										<div className="singleBrands update_date">
											<span>{utils.Language('lang_lastUpdatedOn', this.props.language)}:</span>{this.props.productdata.update_date}
										</div>
										: null
								}

								<div className="socialShareWrap">
									<span className="social-text">
										{utils.Language('lang_socialShare', this.props.language)}
									</span>
									<EmailShareButton url={this.props.shareUrl}>
										<EmailIcon size={20} round={false} />
									</EmailShareButton>
									<FacebookShareButton url={this.props.shareUrl}>
										<FacebookIcon size={20} round={false} />
									</FacebookShareButton>
									<TwitterShareButton url={this.props.shareUrl}>
										<TwitterIcon size={20} round={false} />
									</TwitterShareButton>
									{/* <PinterestShareButton url={this.props.shareUrl}>
										<PinterestIcon size={20} round={false} />
									</PinterestShareButton> */}
								</div>

								{
									(this.props.productdata.categoryinfo) ?
										<div className="categoryinfo">
											<button className="button" onClick={this.openModalPopup}><span className="icon icon-info-circle"></span></button>
											<Popup
												open={this.state.open}
												closeOnDocumentClick
												onClose={this.closeModalPopup}
											>
												<div className="PopupBox">
													<div className="modalHeader">
														<button className="close" onClick={this.closeModalPopup}>&times;</button>
														<p>{utils.Language('lang_techInfo', this.props.language)}</p>
													</div>
													<div className="modalBody">
														{renderHTML(this.props.productdata.categoryinfo)}
													</div>
												</div>
											</Popup>
										</div>
										: null
								}
							</div>
						</div>
						<div className="detailsSec">
							<strong>{utils.Language('lang_stockStatus', this.props.language)}: </strong> <span className="instock">{(this.props.productdata.itemInStock) ? utils.Language('lang_inStock', this.props.language) : utils.Language('lang_outStock', this.props.language)}</span>
							<div className="singleprice show-in-desktop">
								{
									(this.props.productdata.regularPrice !== this.props.productdata.salePrice && this.props.productdata.salePrice !== '0.00') ?
										<div>
											<div className="regularprice">{utils.Language('lang_currency', this.props.language)} {new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format(this.props.productdata.regularPrice)}</div>
											<div className="saleprice">
												{utils.Language('lang_currency', this.props.language)} {new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format(this.props.productdata.salePrice)}
												{
													(this.props.productdata.salePriceValidTill) ?
														<div className="singleBrands validtilldate">
															<span className="smallText">({utils.Language('lang_priceValidTill', this.props.language)} {this.props.productdata.salePriceValidTill})</span>
														</div>
														: null
												}
												{
													( this.props.productdata.updated_days ) ?
														<span className="updatedaysText">{ this.props.productdata.updated_days }</span>
													: null
												}
												<span className="smallText">{utils.Language('lang_taxText', this.props.language)}</span>
											</div>
										</div>
										:
										<div>
											<div className="saleprice">
												{utils.Language('lang_currency', this.props.language)} {new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format(this.props.productdata.regularPrice)}
												{
													( this.props.productdata.updated_days ) ?
														<span className="updatedaysText">{ this.props.productdata.updated_days }</span>
													: null
												}
												<span className="smallText">{utils.Language('lang_taxText', this.props.language)}</span>
											</div>
										</div>
								}
								{/* {this.props.productdata.units} */}
								Unit
							</div>
							
							{
								('seller' !== this.props.userType) ?
									<div className="singlebtnSection">
										<div className="singlebtnSectionLeft">
											{utils.Language('lang_qty', this.props.language)} :
											<button type="button" className="button-number button-minus" data-field="quantity" onClick={this.stepQtyDecrement}><span></span></button>
											<FormControl
												id="qtyUpdate"
												type="number"
												value={this.state.productquantity}
												className="inputBox"
												min={this.props.productdata.minorder}
												max={this.state.maxQty}
												onChange={this.handleQtyChange}
											/>
											<button type="button" className="button-number button-plus" data-field="quantity" onClick={this.stepQtyIncrement}><span></span></button>
										</div>
										<div id="mySinglebtn">	
										<BrowserView>
											<div className="singlebtnSectionRight show-in-desktop">	
												<button type="button" className="btn btn-outline-danger" onClick={(id, qty) => { this.props.addItemToCart(this.props.productdata.itemId, this.state.productquantity, 'cart/') }}>{utils.Language('lang_buyNow', this.props.language)}</button>
												<button type="button" className="btn btn-danger" onClick={(id, qty) => { this.props.addItemToCart(this.props.productdata.itemId, this.state.productquantity, '') }}><span className="icon-cart-add"></span>{utils.Language('lang_addToCart', this.props.language)}</button>
											</div>
										</BrowserView>
										<MobileView>											
											<div className="singlebtnSectionRight show-in-mobile" style={{ position: `${this.state.position}`}}>	
											<button type="button" className="btn btn-outline-danger" onClick={(id, qty) => { this.props.addItemToCart(this.props.productdata.itemId, this.state.productquantity, 'cart/') }}>{utils.Language('lang_buyNow', this.props.language)}</button>
											<button type="button" className="btn btn-danger" onClick={(id, qty) => { this.props.addItemToCart(this.props.productdata.itemId, this.state.productquantity, '') }}><span className="icon-cart-add"></span>{utils.Language('lang_addToCart', this.props.language)}</button>
											</div>											
										</MobileView>
										</div>										
										</div>
									: null
							}
							
						</div>
					</div>

					<div className="col-sm-12 col-lg-6">
						<div className="deliveryoptions">
							<div className="sold-by-wrapper">
								<div className="soldby">
									<span className="soldby-title">{utils.Language('lang_soldby', this.props.language)}:</span>
									{(this.props.productdata.soldby.verified) ? <div className="at-right-side verified small-verified"><div className="verified-div"><img src={require('../../assets/images/certifications.png')} alt="reward" className="verified-logo" /> {utils.Language('lang_verified_supplier', this.props.language)}</div></div> : null}
									{
										( this.props.productdata.soldby.imageUrl && this.props.productdata.soldby.subscribed ) ?
											<Link to={"/suppliers/" + encodeURIComponent(this.props.productdata.soldby.slug)} className="link">
												<div className="soldby-logo">
													{/*<img src={this.props.productdata.soldby.imageUrl ? this.props.productdata.soldby.imageUrl : noImage} alt="logo" />*/}
													{
														( this.props.productdata.soldby.imageUrl ) ?
															<img src={this.props.productdata.soldby.imageUrl} alt="logo" />
														: null
													}
												</div>
											</Link>
											:
											<div className="soldby-logo">
												{/*<img src={this.props.productdata.soldby.imageUrl ? this.props.productdata.soldby.imageUrl : noImage} alt="logo" />*/}
												{
													( this.props.productdata.soldby.imageUrl ) ?
														<img src={this.props.productdata.soldby.imageUrl} alt="logo" />
													: null
												}
											</div>
									}

									{
										(this.props.productdata.soldby.name) ?
											<Link to={"/suppliers/" + encodeURIComponent(this.props.productdata.soldby.slug) + "/products"} className="link"><div className="soldby-name">{this.props.productdata.soldby.name}</div></Link>
											:
											<div className="soldby-name link">{this.props.productdata.soldby.name}</div>
									}

 
									<div className="soldby-vendor">
										{(this.props.productdata.soldby.contact_phone) ? <div className="soldby-phn"><span className="icon-mobile-alt"></span><span className="click-to-call"><a href={"callto:" + this.props.productdata.soldby.contact_phone}> {this.props.productdata.soldby.contact_phone} </a></span></div> : null}
										{(this.props.productdata.soldby.vendor_phone) ? <div className="soldby-phn"><span className="icon-mobile-alt"></span><span className="click-to-call"><a href={"callto:" + this.props.productdata.soldby.vendor_phone}> {this.props.productdata.soldby.vendor_phone} </a></span></div> : null}
										{(this.props.productdata.soldby.vendor_phone_alt) ? <div className="soldby-phn"><span className="icon-mobile-alt"></span> <span className="click-to-call"><a href={"callto:" + this.props.productdata.soldby.vendor_phone_alt}>{this.props.productdata.soldby.vendor_phone_alt}</a></span></div> : null}
									</div>
 

									<div className="soldby-address">
										<span className="core-address">{this.props.productdata.soldby.addressList}</span>
										<div className="proDistance"><a href={
											(this.props.productdata.soldby.latitude && this.props.productdata.soldby.longitude) ?
												'https://maps.google.com/?q=' + this.props.productdata.soldby.name + '&ll=' + this.props.productdata.soldby.latitude + ', ' + this.props.productdata.soldby.longitude + '&z=17'
												:
												'https://maps.google.com/?q=' + this.props.productdata.soldby.name + '&daddr=' + this.props.productdata.soldby.addressList + '&z=17'
										} target="_blank" rel="noopener noreferrer"><span className="icon-map-marker-alt"></span>{utils.Language('lang_viewOnMap', this.props.language)}</a>
									</div>
									</div>

									{(true === this.state.isUserLogin && true === this.props.productdata.soldby.subscribed && this.props.productdata.soldby.contact_fullname) || (true === this.state.isUserLogin && true === this.props.productdata.soldby.subscribed && this.props.productdata.soldby.contact_phone) ?
										<div className="soldby-contact">
											{(this.props.productdata.soldby.contact_fullname) ? <div className="soldby-contactname"><span className="icon-user"></span> {this.props.productdata.soldby.contact_fullname}</div> : null}

											{(this.props.productdata.soldby.contact_phone) ? <div className="soldby-phn"><span className="icon-mobile-alt"></span> {this.props.productdata.soldby.contact_phone}</div> : null}
										</div>
										:
										null
									}

									

{
										(this.props.productdata.soldby.vendor_file && this.props.productdata.soldby.vendor_file.length > 0) ?
											<div className="dealership-certificate">
												<button className="btn btn-primary" type="button" onClick={() => this.setState({ isOpenCertificate: true })}>{utils.Language('lang_DealershipCertificate', this.props.language)}</button>
												{isOpenCertificate && (
													<Lightbox
														mainSrc={this.props.productdata.soldby.vendor_file[photoIndexCertificate]}
														nextSrc={this.props.productdata.soldby.vendor_file[(photoIndexCertificate + 1) % this.props.productdata.soldby.vendor_file.length]}
														prevSrc={this.props.productdata.soldby.vendor_file[(photoIndexCertificate + this.props.productdata.soldby.vendor_file.length - 1) % this.props.productdata.soldby.vendor_file.length]}
														onCloseRequest={() => this.setState({ isOpenCertificate: false })}
														onMovePrevRequest={() =>
															this.setState({
																photoIndexCertificate: (photoIndexCertificate + this.props.productdata.soldby.vendor_file.length - 1) % this.props.productdata.soldby.vendor_file.length
															})
														}
														onMoveNextRequest={() =>
															this.setState({
																photoIndexCertificate: (photoIndexCertificate + 1) % this.props.productdata.soldby.vendor_file.length
															})
														}
													/>
												)}
											</div>
											:
											null
									}
									
								{
									( this.props.productdata.soldby.distance || this.props.productdata.soldby.rating) ?
									<div className="soldby-distance">
										{/* commented removed google api */}
										{/* {
											(this.props.productdata.soldby.distance) ?
												<div className="proDistance">
													<a href={
														(this.props.productdata.soldby.latitude && this.props.productdata.soldby.longitude) ?
															'https://maps.google.com/?q=' + this.props.productdata.soldby.name + '&ll=' + this.props.productdata.soldby.latitude + ', ' + this.props.productdata.soldby.longitude + '&z=17'
															:
															'https://maps.google.com/?q=' + this.props.productdata.soldby.name + '&daddr=' + this.props.productdata.soldby.addressList + '&z=17'
													} target="_blank" rel="noopener noreferrer">
														<span className="icon icon-map-marker-alt"></span> {Number.parseFloat(this.props.productdata.soldby.distance).toFixed(2)} {utils.Language('lang_' + this.props.productdata.soldby.distanceType, this.props.language)}
													</a>
												</div>
												:
												null
										} */}
										<div className="sellerRating">
											<div className="start-ratings">
											{
												( this.props.productdata.soldby.rating ) ?
													<StarRatings
													rating={parseFloat(this.props.productdata.soldby.rating ? this.props.productdata.soldby.rating : 0)}
													starRatedColor="#ff6f00"
													starHoverColor="#ff6f00"
													numberOfStars={5}
													name='rating'
													starDimension="15px"
													starSpacing="0"
													/>
												: null
											}
											</div>
										</div>
										
									</div>
									:null
								}								

								{
									( this.props.productdata.soldby.facebook || this.props.productdata.soldby.instagram || this.props.productdata.soldby.twitter ) ?	
									<div className="socialLink">
									{
										( this.props.productdata.soldby.facebook ) ?
										<a className="socialLink-icon socialLink-icon-facebook" href={this.props.productdata.soldby.facebook} target="_blank" rel="noopener noreferrer"><span className="icon-facebook-f"></span></a>
										: null
									}
									{
										( this.props.productdata.soldby.instagram ) ?
										<a className="socialLink-icon socialLink-icon-instagram" href={this.props.productdata.soldby.instagram} target="_blank" rel="noopener noreferrer"><span className="icon-instagram"></span></a>
										: null
									}
									{
										( this.props.productdata.soldby.twitter ) ?
										<a className="socialLink-icon socialLink-icon-twitter" href={this.props.productdata.soldby.twitter} target="_blank" rel="noopener noreferrer"><span className="icon-twitter"></span></a>
										: null
									}
									</div>
									: null
								}


									{
										/**
										(true === this.props.productdata.soldby.subscribed && false === this.state.isUserLogin) ?
											<div className="suppliers-contact-person call-suppliers-btn single-btn"><button className="click-to-call call-mobile cta-btn" title="Send mail" onClick={this.showLoginPopup}><span className="icon"><span className="icon icon-mobile-alt"></span></span> {utils.Language('lang_callNowSupplier', this.props.language)}</button></div>
											: null
										**/
									}
									{
										/**
										(false === this.props.productdata.soldby.subscribed && false === this.state.isUserLogin) ?
											<div className="suppliers-contact-person call-suppliers-btn"><button className="click-to-call email-mobile cta-btn" title="Call Gharbanau" onClick={this.showLoginPopup}><span className="icon"><span className="icon icon-mailing"></span></span> {utils.Language('lang_sendMail', this.props.language)}</button><button className="click-to-call call-mobile cta-btn" onClick={this.showLoginPopup}><span className="icon"><span className="icon icon-mobile-alt"></span></span> {utils.Language('lang_callNow', this.props.language)}</button></div>
											: null
											**/
									}

									{
										(this.props.attachment.length > 0) ?
											<div className="soldby-bot">
												<div className="attachment">
													<div className="soldby-title">{utils.Language('lang_Attachment', this.props.language)}:</div>
													{
														(this.props.attachment) ?
															<div className="attachment-image">
																{
																	this.props.attachment.map((item, index) => {
																		return (
																			<figure key={index}>
																				<img src={item} onClick={() => this.setState({ isOpen: true, photoIndex: index })} alt="attachment" />
																			</figure>
																		)
																	})
																}
															</div>
															: null
													}
													{isOpen && (
														<Lightbox
															mainSrc={this.props.attachment[photoIndex]}
															nextSrc={this.props.attachment[(photoIndex + 1) % this.props.attachment.length]}
															prevSrc={this.props.attachment[(photoIndex + this.props.attachment.length - 1) % this.props.attachment.length]}
															onCloseRequest={() => this.setState({ isOpen: false })}
															onMovePrevRequest={() =>
																this.setState({
																	photoIndex: (photoIndex + this.props.attachment.length - 1) % this.props.attachment.length
																})
															}
															onMoveNextRequest={() =>
																this.setState({
																	photoIndex: (photoIndex + 1) % this.props.attachment.length
																})
															}
														/>
													)}
												</div>
											</div>
											: null
									}
								</div>
							</div>

							<Tabs defaultActiveKey="tab1" id="uncontrolled-tab-example">
								<Tab eventKey="tab1" title={utils.Language('lang_tabDelivery', this.props.language)}>
									<div className="deliveryoptions-wrapper">
										<div className="delivery-list-div">
											<div className="soldby-title">{utils.Language('lang_Delivery', this.props.language)}</div>
											<div className="delivery-list">
												<div className="delivery-list1">
													<span className="icon icon-truck"></span>
													<div className="deliver-name">{utils.Language('lang_homeDelivery', this.props.language)} <span className="smallText">{this.props.deliveryOptions.deliveryTime ? this.props.deliveryOptions.deliveryTime + utils.Language('lang_Days', this.props.language) : '-'}</span>
														{(this.props.productdata.shippingType) ? <div>{utils.Language('lang_shippingText', this.props.language)}: {this.props.productdata.shippingType}</div> : null}
														{(this.props.productdata.shippingInfo) ? <div>{this.props.productdata.shippingInfo}</div> : null}
													</div>
												</div>
											</div>
										</div>
									</div>
								</Tab>
								<Tab eventKey="tab2" title={utils.Language('lang_tabWarranty', this.props.language)}>
									<div className="deliveryoptions-wrapper">
										<div className="warranty-div">
											<div className="soldby-title">{utils.Language('lang_Warranty', this.props.language)}</div>
											<div className="warranty-list">
												<div className="warranty-list1">
													<span className="icon-tick"></span>
													<div className="warranty-text">{utils.Language('lang_WarrantyPeriod', this.props.language)}
														<span className="smallText">{this.props.deliveryOptions.warrantyPeriod ? this.props.deliveryOptions.warrantyPeriod : '-'}</span>
													</div>
												</div>
												<div className="warranty-list3">
													<span className="icon-tick"></span>
													<div className="warranty-text">{utils.Language('lang_WarrantyPolicy', this.props.language)}
														<span className="smallText">{this.props.deliveryOptions.warrantyPolicy ? this.props.deliveryOptions.warrantyPolicy : '-'}</span>
													</div>
													<div className="warranty-text"></div>
												</div>
											</div>
										</div>
									</div>
								</Tab>
								<Tab eventKey="tab3" title={utils.Language('lang_tabExchange', this.props.language)}>
									<div className="deliveryoptions-wrapper">
										<div className="warranty-div">
											<div className="soldby-title">{utils.Language('lang_Exchange', this.props.language)}</div>
											<div className="warranty-list">
												<div className="warranty-list1">
													<span className="icon-tick"></span>
													<div className="warranty-text">{utils.Language('lang_ExchangePeriod', this.props.language)}
														<span className="smallText">{this.props.deliveryOptions.exchangePeriod ? this.props.deliveryOptions.exchangePeriod : '-'}
														</span>
													</div>
												</div>
												<div className="warranty-list3">
													<span className="icon-tick"></span>
													<div className="warranty-text">{utils.Language('lang_ExchangePolicy', this.props.language)}
														<span className="smallText">{this.props.deliveryOptions.exchangePolicy ? this.props.deliveryOptions.exchangePolicy : '-'}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</Tab>
							</Tabs>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ProductMeta;