// Single News js
import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
// import { Link } from 'react-router-dom';
import renderHTML from 'react-render-html';
import webservice from '../services/webservice';
import { Api } from '../common/variables';
import Loader from '../common/Loader';
import RelatedNews from '../components/singlenews/RelatedNews';
import { FacebookShareButton, TwitterShareButton, EmailShareButton, TwitterIcon, FacebookIcon, EmailIcon, } from "react-share";

// Language
import utils from '../common/utils';

// Redux set data
import { connect } from "react-redux";

class SingleNews extends Component {
	constructor() {
		super()
		this.state = {
			limit : 16,
			title : null,
			isLoading: true,
			created_by : null,
			relatednews : [],
			description : null,
			created_date : null,
			featured_image : null,
			newsAndBlogApi: false,
			last_updated_by : null,
			url : ''
		}
	}

	async componentDidMount() {
		var str = Api.backEndapiUrl;
		var strrepl = str.replace(/\/+$/, '');
		this.setState({ url : strrepl + this.props.match.url });

		window.scrollTo({top: 0});
		let params = {
			lang  : this.props.language,
			NewsSlug : this.props.match.params.slug,
		}

		let getNewsDetail = await webservice.httpPost( Api.method.getNewsDetail, params );
		if ( getNewsDetail.status === 'ok' ) {
			this.setState({
				newsAndBlogApi : true,
				title : getNewsDetail.data.title,
				created_by : getNewsDetail.data.created_by,
				description : getNewsDetail.data.description,
				created_date : getNewsDetail.data.created_date,
				featured_image : getNewsDetail.data.Featured_Image,
				last_updated_by : getNewsDetail.data.last_updated_by,
				created_user_img : getNewsDetail.data.created_user_img,
			})

			this.getRelatedProducts( getNewsDetail.data.id );
		}
	}

	// get product related products
	getRelatedProducts = async ( id ) => {
		let params = {
			lang  : this.props.language,
			limit : this.state.limit,
			exclude_id : id,
		}

		let getAllNews = await webservice.httpPost( Api.method.getAllNews, params );
		if ( 'ok' === getAllNews.status ) {
			this.setState({
				relatednews: getAllNews.data
			})
		}

		this.setState({ isLoading: false })
	}

	// Refresh data in same page after related product click
	refreshData = () => {
		setTimeout(
			function() {
				this.setState({
					relatednews: []
				});
				this.componentDidMount();
				// window.scrollTo({top: 0, behavior: 'smooth'});
				this.setState({ isLoading: true });
			}
			.bind( this ),
			100
		);
	}

	render() {
		return (
			<div className="blogWrapper blogDetail">
				{/*For Meta tags Dynamic content*/}
				<MetaTags>
					<title>{ this.state.title ? this.state.title : 'Gharbanau - A marketplace for construction materials for Nepal' }</title>
					<meta name="keywords" content={ 'blog, gharbanau' } />
					<meta
					name="description"
					content={ 'Gharbanau - A marketplace for construction materials for Nepal' }
					/>
					<meta property="og:url" content={ window.location.href } />
					<meta property="og:type" content="News Detail" />
					<meta property="og:description" content={ 'Gharbanau - A marketplace for construction materials for Nepal' } />
					<meta property="og:title" content={ this.state.title } />
					{
						(this.state.featured_image) ?
						<meta property="og:image" content={ this.state.featured_image } />
						: null
					}
				</MetaTags>

				{
					( this.state.newsAndBlogApi && ! this.state.isLoading ) ?
					<div className="innerContent">
						<div className="wrapper wrapper_1200">
							<div className="blogDetails">
								{
									( this.state.featured_image ) ?
									<div className="news-images">
										<img src={ this.state.featured_image } alt="images" />
									</div>
									: null
								}
								<div className="blogDetail--content">
									<h1>{ this.state.title }</h1>
									<div className="user-date-wrap">
										{
										// <div className="user-profile-wrap">
										// 	{
										// 		( this.state.created_user_img ) ?
										// 		<span className="icon-user-wrap"><img src={ this.state.created_user_img } alt="userimages" /></span>
										// 		:
										// 		<span className="icon-user-wrap"><i className="icon-user"></i></span>
										// 	}
										// 	{ this.state.created_by }
										// </div>
										}
										<span className="date-publish"><span className="icon-date-wrap"><i className="icon-calendar-alt"></i></span>{ this.state.created_date }</span>
									</div>
									{
										this.state.description ?
										<div className="description">{ renderHTML( this.state.description ) }</div>
										: null
									}
								</div>
								<div className="socialShareWrap">
									<span className="social-text">
									{utils.Language('lang_socialShare', this.props.language)}
									</span>
									<EmailShareButton url={ this.state.url }>
										<EmailIcon size={20} round={false} />
									</EmailShareButton>
									<FacebookShareButton url={ this.state.url }>
										<FacebookIcon size={20} round={false} />
									</FacebookShareButton>
									<TwitterShareButton url={ this.state.url }>
										<TwitterIcon size={20} round={false} />
									</TwitterShareButton>
									{/* <PinterestShareButton url={ this.state.url }>
										<PinterestIcon size={20} round={false} />
									</PinterestShareButton> */}
								</div>
							</div>
						</div>
					</div>
					: <Loader />
				}

				<div className="relatedDiv">
				{
					( this.state.relatednews != null && ! this.state.isLoading && this.state.relatednews.length > 0 ) ? 
						<RelatedNews
						data={ this.state.relatednews }
						refreshData={ this.refreshData }
						language={ this.props.language }
						/>
					: null
				}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

export default connect( mapStateToProps, null )(SingleNews);