// Banner js
import React, { Component } from 'react';
import webservice from '../../../services/webservice';
import { Api } from '../../../common/variables';

import renderHTML from 'react-render-html';

// import AdsSection from '../../../components/content/adsection/adsection';
//import AdSense from 'react-adsense';

import Swiper from 'react-id-swiper';
import { isBrowser } from 'react-device-detect';

class Banner extends Component {
	constructor() {
		super()
		this.state = {
			bannerApi: false,
			bannerlink: null,
		}
	}

	async componentDidMount() {	
		let params = {
			lang: "en",
			banner_type: "banner-web",
		}

		if (767 > window.innerWidth) {
			params.banner_type = 'banner-app';
		}

		let banner = await webservice.httpPost(Api.method.getHomeSlider, params);
		if (banner.status === 'ok') {
			this.setState({
				arrBannerHome: banner.data.arrBannerHome,
				bannerApi: true,
			})
		}
	}

	render() {
		if (!this.state.bannerApi) {
			return null;
		}

		const params = {
			// navigation: {
			// 	nextEl: '.swiper-button-next.swiper-button-white', // Add your class name for next button
			// 	prevEl: '.swiper-button-prev.swiper-button-white' // Add your class name for prev button
			// },
			loop: ( this.state.arrBannerHome.length > 1 ) ? true : false,
			shouldSwiperUpdate: true,
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
				clickable: true
			},
			autoplay: {
				delay: 8000,
			},
			spaceBetween: 0,
 			// lazy: true,
 			// preloadImages: false,
			// watchOverflow: true,
			// loop: true,
		}

		return (
			<div className="banner-homepage">
				{
					(this.state.bannerApi && this.state.arrBannerHome.length > 1) ?
					<Swiper
						{...params}
						wrapperClass={'SwiperBanner'}
						spaceBetween={0}
						slidesPerView={1}
						onSlideChange={() => console.log('slide change')}
						onSwiper={(swiper) => console.log(swiper)}
					>
 
					{
					
						this.state.arrBannerHome.map((data, index) => {
							return (
								<div
									className="banners"
									style={{ backgroundImage: `url(${data.BannerImage})` }}
									key={index}
								>
									{
										(data.BannerLink) ?
											<a href={data.BannerLink} rel="noopener noreferrer">
												<img
													alt='banner'
													src={data.BannerImage}
												/>
											</a>
											:
											<img
												alt='banner'
												src={data.BannerImage}
											/>
									}
								</div>
							);
						})
						
					} 
					</Swiper>
					: null
				}

				{
					(this.state.bannerApi && this.state.arrBannerHome.length === 1) ?					
						this.state.arrBannerHome.map((data, index) => {
							return (
								<div
									className="banners"
									style={{ backgroundImage: `url(${data.BannerImage})` }}
									key={index}
								>
									{
										(data.BannerLink) ?
											<a href={data.BannerLink} rel="noopener noreferrer">
												<img
													alt='banner'
													src={data.BannerImage}
												/>
											</a>
											:
											<img
												alt='banner'
												src={data.BannerImage}
											/>
									}
								</div>
							);
						})
					: null
				}

				{
				this.props.data === 404 ? null :
				<div className="googleadSection container">
					{
						(isBrowser === true)?  
						 renderHTML( this.props.googleAds ) : renderHTML( this.props.googleAdsMobile ) 
						
					}					 
				</div>
				}
			</div>
		);
	}
}

export default Banner;