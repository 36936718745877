import React, { Component } from 'react';

// Language
import utils from '../../../common/utils';

// Redux set data
import { connect } from "react-redux";

class ThreeRowContent extends Component {
	render() {
		return (
			<div className="wrapper-three-row-footer">
				<div className="content-with-icon">
					<div className="three-col-footer-icon">
						<span className="icon-bus icon"></span>
					</div>
					<div className="three-col-footer-content">
						<h6>{ utils.Language( 'lang_cashOnDelivery', this.props.language ) }</h6>
						<p>{ utils.Language( 'lang_cashOnDeliveryTextFooter', this.props.language ) }</p>
					</div>
				</div>

				<div className="content-with-icon">   
					<div className="three-col-footer-icon">
						<span className="icon-user-circle icon"></span>
					</div>
					<div className="three-col-footer-content">
						<h6>{ utils.Language( 'lang_customerSupport', this.props.language ) }</h6>
						<p>{ utils.Language( 'lang_customerSupportText', this.props.language ) }</p>
					</div>
				</div>

				<div className="content-with-icon">
					<div className="three-col-footer-icon">
						<span className="icon-truck icon"></span>
					</div>
					<div className="three-col-footer-content">
						<h6>{ utils.Language( 'lang_onTimeDelivery', this.props.language ) }</h6>
						<p>{ utils.Language( 'lang_onTimeDeliveryText', this.props.language ) }</p>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

export default connect( mapStateToProps, null )(ThreeRowContent);