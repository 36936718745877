// Ads
import React, {Component} from 'react';
import webservice from '../../../services/webservice';
import { Api } from '../../../common/variables';
// import ads from '../../../assets/images/eattendace-banner-for-gharbazar980x92.jpg';
// Lazy load
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import InfiniteCarousel from 'react-leaf-carousel';

class AdsSection extends Component {
	constructor() {
		super()
		this.state = {
			adsectionApi: false,
			arrAds: [],
		}
	}

	async componentDidMount() {
		let params = {
			lang    : "en",
			banner_type : "ads-web",
		}

		if (767 > window.innerWidth) {
			params.banner_type = 'ads-app';
		}

		let banner = await webservice.httpPost( Api.method.getHomeSlider, params );
		if ( banner.status === 'ok' ) {
			if ( banner.data.arrBannerHome.length > 0 ) {
				this.setState({
					arrAds: banner.data.arrBannerHome,
					adsectionApi: true,
				})
			}
		}
	}

	render() {
		if ( ! this.state.adsectionApi ) {
			return null;
		}

		return (
			<section className="adSection">
				<div className="home-ads wrapper wrapper_1200">
					{<InfiniteCarousel
						dots={false}
						arrows={false}
						sideSize={.0}
						lazyLoad={true}
						slidesToShow={1}
						autoCycle={true}
						showSides={true}
						responsive={true}
						slidesSpacing={0}
						slidesToScroll={1}
						scrollOnDevice={true}
						cycleInterval={6000}
					>
					{
						( this.state.adsectionApi && this.state.arrAds.length > 0 ) ?
							this.state.arrAds.map( ( data, index ) => {
								return(
									<div className="banners" key={ index }>
										{
											( data.BannerLink ) ? 
											<a href={data.BannerLink} target="_blank" rel="noopener noreferrer">
												<img
												alt='banner'
												src={data.BannerImage}
												/>
											</a>
											:
											<img
											alt='banner'
											src={data.BannerImage}
											/>
										}
									</div>
								);
							})
						: null
					}
					</InfiniteCarousel>}
				</div>
			</section>
		);
	}
}

export default AdsSection;