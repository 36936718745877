// About Us js
import React, {Component} from 'react';
import webservice from '../services/webservice';
import { Api } from '../common/variables';
import renderHTML from 'react-render-html';
import MetaTags from 'react-meta-tags';
import Loader from '../common/Loader';
import Swiper from 'react-id-swiper';

// Redux set data
import { connect } from "react-redux";

class AboutUs extends Component {
	constructor() {
		super()
		this.state = {
			pageDetailApi : false,
			url : '',
			bannerImage : '' //require('../assets/images/gharbanau_banner.jpg')
		}
	}

	async componentDidMount() {
		this.setState({
			bannerImage: this.props.vendorInfos.data.BannerArrayImage
		});

		let params = {
			lang     : this.props.language,
			vendorID : this.props.vendorInfos.data.id
		}

		let getPageDetail = await webservice.httpPost( Api.method.getSupplierPageLanding, params );
		if ( getPageDetail.status === 'ok' ) {
			this.setState({
				pageDetailApi    : true,
				pageTitle        : getPageDetail.data.title,
				pageContent      : getPageDetail.data.content,
				metaTitle        : getPageDetail.data.meta_title,
				metaKeywords     : getPageDetail.data.meta_keywords,
				metaDescriptions : getPageDetail.data.meta_descriptions
			})
		} else {
			document.location.href = Api.backEndapiUrl;
		}
	}

	render() {
		const params = {
			navigation: {
				nextEl: '.swiper-button-next.swiper-button-white', // Add your class name for next button
				prevEl: '.swiper-button-prev.swiper-button-white' // Add your class name for prev button
			},
			autoplay: {
				delay: 8000,
			},
			spaceBetween: 0,
			loop: this.state.bannerImage.length > 1 ? true : false,
			lazy: true,
			preloadImages: false,
			watchOverflow: true,
		}

		return (
			<div className="aboutPage">
				{/*For Meta tags Dynamic content*/}
				<MetaTags>
					<title>{ this.state.metaTitle ? this.state.metaTitle : 'A marketplace for construction materials for Nepal' }</title>
					<meta name="keywords" content={ this.state.metaKeywords ? this.state.metaKeywords : 'about, gharbanau' } />
					<meta
					name="description"
					content={ this.state.metaDescriptions ? this.state.metaDescriptions : 'A marketplace for construction materials for Nepal' }
					/>
					<meta property="og:url" content={ window.location.href } />
					<meta property="og:type" content="Page" />
					<meta property="og:description" content={ this.state.metaDescriptions ? this.state.metaDescriptions : 'A marketplace for construction materials for Nepal' } />
					<meta property="og:title" content={ this.state.pageTitle ? this.state.pageTitle : 'Page' } />
				</MetaTags>
				{
					( this.state.pageDetailApi ) ?
					<div className="aboutwrap">
						{
							(this.state.bannerImage.length > 0) ?
								<Swiper
									{...params}
									wrapperClass={'SwiperBanner'}
									spaceBetween={0}
									slidesPerView={1}
									onSlideChange={() => console.log('slide change')}
									onSwiper={(swiper) => console.log(swiper)}
								>
									{
									(this.state.bannerImage.length > 0) ?
										this.state.bannerImage.map((data, index) => {
											return (
											<div
												className="banners swiper-slide swiper-lazy"
												// style={{ backgroundImage: `url(${data.BannerImage})` }}
												data-background={data.BannerImage}
												key={index}
											>
											<div className="swiper-lazy-preloader"></div>
												{
													(data.BannerLink) ?
													<a href={data.BannerLink} rel="noopener noreferrer">
														<img
															alt='banner'
															className="swiper-lazy"
															data-src={data.BannerImage}
														/>
														<div className="swiper-lazy-preloader"></div>
													</a>
													:
													<div>
														<img
															alt='banner'
															className="swiper-lazy"
															data-src={data.BannerImage}
														/>
														<div className="swiper-lazy-preloader"></div>
													</div>
												}
											</div>
											);
										})
									: null
									}
								</Swiper>
								: null
						}

						<div className="innerContent">
							<div className="wrapper wrapper_1200">
								{ ( this.state.pageTitle ) ? <h2 className="innerContent-title">{ this.state.pageTitle } </h2> : null }
								
								{ ( this.state.pageContent ) ? renderHTML( this.state.pageContent ) : null }
							</div>
						</div>
					</div>
					: <Loader />
				}
				
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

export default connect( mapStateToProps, null )(AboutUs);