import React, { useState } from 'react';
import Swiper from 'react-id-swiper';
import { Link } from 'react-router-dom';
// import 'react-id-swiper/lib/styles/scss/swiper.scss';
import noImage from '../../../assets/images/no-image-available.png';
import './_featured-product.scss';
import StarRatings from 'react-star-ratings';

// Language
import utils from '../../../common/utils';

const ManipulatingSwiper = ( data ) => {
	const [swiper, updateSwiper] = useState(null);

	const goNext = () => {
		if (swiper !== null) {
			swiper.slideNext();
		}
	};

	const goPrev = () => {
		if (swiper !== null) {
			swiper.slidePrev();
		}
	};

	const params = {
		slidesPerView : 2,
		loop          : true,
		shouldSwiperUpdate: true,
        breakpoints: {
        	1024: {
                slidesPerView: 6,
        	},
        	992: {
                slidesPerView: 5,
        	},
            767: {
                slidesPerView: 4,
            },
            575: {
                slidesPerView: 3,
            },
        },
	}

	return (
		<div className="por">
			<Swiper className="row" {...params} getSwiper={updateSwiper}>
				{
					data.popularProducts.map( (products, index) => {
						return(
							<div className="mostPopularProducts featuredProducts " key={index}>
								<div className="boxed">
									{
										( 'seller' !== data.userType ) ?
										<div className="wishlist-div">
											{
												( true === products.wishlistStatus ) ?
												<div class="wishlist wishlist-added" title="Wishlist" onClick={ () => { data.removeFromWishlist( products.id ) } }><span className="icon-heart-black icon-heart-red icon-fav"></span></div>
												:
												<div class="wishlist" title="Wishlist" onClick={() => { data.addToWishlist( products.id ) } }><span className="icon-heart-black icon-fav"></span></div>
											}
										</div>
										: null
									}
									<Link to={'/products/' + encodeURIComponent( products.slug )} className="link">
										<div className="imgWrapper">
											<img src={ products.imageUrl ? products.imageUrl : noImage } alt="productsimage" />
										</div>
										<div data-mh className="productDetail">
											<div className="proName">{products.name}</div>
											<div className="priceSec">
												{
													( products.regularPrice !== products.salePrice && products.salePrice !== '0.00'  ) ?
														<div><span className="regularprice">{ utils.Language( 'lang_currency', data.language ) } { new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format( products.regularPrice ) }</span>
														<span className="saleprice">{ utils.Language( 'lang_currency', data.language ) } { new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format( products.salePrice ) }
														{
															( products.salePriceValidTill ) ?
																<div className="singleBrands validtilldate">
																	<span className="smallText">({ utils.Language( 'lang_priceValidTill', data.language ) } { products.salePriceValidTill })</span>
																</div>
															: null
														}
														</span></div>
													:
													<span className="saleprice">{ utils.Language( 'lang_currency', data.language ) } { new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format( products.regularPrice ) }</span>
												}
											</div>
										</div>
									</Link>
									<div className="starrating-div">
									{
										( products.rating ) ?
											<StarRatings
											rating={ parseFloat( products.rating ) }
											starRatedColor="#ff6f00"
											starHoverColor="#ff6f00"
											numberOfStars={5}
											name='rating'
											starDimension="20px"
											starSpacing="0"
											/>
										: null
									}
									</div>
									{
										( 'seller' !== data.userType ) ?
											<div className="productDetail productDetailnew">
												<button type="button" className="btn btn-danger btn-add-cart" onClick={() => { data.addItemToCart( products.id, parseInt( products.minimum_quantity ) ) }}><span className="icon-cart-add"></span> { utils.Language( 'lang_addToCart', data.language ) }</button>
											</div>
										: null
									}
								</div>
							</div>
						)
					})
				}
			</Swiper>
			<button onClick={goPrev} className="prev"><span className="icon-back"></span></button>
			<button onClick={goNext} className="next"><span className="icon-next"></span></button>
		</div>
	);
};

export default ManipulatingSwiper;