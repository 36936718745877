export default (state, action) => {
	switch (action.type) {
		case "GEO_LOCATION":
			return {
				...state,
				geoLocation: action.geoLocation
			};
		case "CART_COUNT":
			return {
				...state,
				cartCount: action.cartCount
			};
		case "ALERT_MESSAGES":
			return {
				...state,
				alertMessages: action.alertMessages
			};
		case "SET_LANGUAGE":
			return {
				...state,
				language: action.language
			};
		case "SET_USER_TYPE":
			return {
				...state,
				userType: action.userType
			};
		case "SET_VENDOR_INFOS":
			return {
				...state,
				vendorInfos: action.vendorInfos
			};
		default:
		return state;
	}
};