// Terms And Condition js
import React, {Component} from 'react';
import webservice from '../services/webservice';
import { Api } from '../common/variables';
import renderHTML from 'react-render-html';
import MetaTags from 'react-meta-tags';
import Loader from '../common/Loader';

// Redux set data
import { connect } from "react-redux";

class TermsAndCondition extends Component {
	constructor() {
		super()
		this.state = {
		   pageDetailApi : false,
		}
	}

	async componentDidMount() {
		let params = {
			pageSlug : 'terms-conditions',
			lang     : this.props.language
		}

		let getPageDetail = await webservice.httpPost( Api.method.getPageDetail, params );
		if ( getPageDetail.status === 'ok' ) {
			this.setState({
				pageDetailApi    : true,
				pageTitle        : getPageDetail.data.title,
				pageContent      : getPageDetail.data.content,
				metaTitle        : getPageDetail.data.meta_title,
				metaKeywords     : getPageDetail.data.meta_keywords,
				metaDescriptions : getPageDetail.data.meta_descriptions
			})
		}
	}

	render() {
		return (
			<div className="termsPage">
				{/*For Meta tags Dynamic content*/}
				<MetaTags>
					<title>{ this.state.metaTitle ? this.state.metaTitle : 'Gharbanau - A marketplace for construction materials for Nepal' }</title>
					<meta name="keywords" content={ this.state.metaKeywords ? this.state.metaKeywords : 'terms and condition, gharbanau' } />
					<meta
					name="description"
					content={ this.state.metaDescriptions ? this.state.metaDescriptions : 'Gharbanau - A marketplace for construction materials for Nepal' }
					/>
				</MetaTags>

				{
					( this.state.pageDetailApi ) ?
					<div className="termswrap">
						{
							( this.state.pageTitle ) ?
							<div className="banner">
								<div className="image_sec bgColor_sec" key="1">
									<div className="bannercontent">
										<div className="wrapper wrapper_1200">
											<span className="title-small-banner">{ this.state.pageTitle }</span>
										</div>
									</div>
								</div>
							</div>
							: null
						}

						<div className="innerContent">
							<div className="wrapper wrapper_1200">
								{ ( this.state.pageContent ) ? renderHTML( this.state.pageContent ) : null }
							</div>
						</div>
					</div>
					: <Loader />
				}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

export default connect( mapStateToProps, null )(TermsAndCondition);