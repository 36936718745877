// Show category list
import React, {Component} from 'react';
import webservice from '../../../services/webservice';
import { Api } from '../../../common/variables';
import noImage from '../../../assets/images/no-image-available.png';
import './_brand.scss';
import { isBrowser } from 'react-device-detect';

// Language
import utils from '../../../common/utils';

// Redux set data
import { connect } from "react-redux";
// Lazy load
import { LazyLoadImage } from 'react-lazy-load-image-component';

class FeaturedBrandList extends Component {
    constructor() {
        super()
        this.state = {
            featuredBrandApi: false,
        }
    }

    // fetch all featured brands from api
    async componentDidMount() {
        let params = {
            lang  : this.props.language
        }

        if ( true === isBrowser ) {
            params.isBrowser = isBrowser;
        }

        let featuredbrands = await webservice.httpPost( Api.method.getProductBrands, params );
        if ( featuredbrands.status === 'ok' ) {
            this.setState({
                featuredBrandApi: true,
                featuredbrand: featuredbrands.data
            })
        }
    }

    render() {

        if ( ! this.state.featuredBrandApi ) {
            return null;
        }

        return (
            <div className="wrapper wrapper_1200">
                <div className="category_wrapper">
                    <h2 className="catTitle title-withline"><span className="wrapwhite">{ utils.Language( 'lang_featureBrand', this.props.language ) }</span></h2>
                    <div className="row home_featured-brands">
                    {
                        ( this.state.featuredBrandApi ) ?
                            this.state.featuredbrand.map( (brand,index) => {
                                return(
                                    <div className="featuredbrands col-lg-2 col-md-3 col-sm-4 col-6" key={brand.id}>
                                        <div className="featuredbrandsImg"><div className="img-wrapper"><a href={ '/search?keyword=' + brand.name  }><LazyLoadImage src={ brand.imageUrl ? brand.imageUrl : noImage } alt={ brand.name } /></a></div></div>
                                    </div>
                                )
                            })
                        : null
                    }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    ...state
});
export default connect( mapStateToProps, null )(FeaturedBrandList);