// CategoriesProductList js
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import webservice from '../services/webservice';
import { Api } from '../common/variables';
import noImage from '../assets/images/no-image-available.png';
import Pagination from "../components/Pagination";
import MetaTags from 'react-meta-tags';
import { isMobile, isBrowser } from 'react-device-detect';
import { FacebookShareButton, TwitterShareButton, EmailShareButton,  TwitterIcon, FacebookIcon, EmailIcon,  } from "react-share";

// Language
import utils from '../common/utils';

// Redux set data
import { connect } from "react-redux";
import { removeFromWishlist, addToWishlist, addItemToCart } from '../services/common';
import { setCartCount, setAlertMessage } from '../action/commonAction';

class CategoryProductList extends Component {
	constructor() {
		super()
		this.state = {
			products: [],
			productCategoriesApi: false,
			brandsCategoryApi: false,
			filteredProductsApi: false,
			sortby: 'latest-update',
			og_image_url: null,
			searchValue: '',
			url: '',
			limit: 15,
			shows: false,
		}

		this.selectedCheckboxes = new Set();
		this.selectedCheckboxesArray = [];
		this.filterBrand = this.filterBrand.bind(this);
		this.myDivToFocus = React.createRef();
	}

	async componentDidMount() {
		window.scrollTo({ top: 0 });
		var str = Api.backEndapiUrl;
		var strrepl = str.replace(/\/+$/, '');
		this.setState({ url: strrepl + this.props.match.url });

		let catSlug = this.props.match.params.slug;
		let ids = this.getUrlParameter('brand');
		let search = this.getUrlParameter('search');

		if (search) {
			this.setState({ searchValue: search })
		}

		if (ids) {
			var arrayIds = ids.split(',');
			this.selectedCheckboxesArray = arrayIds;

			for (const [index, value] of arrayIds.entries()) {
				this.selectedCheckboxes.add(value);
			}
		}

		let params = {
			lang: this.props.language,
			CurrentPage: 1,
			CategorySlug: catSlug,
			limit: this.state.limit,
			SortBy: this.state.sortby,
			SearchText: search,
			BrandIds: this.selectedCheckboxesArray,
			Latitude: this.props.geoLocation.latitude,
			Longitude: this.props.geoLocation.longitude
		}

		if (true === isBrowser) {
			params.isBrowser = isBrowser;
		}

		let products = await webservice.httpPost(Api.method.getProductsByFilter, params);

		if (products.status === 'ok') {
			this.setState({
				productCategoriesApi: true,
				paginationtype: 'list',
				catslug: catSlug,
				products: products.data,
				catname: products.categoryName,
				totalproducts: products.total_records,
				og_image_url: products.categoryImage ? products.categoryImage : products.data[0].imageUrl,
				catdescription: products.categoryDescription
			})

			if (isMobile) {
				// window.location = 'gharbanauapp://CategoriesProductList/' + products.categoryID + '/' + products.categoryName.replace( ' ', '::' );
				if (document.getElementById("appLink")) {
					document.getElementById("appLink").href = 'gharbanauapp://CategoriesProductList/' + products.categoryID + '/' + products.categoryName.replace(' ', '::');
				}
			}
		}

		let brands = await webservice.httpPost(Api.method.getBrandsByCategorySlug, params);
		if ('ok' === brands.status) {
			this.setState({
				brandsCategoryApi: true,
				brands: brands.data
			})
		}
	}

	getUrlParameter = (sParam) => {
		var sPageURL = window.location.search.substring(1),
			sURLVariables = sPageURL.split('&'),
			sParameterName,
			i;

		for (i = 0; i < sURLVariables.length; i++) {
			sParameterName = sURLVariables[i].split('=');

			if (sParameterName[0] === sParam) {
				return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
			}
		}
	}

	filterBrand = async (e) => {
		this.setState({
			shows: false
		});
		let catSlug = this.props.match.params.slug;

		if (undefined !== e && '' !== e.target.id) {
			if (this.selectedCheckboxes.has(e.target.id)) {
				this.selectedCheckboxes.delete(e.target.id);
				var index = this.selectedCheckboxesArray.indexOf(e.target.id);
				if (-1 !== index) {
					this.selectedCheckboxesArray.splice(index, 1);
				}
			} else {
				this.selectedCheckboxes.add(e.target.id);
				this.selectedCheckboxesArray.push(e.target.id);
			}
		}

		if (this.selectedCheckboxesArray.length > 0) {
			window.history.replaceState({}, '', "?brand=" + this.selectedCheckboxesArray.join());
		}

		// if ( this.menu.value ) {
		// 	window.history.replaceState( {}, '', "?sortby=" + this.menu.value );
		// }

		if (this.state.searchValue) {
			window.history.replaceState({}, '', "?search=" + this.state.searchValue);
		}

		if (this.selectedCheckboxesArray.length > 0 && this.state.searchValue) {
			window.history.replaceState({}, '', "?brand=" + this.selectedCheckboxesArray.join() + "&search=" + this.state.searchValue);
		}

		// if ( this.selectedCheckboxesArray.length > 0 && this.menu.value ) {
		// 	window.history.replaceState( {}, '', "?brand=" + this.selectedCheckboxesArray.join() + "&sortby=" + this.menu.value );
		// }

		// if ( this.state.searchValue && this.menu.value ) {
		// 	window.history.replaceState( {}, '', "?search=" + this.state.searchValue + "&sortby=" + this.menu.value );
		// }

		// if ( this.selectedCheckboxesArray.length > 0 && this.state.searchValue && this.menu.value ) {
		// 	window.history.replaceState( {}, '', "?brand=" + this.selectedCheckboxesArray.join() + "&search=" + this.state.searchValue + "&sortby=" + this.menu.value );
		// }

		let params = {
			lang: this.props.language,
			BrandIds: this.selectedCheckboxesArray,
			SearchText: this.state.searchValue,
			SortBy: this.menu.value,
			CategorySlug: catSlug,
			Latitude: this.props.geoLocation.latitude,
			Longitude: this.props.geoLocation.longitude,
			limit: this.state.limit,
			Offset: 0,
			CurrentPage: 1,
		}

		if (true === isBrowser) {
			params.isBrowser = isBrowser;
		}

		let filteredProducts = await webservice.httpPost(Api.method.getProductsByFilter, params);
		if ('ok' === filteredProducts.status) {
			this.setState({
				products: filteredProducts.data,
				totalproducts: filteredProducts.total_records,
				productCategoriesApi: true,
				paginationtype: 'filter',
				searchtext: this.state.searchValue,
				brandids: this.selectedCheckboxesArray,
				sortby: this.menu.value,
				currentPage: 1
			})
		}

		const currentPage = 1;
		const paginationData = {
			totalRecords: parseInt(this.state.totalproducts),
			pageLimit: this.state.limit,
			pageNeighbours: 1,
			onPageChanged: this.onFilterPageChanged,
		};
		this.setState({ currentPage }, () => this.onPageChanged(paginationData));
	}

	onPageChanged = async (data) => {
		let params = {
			lang: this.props.language,
			CategorySlug: this.state.catslug,
			BrandIds: this.selectedCheckboxesArray,
			SearchText: this.state.searchtext,
			SortBy: this.menu.value,
			Offset: undefined !== data.currentPage ? (data.currentPage - 1) * data.pageLimit : 0,
			CurrentPage: undefined !== data.currentPage ? data.currentPage : 1,
			limit: this.state.limit,
			Latitude: this.props.geoLocation.latitude,
			Longitude: this.props.geoLocation.longitude
		}

		if (true === isBrowser) {
			params.isBrowser = isBrowser;
		}

		let products = await webservice.httpPost(Api.method.getProductsByFilter, params);
		if (products.status === 'ok') {
			this.setState({
				products: products.data,
				totalproducts: products.total_records,
				totalPages: products.total_pages,
				productCategoriesApi: true,
				currentPage: products.current_page,
				catslug: this.state.catslug,
				paginationtype: 'list',
			})

			// const id      = 'scrolle_here';
			// const yOffset = -160; 
			// const element = document.getElementById( id );
			// const y       = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
			// window.scrollTo({top: y, behavior: 'smooth'});
		}
	};

	onFilterPageChanged = async (data) => {
		let params = {
			lang: this.props.language,
			CategorySlug: this.state.catslug,
			BrandIds: this.selectedCheckboxesArray,
			SearchText: this.state.searchtext,
			SortBy: this.state.sortby,
			Offset: undefined !== data.currentPage ? (data.currentPage - 1) * data.pageLimit : 0,
			CurrentPage: undefined !== data.currentPage ? data.currentPage : 1,
			limit: this.state.limit,
			Latitude: this.props.geoLocation.latitude,
			Longitude: this.props.geoLocation.longitude
		}

		if (true === isBrowser) {
			params.isBrowser = isBrowser;
		}

		let filteredProducts = await webservice.httpPost(Api.method.getProductsByFilter, params);
		if ('ok' === filteredProducts.status) {
			this.setState({
				products: filteredProducts.data,
				totalproducts: filteredProducts.total_records,
				totalPages: filteredProducts.total_pages,
				productCategoriesApi: true,
				currentPage: filteredProducts.current_page,
				catslug: this.state.catslug,
				paginationtype: 'filter',
			})
		}

		// const id      = 'scrolle_here';
		// const yOffset = -160; 
		// const element = document.getElementById( id );
		// const y       = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
		// window.scrollTo({top: y, behavior: 'smooth'});
	};

	// Add to cart item
	addItemToCart = async (productID, minQty) => {
		if (minQty === 0) {
			minQty = 1;
		}

		const newdata = await addItemToCart(productID, minQty, this.props.language);
		this.props.setCartCount(newdata.count);
		this.props.setAlertMessage(newdata.message);
	}

	//add to wishlist
	addToWishlist = async (productID) => {
		const newdata = await addToWishlist(productID, this.state.products, this.props.language);

		if (newdata.productsArray) {
			this.setState({
				products: newdata.productsArray,
				productCategoriesApi: newdata.productsApi
			});
		}
		this.props.setAlertMessage(newdata.message);
	}

	//remove from wishlist
	removeFromWishlist = async (productID) => {
		const newdata = await removeFromWishlist(productID, this.state.products, this.props.language);

		this.setState({
			products: newdata.productsArray,
			productCategoriesApi: newdata.productsApi
		});
		this.props.setAlertMessage(newdata.message);
	}

	desktopSearch = (event) => {
		this.setState({ searchValue: event.target.value })
	}

	renderCategories() {
		const category = [];
		for (let i = 0; i < 15; i++) {
			// Try avoiding the use of index as a key, it has to be unique!
			category.push(
				<div className="col-lg-3 col-md-4 col-sm-4 col-6" key={i}>
					<div className="categoryList" style={{ boxShadow: 'none', alignItems: 'center', justifyContent: 'center', }}>
						<div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100px', }}>
							<img src={require('../assets/images/placeholder.png')} alt="productsimage" style={{ width: '30px' }} />
						</div>
						<div style={{ width: '70%', backgroundColor: '#ddd', height: '10px', borderRadius: '3px', marginLeft: 'auto', marginRight: 'auto', marginBottom: '5px' }}>
						</div>
						<div style={{ width: '50%', backgroundColor: '#ddd', height: '10px', marginLeft: 'auto', marginRight: 'auto', borderRadius: '3px', }}>
						</div>
					</div>
				</div>
			);
		}
		return category;
	}

	render() {
		return (
			<div className="category_wrapper category-page-list page-list" ref={this.myDivToFocus} id="scrolle_here">
				{/*For Meta tags Dynamic content*/}
				<MetaTags>
					<title>
						{this.state.catname ? 'Gharbanau.com - ' + this.state.catname : 'Gharbanau.com'}
					</title>
					<meta name="keywords" content="category, gharbanau" />
					<meta
						name="description"
						content={this.state.catdescription ? this.state.catdescription : 'A marketplace for construction materials for Nepal'}
					/>
					<meta property="og:url" content={window.location.href} />
					<meta property="og:type" content="Category page" />
					<meta property="og:title" content={this.state.catname} />
					<meta property="og:description" content={this.state.catdescription ? this.state.catdescription : 'A marketplace for construction materials for Nepal'} />
					<meta property="og:image" content={this.state.og_image_url} />
				</MetaTags>

				<div className="wrapper wrapper_1200 padding_side_15">
					<div className="breadcrumb-section">
						<div className="breadcrumb-div">
							<span className="miniTitle breadcrumb-item"><Link to="/" className="link">{utils.Language('lang_home', this.props.language)} </Link></span>
							<span className="miniTitle breadcrumb-item"><Link to="/product-categories" className="link">{utils.Language('lang_productCategories', this.props.language)}</Link></span>
							<span className="miniTitle breadcrumb-item">{this.state.catname}</span>
						</div>
					</div>

					<div className="row">
						<div className="col-lg-2 col-md-3 col-sm-12 category-page-list-left">
							<div className="">
								<div className="miniTitle-div">
									<h3 className="miniTitle">{utils.Language('lang_brand', this.props.language)}</h3>
									<div className="filter show-in-mobile" onClick={() => { this.setState({ shows: !this.state.shows }) }}><span className={this.state.shows ? 'fa fa-filter open' : 'fa fa-filter'}></span> </div>
								</div>
								{
									this.state.shows ?
										<div className="section_filter-div show-in-mobile">
											<div className="section_filter">
												<div className="section_filter-search">
													<input
														type="text"
														className="search_input"
														value={this.state.searchValue}
														placeholder={utils.Language('lang_search', this.props.language)}
														// ref={ el => this.searchValue=el }
														onChange={this.desktopSearch}
														onKeyPress={event => {
															if (event.key === 'Enter') {
																this.filterBrand();
															}
														}}
													/>
													<button className="search-btn" onClick={this.filterBrand}><span className="icon_search"></span></button>
												</div>
											</div>
											{
												(this.state.brandsCategoryApi) ?
													this.state.brands.map((brand, index) => {
														var checked = this.selectedCheckboxesArray.includes(brand.id);
														return (
															<div className="section_filter-bycatname" key={index} >
																<div className="filter-bycatname">
																	<div className="bycatname">
																		<div className="custom-control custom-checkbox">
																			<input type="checkbox" className="custom-control-input" name="productbrand" onClick={this.filterBrand} id={brand.id} defaultChecked={checked ? "defaultChecked" : ''} />
																			<label className="custom-control-label" htmlFor={brand.id}>{brand.name}</label>
																		</div>
																	</div>

																	<span className="num-cat-item">{brand.count}</span>
																</div>
															</div>
														);
													})
													: null
											}
											<div className="socialShareWrap">
												<span className="social-text">
													{utils.Language('lang_socialShare', this.props.language)}
												</span>
												<EmailShareButton url={this.state.url}>
													<EmailIcon size={20} round={false} />
												</EmailShareButton>
												<FacebookShareButton url={this.state.url}>
													<FacebookIcon size={20} round={false} />
												</FacebookShareButton>
												<TwitterShareButton url={this.state.url}>
													<TwitterIcon size={20} round={false} />
												</TwitterShareButton>
												{/* <PinterestShareButton url={ this.state.url }>
										<PinterestIcon size={20} round={false} />
									</PinterestShareButton> */}
											</div>
										</div>
										: null
								}

								<div className="section_filter-div show-in-desktop">
									<div className="section_filter">
										<div className="section_filter-search">
											<input
												type="text"
												className="search_input"
												value={this.state.searchValue || ''}
												placeholder={utils.Language('lang_search', this.props.language)}
												// ref={ el => this.searchValue=el }
												onChange={this.desktopSearch}
												onKeyPress={event => {
													if (event.key === 'Enter') {
														this.filterBrand();
													}
												}}
											/>
											<button className="search-btn" onClick={this.filterBrand}><span className="icon_search"></span></button>
										</div>
									</div>
									{
										(this.state.brandsCategoryApi) ?
											this.state.brands.map((brand, index) => {
												var checked = this.selectedCheckboxesArray.includes(brand.id);
												return (
													<div className="section_filter-bycatname" key={index} >
														<div className="filter-bycatname">
															<div className="bycatname">
																<div className="custom-control custom-checkbox">
																	<input type="checkbox" className="custom-control-input" name="productbrand" onClick={this.filterBrand} id={brand.id} defaultChecked={checked ? "defaultChecked" : ''} />
																	<label className="custom-control-label" htmlFor={brand.id}>{brand.name}</label>
																</div>
															</div>

															<span className="num-cat-item">{brand.count}</span>
														</div>
													</div>
												);
											})
											: null
									}
									<div className="socialShareWrap">
										<span className="social-text">
											{utils.Language('lang_socialShare', this.props.language)}
										</span>
										<EmailShareButton url={this.state.url}>
											<EmailIcon size={20} round={false} />
										</EmailShareButton>
										<FacebookShareButton url={this.state.url}>
											<FacebookIcon size={20} round={false} />
										</FacebookShareButton>
										<TwitterShareButton url={this.state.url}>
											<TwitterIcon size={20} round={false} />
										</TwitterShareButton>
										{/* <PinterestShareButton url={ this.state.url }>
												<PinterestIcon size={20} round={false} />
											</PinterestShareButton> */}
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-10 col-md-9 col-sm-12 category-page-list-right">
							<div className="category-list-attop">
								<div className="section_sort">
									<div className="section_sort-div">
										<select defaultValue="latest-update" ref={(input) => this.menu = input} onChange={this.filterBrand}>
											{/* <option value="near-location">{ utils.Language( 'lang_nearestLocation', this.props.language ) }</option> */}
											<option value="price-highest-first">{utils.Language('lang_price_high_to_low', this.props.language)}</option>
											<option value="price-lowest-first">{utils.Language('lang_price_low_to_high', this.props.language)}</option>
											<option value="popularity">{utils.Language('lang_popularity', this.props.language)}</option>
											<option value="latest-update">{utils.Language('lang_latestUpdate', this.props.language)}</option>
										</select>
									</div>
								</div>
							</div>

							<div className="category_wrapper-list">
								<div className="row">
									{
										(this.state.productCategoriesApi) ?
											this.state.products.map((product, index) => {
												return (
													<div className="col-lg-3 col-md-4 col-sm-4 col-6" key={product.id + index}>
														<div className="categoryList" id={product.id}>
															{
																(product.is_verified === '1' || product.is_verified === 1) ?
																	<div className="verified small-verified"><div className="verified-div"><img src={require('../assets/images/certifications.png')} alt="reward" className="verified-logo" /> {utils.Language('lang_verified', this.props.language)}</div></div>
																	: null
															}
															{
																('seller' !== this.props.userType) ?
																	<div className="wishlist-div">
																		{
																			(true === product.wishlistStatus) ?
																				<div className="wishlist wishlist-added" title="Wishlist" onClick={() => { this.removeFromWishlist(product.id) }}><span className="icon-heart-black icon-heart-red icon-fav"></span></div>
																				:
																				<div className="wishlist" title="Wishlist" onClick={() => { this.addToWishlist(product.id) }}><span className="icon-heart-black icon-fav"></span></div>
																		}
																	</div>
																	: null
															}
															<Link to={'/products/' + encodeURIComponent(product.slug)} className="link">
																<div className="imgWrapper" style={{}}>
																	<img src={product.imageUrl ? product.imageUrl : noImage} alt="categoryimage" />
																</div>
																<span className="product-name">{product.name}</span>

																{
																	(product.company_name || product.addressList) ?
																		<div className="supplierdetails">
																			<span className="company">{product.company_name}</span>
																			<span className="address">{product.addressList}</span>
																		</div>
																		: null
																}
																{/* commented removed google api */}
																{/* {
																	(product.distance) ?
																		<div className="product-distance">
																			<span className="icon icon-map-marker-alt"></span>
																			{Number.parseFloat(product.distance).toFixed(2)} {utils.Language('lang_' + product.distanceType, this.props.language)}</div>
																		:
																		null
																} */}
																<div className="priceSec">
																	{
																		(product.regularPrice !== product.salePrice && product.salePrice !== '0.00') ?
																			<div><span className="regularprice">{utils.Language('lang_currency', this.props.language)} {new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format(product.regularPrice)}</span>
																				<span className="saleprice">{utils.Language('lang_currency', this.props.language)} {new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format(product.salePrice)}
																					{
																						(product.salePriceValidTill) ?
																							<div className="singleBrands validtilldate">
																								<span className="smallText">({utils.Language('lang_priceValidTill', this.props.language)} {product.salePriceValidTill})</span>
																							</div>
																							: null
																					}
																				</span></div>
																			:
																			<span className="saleprice">{utils.Language('lang_currency', this.props.language)} {new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format(product.regularPrice)}</span>
																	}
																</div>
															</Link>
															<div className="starrating-div">
																{
																	(product.rating) ?
																		<StarRatings
																			rating={parseFloat(product.rating)}
																			starRatedColor="#ff6f00"
																			starHoverColor="#ff6f00"
																			numberOfStars={5}
																			name='rating'
																			starDimension="20px"
																			starSpacing="0"
																		/>
																		: null
																}
															</div>
															{
																('seller' !== this.props.userType) ?
																	<div data-mh className="productDetail productDetailnew">
																		<button type="button" className="btn btn-danger btn-add-cart" onClick={() => { this.addItemToCart(product.id, parseInt(product.minimum_quantity)) }}><span className="icon-cart-add"></span>{utils.Language('lang_addToCart', this.props.language)}</button>
																	</div>
																	: null
															}
														</div>
													</div>
												)
											})
											:
											this.renderCategories()
									}
								</div>
							</div>

							<div className="pagination_wrapper">
								<span className="pagination-items">{this.state.totalproducts} {utils.Language('lang_itemFound', this.props.language)}</span>
								{
									(this.state.totalproducts > this.state.limit && 'list' === this.state.paginationtype) ?
										<Pagination
											totalRecords={parseInt(this.state.totalproducts)}
											pageLimit={parseInt(this.state.limit)}
											pageNeighbours={1}
											onPageChanged={this.onPageChanged}
										/>
										: null
								}
								{
									(this.state.totalproducts > this.state.limit && 'filter' === this.state.paginationtype) ?
										<Pagination
											totalRecords={parseInt(this.state.totalproducts)}
											pageLimit={parseInt(this.state.limit)}
											pageNeighbours={1}
											onPageChanged={this.onFilterPageChanged}
										/>
										: null
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({
	setCartCount: (count) => dispatch(setCartCount(count)),
	setAlertMessage: (message) => dispatch(setAlertMessage(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryProductList);