import React, { useState } from "react";
import { Button, FormGroup, FormControl, Form } from "react-bootstrap";
import webservice from '../../services/webservice';
import { Api } from '../../common/variables';
import MapPopup from '../../components/MapPopup';

// Language
import utils from '../../common/utils';

export default function ForgotPassword( props ) {
	const [companyname, setCompanyName] = useState('');
	const [companynameerror, setCompanyNameError] = useState('');
	const [firstname, setFirstName] = useState('');
	const [firstnameerror, setFirstNameError] = useState('');
	const [lastname, setLastName] = useState('');
	const [companyaddress, setCompanyAddress] = useState('');
	const [lastnameerror, setLastNameError] = useState('');
	const [companyaddresserror, setCompanyAddressError] = useState('');
	const [number, setNumber] = useState('');
	const [numbererror, setNumberError] = useState('');
	const [email, setEmail] = useState('');
	const [latitude, setLatitude] = useState('');
	const [longitude, setLongitude] = useState('');
	// const [emailerror, setEmailerror] = useState('');
	const [password, setPassword] = useState('');
	const [checkstatus, checkStatus] = useState('');
	const [passworderror, setPassworderror] = useState('');
	const [confirmpassword, setconfirmPassword] = useState('');
	const [confirmpassworderror, setconfirmPassworderror] = useState('');
	const [matchpassworderror, setmatchPassword] = useState('');
	const [TermsandCondition, setTermsandCondition] = useState('');
	const [TermsandConditionerror, setTermsandConditionerror] = useState('');
	const [userOption, setUserOption] = useState( props.isBecomeASeller ? 'seller' : 'buyer' );
	const [responseError, serverResponseError] = useState('');
	const [loaderClass, loaderHidden] = useState( ' hidden' );

	const UserAuthentication = async () => {
		//user authentication
		let credentials = {
			username: email ? email : number,
			password: password
		}

		let authentication = await webservice.httpPost( Api.method.login, credentials );
		if ( authentication.status === 'ok' ) {
			  let userDetail = {
				'userID' : authentication.data.ID,
				'Email' : authentication.data.Email,
				'FullName' : authentication.data.FullName,
				'User_Type' : authentication.data.User_Type,
				'Birth_Date': authentication.data.Birth_Date,
				'Gender' : authentication.data.Gender,
				'Mobile_Number': authentication.data.Mobile_Number,
				'token' : authentication.token
			}

			// store login history of user
			let params = {
				'Platform' : 2,
				'AuthStatus' : 1,
				'ip' : props.ipaddress,
				'UserId' : authentication.data.ID,
				'Device_Browser' : props.Device_Browser
			}
			await webservice.httpPost( Api.method.getUserLoginHistory, params );

			await localStorage.setItem( 'userdetail', JSON.stringify( userDetail ) );
			document.location.href = Api.backEndapiUrl + 'set-session/?token='+ authentication.token +'&type=1';
		} else {
			if ( authentication.message === 'INVALID_LOGIN_CREDENTIALS' ) {
				serverResponseError( utils.Language( 'lang_invalidLoginCredentials', props.language ) );
			} else {
				serverResponseError( authentication.message );
			}
		}
	}

	const handleSubmit = async ( event ) => {
		event.preventDefault();
		if ( checkstatus ) return;
		loaderHidden( '' );

		if ( 'seller' === userOption ) {
			if ( companyname === '' ) {
				setCompanyNameError( utils.Language( 'lang_errorCompanyName', props.language ) );
			} else {
				setCompanyNameError( '' );
			}

			if ( companyaddress === '' ) {
				setCompanyAddressError( utils.Language( 'lang_errorAddress', props.language ) );
			} else {
				setCompanyAddressError( '' );
			}
		}

		if ( firstname === '' ) {
			setFirstNameError( utils.Language( 'lang_firstname_req', props.language ) );
		} else {
			setFirstNameError( '' );
		}

		if ( lastname === '' ) {
			setLastNameError( utils.Language( 'lang_lastname_req', props.language ) );
		} else {
			setLastNameError( '' );
		}

		if ( number === '' ) {
			setNumberError( utils.Language( 'lang_mobileNumber_req', props.language ) );
		} else {
			setNumberError( '' );
		}

		// if ( email === '' ) {
		// 	setEmailerror( utils.Language( 'lang_emailAddress_req', props.language ) );
		// } else {
		// 	setEmailerror( '' );
		// }

		if ( password === '' ) {
			setPassworderror( utils.Language( 'lang_password_req', props.language ) );
		} else {
			setPassworderror( '' );
		}

		if ( confirmpassword === '' ) {
			setconfirmPassworderror( utils.Language( 'lang_confirmPasswordRequired', props.language ) );
		} else {
			setconfirmPassworderror( '' );
		}

		if ( confirmpassword !== password && confirmpassword !== '' && password !== '' ) {
			setmatchPassword( utils.Language( 'lang_passwordResetPasswordError', props.language ) );
		} else {
			setmatchPassword( '' );
		}

		if ( ! TermsandCondition ) {
			setTermsandConditionerror( utils.Language( 'lang_terms', props.language ) );
		} else {
			setTermsandConditionerror( '' );
		}

		if ( firstname === '' || lastname === '' || number === '' || password === '' || confirmpassword === '' || confirmpassword !== password || ! TermsandCondition ) {
			loaderHidden( ' hidden' );
			return;
		}

		if ( 'seller' === userOption ) {
			if ( companyname === '' || companyaddress === '' ) {
				loaderHidden( ' hidden' );
				return;
			}
		}

		let newUserRegister = {};
		if ( 'seller' === userOption ) {
			newUserRegister = {
				Full_Name : firstname + ' ' + lastname,
				Email_Address : email,
				Mobile_Number: number,
				Password: password,
				User_Type: userOption,
				Company_Name: companyname,
				Mobile_Verification: false,
				Latitude: latitude,
				Longitude: longitude,
				Street: companyaddress,
				Terms_Accepted: TermsandCondition
			}
		} else {
			newUserRegister = {
				Full_Name : firstname + ' ' + lastname,
				Email_Address : email,
				Mobile_Number: number,
				Password: password,
				User_Type: userOption,
				Mobile_Verification: false,
				Terms_Accepted: TermsandCondition
			}
		}

		let registerNewUser = await webservice.httpPost( Api.method.userSignup, newUserRegister );
		if ( 'ok' === registerNewUser.status ) {
			//authenticate user and redirect to dashboard
			UserAuthentication();
			// props.validateCodeSection( number );
			loaderHidden( ' hidden' );
		} else {
			if ( 'DUPLICATE_EMAIL' === registerNewUser.message ) {
				serverResponseError( utils.Language( 'lang_emailInUse', props.language ) );
			} else if ( 'DUPLICATE_PHONE' === registerNewUser.message ) {
				serverResponseError( utils.Language( 'lang_phoneDuplicate', props.language ) );
			} else {
				serverResponseError( registerNewUser.message );
			}
			loaderHidden( ' hidden' );
		}
	}

	const companyAddressLatLng = ( lat, lng, addrs ) => {
		setLatitude( lat );
		setLongitude( lng );
		setCompanyAddress( addrs );
	}

	return (
		<div>
			<div className="modalHeader">
				<Button className="close" onClick={ props.exitPopup }>&times;</Button>
				<p>{ utils.Language( 'lang_signUp', props.language ) }</p>
			</div>
			<div className="modalBody">
				<div className="signUp">
					{ ( responseError ) ? <div className="error-msg"><p>{ responseError }</p></div> : null }
					<form onSubmit={handleSubmit}>
						<div className="row">
							<div className="col-sm-12">
								<div className="row">
									<div className="col-sm-6">
										<div className="d-flex">
											<FormGroup className="customCheckbox">
												<input type="radio" id="buyer" value="buyer" name="chekoptions" className="inputboxes" onChange={e => setUserOption(e.target.value)} defaultChecked={ props.isBecomeASeller ? '' : 'defaultChecked' } />
												<label className="labelRadio" htmlFor="buyer">{ utils.Language( 'lang_IMBuyer', props.language ) }</label>
											</FormGroup>
										</div>
									</div>
									<div className="col-sm-6">
										<FormGroup className="customCheckbox">
											<input type="radio" id="seller" value="seller" name="chekoptions" className="inputboxes" onChange={e => setUserOption(e.target.value)} defaultChecked={ props.isBecomeASeller ? 'defaultChecked' : '' } />
											<label className="labelRadio" htmlFor="seller">{ utils.Language( 'lang_IMSeller', props.language ) }</label>
										</FormGroup>
									</div>
								</div>
							</div>
							{
								( 'seller' === userOption ) ?
								<div className="col-sm-6">
									<FormGroup controlId="fName">
										<Form.Label>{ utils.Language( 'lang_CompanyName', props.language ) } <span className="error-msg">*</span></Form.Label>
										<FormControl
										autoFocus={ props.isBecomeASeller || 'seller' === userOption ? 'autoFocus' : '' }
										type="text"
										onChange={e => setCompanyName(e.target.value)}
										className="inputBox"
										/>
										<div className="error-msg"><p>{ companynameerror }</p></div>
									</FormGroup>
								</div>
								: null
							}							
							{
								( 'seller' === userOption ) ?
									// <div className="col-sm-6 por withmapicon">
									<div className="col-sm-6 por">
										<div className="mapicon">
											<MapPopup signup={true} companyAddressLatLng = { (lat, lng, addrs) => companyAddressLatLng( lat, lng, addrs ) } checkStatus={ e => checkStatus( e ) } />
										</div>
										<FormGroup controlId="lName">
											<Form.Label>{ utils.Language( 'lang_changeCompanyAddress', props.language ) } <span className="error-msg">*</span></Form.Label>
											<FormControl
											type="text"
											value = {companyaddress}
											onChange={e => setCompanyAddress(e.target.value)}
											className="inputBox"
											/>
											<div className="error-msg"><p>{ companyaddresserror }</p></div>
										</FormGroup>
									</div>
								: null
							}
							<div className="col-sm-6">
								<FormGroup controlId="fName">
									<Form.Label>{ utils.Language( 'lang_firstName', props.language ) } <span className="error-msg">*</span></Form.Label>
									<FormControl
									autoFocus={ ! props.isBecomeASeller || 'seller' !== userOption ? 'autoFocus' : '' }
									type="text"
									onChange={e => setFirstName(e.target.value)}
									className="inputBox"
									/>
									<div className="error-msg"><p>{ firstnameerror }</p></div>
								</FormGroup>
							</div>
							<div className="col-sm-6">
								<FormGroup controlId="lName">
									<Form.Label>{ utils.Language( 'lang_lastName', props.language ) } <span className="error-msg">*</span></Form.Label>
									<FormControl
									type="text"
									value = {lastname}
									onChange={e => setLastName(e.target.value)}
									className="inputBox"
									/>
									<div className="error-msg"><p>{ lastnameerror }</p></div>
								</FormGroup>
							</div>

							<div className="col-sm-6">
								<FormGroup controlId="mobinenumber">
									<Form.Label>{ utils.Language( 'lang_mobileNumber', props.language ) } <span className="error-msg">*</span></Form.Label>
									<FormControl
									type="number"
									onChange={e => setNumber(e.target.value)}
									className="inputBox"
									/>
									<div className="error-msg"><p>{ numbererror }</p></div>
								</FormGroup>
							</div>
							<div className="col-sm-6">
								<FormGroup controlId="email">
									<Form.Label>{ utils.Language( 'lang_emailAddress', props.language ) } {/*<span className="error-msg">*</span>*/}</Form.Label>
									<FormControl
									type="email"
									onChange={e => setEmail(e.target.value)}
									className="inputBox"
									/>
									{/*<div className="error-msg"><p>{ emailerror }</p></div>*/}
								</FormGroup>
							</div>

							<div className="col-sm-12">
								<div className="row">
									<div className="col-sm-6">
										<FormGroup controlId="password">
											<Form.Label>{ utils.Language( 'lang_password', props.language ) } <span className="error-msg">*</span></Form.Label>
											<FormControl
											value={password}
											onChange={e => setPassword(e.target.value)}
											type="password"
											className="inputBox"
											/>
											<div className="error-msg"><p>{ passworderror }</p></div>
										</FormGroup>
									</div>

									<div className="col-sm-6">
										<FormGroup controlId="confirmpassword">
											<Form.Label>{ utils.Language( 'lang_confirmPassword', props.language ) } <span className="error-msg">*</span></Form.Label>
											<FormControl
											value={confirmpassword}
											onChange={e => setconfirmPassword(e.target.value)}
											type="password"
											className="inputBox"
											/>
											<div className="error-msg"><p>{ confirmpassworderror }</p></div>
										</FormGroup>
									</div>
									<div className="col-sm-12"><div className="error-msg"><p>{matchpassworderror}</p></div></div>
								</div>
							</div>

							<div className="col-sm-12">
								<FormGroup className="customCheckbox text-condition">
									<input type="checkbox" id="termsoption" name="termsoption" className="inputboxes" onChange={e => setTermsandCondition(e.target.checked)} />
									<label className="labelCheckbox" htmlFor="termsoption">{ utils.Language( 'lang_IacceptGharbanau', props.language ) } <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">{ utils.Language( 'lang_termsAndConditions', props.language ) }</a>. <span className="error-msg">*</span></label>
									<div className="error-msg"><p>{ TermsandConditionerror }</p></div>
								</FormGroup>
							</div>
						</div>
						<div className="btnWrapper">
							<Button className="btn btn-success" type="submit"><i className={ "fa fa-circle-o-notch fa-spin loaderspinner" + loaderClass }></i> { utils.Language( 'lang_signUp', props.language ) }</Button>
							<Button className="btn btn-danger" onClick={ props.isBecomeASeller ? props.exitPopup : props.signInSection }>{ utils.Language( 'lang_Cancel', props.language ) }</Button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}