import React, { useState } from "react";
// import { Link } from 'react-router-dom';
// import Popup from "reactjs-popup";
import { Button, FormGroup, FormControl, Form } from "react-bootstrap";
import webservice from '../../services/webservice';
import { Api } from '../../common/variables';
import ForgotPassword from './ForgotPassword';
import SignUp from './SignUp';
import ValidateCode from './ValidateCode';

// import FacebookLogin from 'react-facebook-login';
import { FacebookLogin } from 'react-facebook-login-component';

// Language
import utils from '../../common/utils';

export default function Login( props ) {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loaderClass, loaderHidden] = useState( ' hidden' );
	const [responseError, serverResponseError] = useState('');
	// const [loginuser, successLogin] = useState('');
	const [mobilenumber, setMobileNumber] = useState('');
	const [signin, signinModalPopup] = useState( props.isBecomeASeller ? false : true );
	const [reset, resetModalPopup] = useState();
	const [signup, signupnModalPopup] = useState( props.isBecomeASeller );
	const [validatecode, validatecodenModalPopup] = useState();

	const validateForm = () => {
		return email.length > 0 && password.length > 0;
	}

	const handleSubmit = async ( event ) => {
		event.preventDefault();
		loaderHidden( '' );
		serverResponseError( '' );

		var str = window.location.pathname;
		var replacefirstslash = str.replace('/',"" );

		//user authentication
		let credentials = {
			username: email,
			password: password
		}

		// Check user login authentication
		let authentication = await webservice.httpPost( Api.method.login, credentials );
		if ( authentication.status === 'ok' ) {

			let userDetail = {
				'userID' : authentication.data.ID,
				'Email' : authentication.data.Email,
				'FullName' : authentication.data.FullName,
				'User_Type' : authentication.data.User_Type,
				'Birth_Date': authentication.data.Birth_Date,
				'Gender' : authentication.data.Gender,
				'Mobile_Number': authentication.data.Mobile_Number,
				'profile_image': authentication.data.Profile_Image_Fullpath,
				'token' : authentication.token
			}

			await localStorage.setItem( 'userdetail', JSON.stringify( userDetail ) );

			// store login history of user
			let params = {
				'Platform' : 2,
				'AuthStatus' : 1,
				'ip' : props.ipaddress,
				'UserId' : authentication.data.ID,
				'Device_Browser' : props.Device_Browser
			}
			await webservice.httpPost( Api.method.getUserLoginHistory, params );

			loaderHidden( ' hidden' );
			document.location.href = Api.backEndapiUrl + 'set-session/?token='+ authentication.token +'&type=1&currenturl='+replacefirstslash;
		} else {
			if ( authentication.message === 'INVALID_LOGIN_CREDENTIALS' ) {
				serverResponseError( utils.Language( 'lang_userPassError', props.language ) );
			} else {
				serverResponseError( authentication.message );
			}
			loaderHidden( ' hidden' );
		}
	}

	// facebook login
	const responseFacebookLogin = async ( response ) => {
		var str = window.location.pathname;
		var replacefirstslash = str.replace('/',"" );

		if ( response.id && response.name && response.email && response.accessToken ) {
			let param = {
				fb_id: response.id,
				fb_name: response.name,
				fb_email: response.email,
				fb_accessToken: response.accessToken
			}
			let facebook_authentication = await webservice.httpPost( Api.method.AuthfromFacebook, param );
			if ( 'ok' === facebook_authentication.status ) {
				let userDetail = {
					'userID': facebook_authentication.data.ID,
					'Email': facebook_authentication.data.Email,
					'FullName': facebook_authentication.data.FullName,
					'User_Type': facebook_authentication.data.User_Type,
					'Birth_Date': facebook_authentication.data.Birth_Date,
					'Gender': facebook_authentication.data.Gender,
					'Mobile_Number': facebook_authentication.data.Mobile_Number,
					'profile_image': facebook_authentication.data.Profile_Image_Fullpath,
					'token': facebook_authentication.token
				}
				await localStorage.setItem( 'userdetail', JSON.stringify( userDetail ) );

				// store login history of user
				let params = {
					'Platform' : 2,
					'AuthStatus' : 1,
					'ip' : props.ipaddress,
					'UserId' : facebook_authentication.data.ID,
					'Device_Browser' : props.Device_Browser
				}
				await webservice.httpPost( Api.method.getUserLoginHistory, params );

				document.location.href = Api.backEndapiUrl + 'set-session/?token='+ facebook_authentication.token +'&type=1&currenturl='+replacefirstslash;
			} else {
				if ( facebook_authentication.message === 'INVALID_LOGIN_CREDENTIALS' ) {
					props.setAlertMessage( utils.Language( 'lang_invalidLoginCredentials', props.language ) );
				} else {
					props.setAlertMessage( facebook_authentication.message );
				}
			}
		} else {
			props.setAlertMessage( utils.Language( 'lang_invalidLoginCredentials', props.language ) );
		}
	}

	var formOpenClose = ( signin ) => {
		signinModalPopup( false );
		resetModalPopup( true );
	}

	var singupModalPopupfunction = () => {
		signinModalPopup( false );
		signupnModalPopup( true );
	}

	var returnBackToSignIn = () => {
		signinModalPopup( true );
		signupnModalPopup( false );
		resetModalPopup( false );
		validatecodenModalPopup( false );
	}

	var returnBackToValidateCode = ( e ) => {
		setMobileNumber( e );
		validatecodenModalPopup( true );
		signinModalPopup( false );
		signupnModalPopup( false );
		resetModalPopup( false );
	}

	return (
		<div className="forms">
			{
				( signin ) ?
				<div>
					<div className="modalHeader">
						<Button className="close" onClick={props.onCallBack}>&times;</Button>
						<p>{ utils.Language( 'lang_LogIn', props.language ) }</p>
					</div>
					<div className="modalBody">
						<div className="login">
							<p>{ utils.Language( 'lang_needLogin', props.language ) }</p>
							{/*<Link to={""} className="btn-signFb"><span className="icon-facebook-f"></span>{ utils.Language( 'lang_signWithFB', props.language ) }</Link>*/}
							<FacebookLogin
							socialId="527561618079629"
							language="en_US"
							scope="public_profile,email"
							responseHandler={ responseFacebookLogin }
							xfbml={true}
							fields="id,email,name"
							icon="icon-facebook-f"
							version="v2.5"
							className="btn-signFb"
							buttonText={ utils.Language( 'lang_signWithFB', props.language ) }
							/>
							<span className="text-medium">-{ utils.Language( 'lang_or', props.language ) }-</span>

							<form onSubmit={handleSubmit}>
								<FormGroup controlId="email">
									<Form.Label>{ utils.Language( 'lang_emailPhone', props.language ) } <span className="error-msg">*</span></Form.Label>
									<FormControl
									autoFocus
									type="text"
									value={email}
									onChange={e => setEmail(e.target.value)}
									className="inputBox"
									/>
								</FormGroup>

								<FormGroup controlId="password">
									<Form.Label>{ utils.Language( 'lang_password', props.language ) } <span className="error-msg">*</span></Form.Label>
									<FormControl
									value={password}
									onChange={e => setPassword(e.target.value)}
									type="password"
									className="inputBox"
									/>
								</FormGroup>
								{ ( responseError ) ? <div className="error-msg"><p>{ responseError }</p></div> : null }
								<div className="btnWrapper">
									<Button className="btn btn-success" disabled={!validateForm()} type="submit">
										<i className={ "fa fa-circle-o-notch fa-spin loaderspinner" + loaderClass }></i>
										{ utils.Language( 'lang_LogIn', props.language ) }
									</Button>
									<Button onClick={e => singupModalPopupfunction( true )} className="btn-secondary">
										{ utils.Language( 'lang_signUp', props.language ) }
									</Button>
								</div>
							</form>
							<div className="forgotpwd-div">
							<button className="forgotpwd" onClick={e => formOpenClose( true )}>{ utils.Language( 'lang_forgotPass', props.language ) }</button>
							</div>
						</div>
					</div>
				</div>
				: null
			}

			{
				( reset ) ?
				<ForgotPassword
				language={ props.language }
				exitPopup={ props.onCallBack }
				signInSection={ e => returnBackToSignIn() }
				/>
				: null
			}

			{
				( signup ) ?
				<SignUp
				language={ props.language }
				exitPopup={ props.onCallBack }
				ipaddress = { props.ipaddress }
				Device_Browser = { props.Device_Browser }
				signInSection={ e => returnBackToSignIn() }
				validateCodeSection={ e => returnBackToValidateCode( e ) }
				isBecomeASeller={props.isBecomeASeller ? true : false}
				/>
				: null
			}

			{
				( validatecode ) ?
				<ValidateCode
				language={ props.language }
				exitPopup={ props.onCallBack }
				ipaddress = { props.ipaddress }
				Device_Browser = { props.Device_Browser }
				mobilenumber = { mobilenumber }
				signInSection={ e => returnBackToSignIn() }
				/>
				: null
			}
		</div>
	);
}