// Banner js
import React, { Component } from 'react';
import Swiper from 'react-id-swiper';

// Redux set data
import { connect } from "react-redux";

class Banner extends Component {
	constructor() {
		super()
		this.state = {
			bannerApi: false,
			bannerImage: [],
			bannerlink: null,
		}
	}

	async componentDidMount() {
		// Suppliers Details
		if ( this.props.vendorInfos.status === 'ok' ) {
			const getSupplierDetails = this.props.vendorInfos;
			this.setState({
				bannerImage: getSupplierDetails.data.BannerArrayImage,
				bannerApi: true,
			})
		}
	}

	render() {
		if (!this.state.bannerApi) {
			return null;
		}

		const params = {
			navigation: {
				nextEl: '.swiper-button-next.swiper-button-white', // Add your class name for next button
				prevEl: '.swiper-button-prev.swiper-button-white' // Add your class name for prev button
			},
			autoplay: {
				delay: 8000,
			},
			spaceBetween: 0,
			loop: this.state.bannerImage.length > 1 ? true : false,
 			lazy: true,
 			preloadImages: false,
 			watchOverflow: true,
		}

		return (
		<div className="banner-supplier">
			{
				(this.state.bannerImage.length > 0) ?
				<Swiper
					{...params}
					wrapperClass={'SwiperBanner'}
					spaceBetween={0}
					slidesPerView={1}
					onSlideChange={() => console.log('slide change')}
					onSwiper={(swiper) => console.log(swiper)}
				>
					{

						(this.state.bannerApi && this.state.bannerImage.length > 0) ?
							this.state.bannerImage.map((data, index) => {
								return (
									<div
										className="banners swiper-slide swiper-lazy"
										// style={{ backgroundImage: `url(${data.BannerImage})` }}
										data-background={data.BannerImage}
										key={index}
									>
										<div className="swiper-lazy-preloader"></div>
										{
										(data.BannerLink) ?
										<a href={data.BannerLink} rel="noopener noreferrer">
											<img
												alt='banner'
												className="swiper-lazy"
												data-src={data.BannerImage}
											/>
											<div className="swiper-lazy-preloader"></div>
										</a>
										:
										<div>
											<img
												alt='banner'
												className="swiper-lazy"
												data-src={data.BannerImage}
											/>
											<div className="swiper-lazy-preloader"></div>
										</div>
										}
									</div>
								);
							})
							: null
					}
				</Swiper>
				: null
			}
		</div>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

export default connect( mapStateToProps, null )(Banner);