import React, { useState } from "react";
// import { Link } from 'react-router-dom';
import { Button, FormGroup, FormControl, Form } from "react-bootstrap";
import webservice from '../../services/webservice';
import { Api } from '../../common/variables';

// Language
import utils from '../../common/utils';

export default function ForgotPassword( props ) {
	const [token, setToken] = useState( '' );
	const [resetValidTokenerror, serverValidTokenCheckError] = useState('');

	const handleValidToken = async ( event ) => {
		event.preventDefault();

		if ( token === '' || token.length !== 6 ) {
			serverValidTokenCheckError( utils.Language( 'lang_error_validation_code', props.language ) );
			return;
		} else {
			serverValidTokenCheckError( '' );
		}

		//user authentication
		let credentials = {
			mobileNumber : props.mobilenumber,
			validationCode : token
		}

		// Validate token
		let authentication = await webservice.httpPost( Api.method.checkValidateCode, credentials );
		if ( authentication.status === 'ok' ) {
			let userDetail = {
				'userID' : authentication.data.ID,
				'Email' : authentication.data.Email,
				'FullName' : authentication.data.FullName,
				'User_Type' : authentication.data.User_Type,
				'Birth_Date': authentication.data.Birth_Date,
				'Gender' : authentication.data.Gender,
				'Mobile_Number': authentication.data.Mobile_Number,
				'token' : authentication.token
			}

			// store login history of user
			let params = {
				'Platform' : 2,
				'AuthStatus' : 1,
				'ip' : props.ipaddress,
				'UserId' : authentication.data.ID,
				'Device_Browser' : props.Device_Browser
			}
			await webservice.httpPost( Api.method.getUserLoginHistory, params );

			await localStorage.setItem( 'userdetail', JSON.stringify( userDetail ) );
			document.location.href = Api.backEndapiUrl + 'set-session/?token='+ authentication.token +'&type=1';
		} else {
			serverValidTokenCheckError( utils.Language( 'lang_invalid_validation_code', props.language ) );
		}
	}

	return (
		<div>
			<div className="modalHeader">
				<Button className="close" onClick={ props.exitPopup }>&times;</Button>
				<p>{ utils.Language( 'lang_validateCode', props.language ) }</p>
			</div>
			<div className="modalBody">
				<div className="validatecode">
					<div className="validateCode">
						<p>{ utils.Language( 'lang_validateCode_title', props.language ) }</p>

						<form onSubmit={handleValidToken}>
							<FormGroup controlId="email">
								<Form.Label>{ utils.Language( 'lang_validateCode', props.language ) } <span className="error-msg">*</span></Form.Label>
								<FormControl
								autoFocus
								type="number"
								value={token}
								onChange={e => setToken(e.target.value)}
								className="inputBox"
								maxLength="9"
								/>
								<div className="error-msg"><p>{ resetValidTokenerror }</p></div>
							</FormGroup>
							<div className="btnWrapper">
								<Button className="btn btn-success" type="submit">{ utils.Language( 'lang_validate', props.language ) }</Button>
								<Button className="btn btn-danger mrg-lft-20" onClick={ props.signInSection }>
									{ utils.Language( 'lang_Cancel', props.language ) }
								</Button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}