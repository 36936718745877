// About Us js
import React, {Component} from 'react';
// import { Link } from 'react-router-dom';
import webservice from '../services/webservice';
import { Api } from '../common/variables';
import renderHTML from 'react-render-html';
import MetaTags from 'react-meta-tags';
import Loader from '../common/Loader';
import { FacebookShareButton, TwitterShareButton, EmailShareButton, TwitterIcon, FacebookIcon, EmailIcon, } from "react-share";

// Language
import utils from '../common/utils';

// Redux set data
import { connect } from "react-redux";

class AboutUs extends Component {
	constructor() {
		super()
		this.state = {
			pageDetailApi : false,
			url : '',
			cacheBanner   : '' //require('../assets/images/gharbanau_banner.jpg')
		}
	}

	async componentDidMount() {
		var str = Api.backEndapiUrl;
		var strrepl = str.replace(/\/+$/, '');
		this.setState({ url : strrepl + this.props.match.url });

		const gharbanu_banner = localStorage.getItem( 'gharbanu_banner' );
		if ( gharbanu_banner ) {
			const details = JSON.parse( gharbanu_banner );
			this.setState({
				cacheBanner : details.url,
			})
		}

		let params = {
			lang     : this.props.language,
			pageSlug : this.props.match.params.slug,
			vendorID : this.props.vendorInfos.data.id
		}

		let getPageDetail = await webservice.httpPost( Api.method.getPageDetail, params );
		if ( getPageDetail.status === 'ok' ) {
			this.setState({
				pageDetailApi    : true,
				pageTitle        : getPageDetail.data.title,
				pageContent      : getPageDetail.data.content,
				metaTitle        : getPageDetail.data.meta_title,
				metaKeywords     : getPageDetail.data.meta_keywords,
				metaDescriptions : getPageDetail.data.meta_descriptions
			})
		} else {
			document.location.href = Api.backEndapiUrl;
		}

		let banner_params = {
			banner_type : 'banner-web',
			lang        : this.props.language
		}

		let banner = await webservice.httpPost( Api.method.getHomeSlider, banner_params );
		if ( banner.status === 'ok' ) {
			this.setState({
				bannerApi: true,
				banner: banner.data.banners
			})

			// Check banner is changed or not
			if ( banner.data.banners.length > 0 ) {
				var url = banner.data.banners[banner.data.banners.length-1];
				var newBannerName = url.substring( url.lastIndexOf('/') + 1 );

				var cacheBanner = this.state.cacheBanner;
				var cacheBannerName = cacheBanner.substring( cacheBanner.lastIndexOf('/') + 1 );

				if ( newBannerName !== cacheBannerName ) {
					localStorage.setItem( 'gharbanu_banner', JSON.stringify( { 'url' : banner.data.banners[banner.data.banners.length-1] } ) );
				}

				if ( ! gharbanu_banner || newBannerName !== cacheBannerName ) {
					this.setState({
						cacheBanner : banner.data.banners[banner.data.banners.length-1]
					})
				}
			}
		}
	}

	render() {
		return (
			<div className="aboutPage">
				{/*For Meta tags Dynamic content*/}
				<MetaTags>
					<title>{ this.state.metaTitle ? this.state.metaTitle : 'Gharbanau - A marketplace for construction materials for Nepal' }</title>
					<meta name="keywords" content={ this.state.metaKeywords ? this.state.metaKeywords : 'about, gharbanau' } />
					<meta
					name="description"
					content={ this.state.metaDescriptions ? this.state.metaDescriptions : 'Gharbanau - A marketplace for construction materials for Nepal' }
					/>
					<meta property="og:url" content={ window.location.href } />
					<meta property="og:type" content="Page" />
					<meta property="og:description" content={ this.state.metaDescriptions ? this.state.metaDescriptions : 'Gharbanau - A marketplace for construction materials for Nepal' } />
					<meta property="og:title" content={ this.state.pageTitle ? this.state.pageTitle : 'Page' } />
				</MetaTags>
				{
					( this.state.pageDetailApi ) ?
					<div className="aboutwrap">
						<div className="image_sec" style={{backgroundImage: `url(${this.state.cacheBanner})`}}>
							<img src={ this.state.cacheBanner } alt="banner" />
						</div>

						<div className="innerContent">
							<div className="wrapper wrapper_1200">
								{ ( this.state.pageTitle ) ? <h2 className="innerContent-title">{ this.state.pageTitle } </h2> : null }
								
								{ ( this.state.pageContent ) ? renderHTML( this.state.pageContent ) : null }
							</div>
							
							<div className="socialShareWrap">
									<span className="social-text">
									{utils.Language('lang_socialShare', this.props.language)}
									</span>
									<EmailShareButton url={ this.state.url }>
										<EmailIcon size={20} round={false} />
									</EmailShareButton>
									<FacebookShareButton url={ this.state.url }>
										<FacebookIcon size={20} round={false} />
									</FacebookShareButton>
									<TwitterShareButton url={ this.state.url }>
										<TwitterIcon size={20} round={false} />
									</TwitterShareButton>
									{/* <PinterestShareButton url={ this.state.url }>
										<PinterestIcon size={20} round={false} />
									</PinterestShareButton> */}
								</div>
							
						</div>
					</div>
					: <Loader />
				}
				
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

export default connect( mapStateToProps, null )(AboutUs);