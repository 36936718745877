// Set location
export const setGeoLocation = geoLocation => (
	{
		type: "GEO_LOCATION",
		geoLocation: geoLocation,
	}
);

// Cart Count
export const setCartCount = cartCount => (
	{
		type: "CART_COUNT",
		cartCount: cartCount,
	}
);

// ALert message
export const setAlertMessage = alertMessages => (
	{
		type: "ALERT_MESSAGES",
		alertMessages: alertMessages,
	}
);

// Set language
export const setCurrentLanguage = Language => (
	{
		type: "SET_LANGUAGE",
		language: Language,
	}
);

// Set language
export const setUserType = userType => (
	{
		type: "SET_USER_TYPE",
		userType: userType,
	}
);

// Set VendorID
export const setVendorInfos = vendorInfos => (
	{
		type: "SET_VENDOR_INFOS",
		vendorInfos: vendorInfos,
	}
);