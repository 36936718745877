
// FindSuppliers js
import React, { Component } from 'react';
import webservice from '../services/webservice';
import StarRatings from 'react-star-ratings';
import { Api } from '../common/variables';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import Lightbox from 'react-image-lightbox';
import { isMobile } from 'react-device-detect';
import noImage from '../assets/images/icons8-company-100.png';

// Redux set data
import { connect } from "react-redux";

// Language
import utils from '../common/utils';

class FindSuppliers extends Component {
	constructor() {
		super()
		this.state = {
			limit: 25,
			Offset: 0,
			totalSuppliers: 0,
			photoIndex: 0,
			isOpen: false,
			clicked: false,
			loader: false,
			searchclicked: false,
			findSupplierApi: false,
			isUserLogin: false,
			getFindSuppliers: null,
			logo_fullpath: null,
			searchPlaceText: '',
			searchTexts: '',
			searchSuggestion : [],
			cacheBanner: '', //require('../assets/images/gharbanau_banner.jpg')
			// defaultSortBy: 'distance-nearest-first',
			defaultSortBy: 'latest-updated',
			addClass: null,
			alreadyPressed: false,
		};
		this.supplierFilter = this.supplierFilter.bind(this);
		this.supplierFilterText = this.supplierFilterText.bind(this);
	}

	toggle = (newclass) => {
		this.setState({
            //this.setState({addClass: newclass === this.state.addClass ? null : newclass});
			addClass: newclass,
			alreadyPressed: this.state.addClass === newclass ? !this.state.alreadyPressed : true
		});
	}

	async componentDidMount() {
		window.scrollTo({top: 0});

		if (isMobile) {
			// window.location="gharbanauapp://SupplierList";
			if (document.getElementById("appLink")) {
				document.getElementById("appLink").href = "gharbanauapp://SupplierList";
			}
		}

		var x = document.getElementById("footer_bottom-right");
		if ("none" === x.style.display) {
			x.style.display = "block";
		}

		const userdetails = localStorage.getItem('userdetail');
		if (userdetails) {
			const details = JSON.parse(userdetails);
			if (details.userID) {
				this.setState({
					isUserLogin: true,
				});
			}
		}

		let data = {
			SortBy: this.state.defaultSortBy,
			lang: this.props.language,
			limit: this.state.limit,
			Offset: this.state.Offset,
			// Latitude: this.props.geoLocation.latitude,
			// Longitude: this.props.geoLocation.longitude
		}

		let getFindSuppliers = await webservice.httpPost(Api.method.getFindSuppliers, data);

		if ('ok' === getFindSuppliers.status) {
			this.setState({
				findSupplierApi: true,
				getFindSuppliers: getFindSuppliers.data,
				Offset: getFindSuppliers.data.length,
				totalSuppliers: getFindSuppliers.total_records
			})
		}

		let params = {
			lang: this.props.language,
			pageSlug: 'find-suppliers',
		}

		let getPageDetail = await webservice.httpPost(Api.method.getPageDetail, params);

		if (getPageDetail.status === 'ok') {
			this.setState({
				findSupplierApi: true,
				pageTitle: getPageDetail.data.title,
			})
		}

		this.setState({
			searchPlaceText: utils.Language('lang_searchSuppliers', this.props.language)
		});
	}

	// Filter suppliers List 
	async supplierFilter( suggestion = null ) {
		if (this.state.searchclicked) {
			return false;
		} else {
			this.setState({
				searchclicked: true
			})
		}

		let searchValue = this.searchValue.value.trim();
		// if ( suggestion ) {
		// 	searchValue = suggestion;
		// 	this.setState({
		// 		searchTexts: suggestion.replace(/[^a-zA-Z0-9\s.]/g, "")
		// 	})
		// }
		// if ( '' !== searchValue ) {
		let data = {
			Offset: 0,
			Keyword: searchValue,
			SortBy: this.menu.value,
			limit: this.state.limit,
			lang: this.props.language,
			Latitude: this.props.geoLocation.latitude,
			Longitude: this.props.geoLocation.longitude
		}

		if ('' === searchValue) {
			data.Keyword = '';
		}

		let getFindSuppliers = await webservice.httpPost(Api.method.getFindSuppliers, data);
		if ('ok' === getFindSuppliers.status) {
			this.setState({
				findSupplierApi: true,
				searchclicked: false,
				getFindSuppliers: getFindSuppliers.data,
				Offset: getFindSuppliers.data.length,
				totalSuppliers: getFindSuppliers.total_records
			})
		}
		// }
	}

	async supplierFilterText( suggestion = null ) {
		if (this.state.searchclicked) {
			return false;
		} else {
			this.setState({
				searchclicked: true
			})
		}

		let searchValue = this.searchValue.value.trim();
		if ( suggestion ) {
		searchValue = suggestion;
		this.setState({
			searchTexts: suggestion
		})
		}
		// if ( '' !== searchValue ) {
		let data = {
			Offset: 0,
			Keyword: searchValue,
			SortBy: this.menu.value,
			limit: this.state.limit,
			lang: this.props.language,
			Latitude: this.props.geoLocation.latitude,
			Longitude: this.props.geoLocation.longitude
		}

		if ('' === searchValue) {
			data.Keyword = '';
		}

		let getFindSuppliers = await webservice.httpPost(Api.method.getFindSuppliers, data);
		if ('ok' === getFindSuppliers.status) {
			this.setState({
				findSupplierApi: true,
				searchclicked: false,
				getFindSuppliers: getFindSuppliers.data,
				Offset: getFindSuppliers.data.length,
				totalSuppliers: getFindSuppliers.total_records
			})
		}
		// }
	}

	// Loadmore suppliers list
	loadMoreSuppliers = async () => {
		let searchValue = this.searchValue.value;
		this.setState({
			clicked: true,
			loader: true,
		});

		let params = {
			Keyword: searchValue,
			SortBy: this.menu.value ? this.menu.value : this.state.defaultSortBy,
			limit: this.state.limit,
			Offset: this.state.Offset,
			lang: this.props.language,
			Latitude: this.props.geoLocation.latitude,
			Longitude: this.props.geoLocation.longitude
		}

		let LoadmoreSuppliers = await webservice.httpPost(Api.method.getFindSuppliers, params);
		if ('ok' === LoadmoreSuppliers.status) {
			//update all suppliers
			let UpdateProducts = [...this.state.getFindSuppliers];
			let loadMoreProductLists = UpdateProducts.concat(LoadmoreSuppliers.data);

			this.setState({
				findSupplierApi: true,
				clicked: false,
				loader: false,
				getFindSuppliers: loadMoreProductLists,
				Offset: loadMoreProductLists.length,
				totalSuppliers: LoadmoreSuppliers.total_records
			})
		}
	}

	// save search input value
	saveSearch = async (e) => {
		this.setState({
			searchTexts: e.replace(/[^a-zA-Z0-9\s.]/g, "")
		})

		if ( e.replace( /[^a-zA-Z0-9\s.]/g, "" ) ) {
			let params = {
				'lang' : this.props.language,
				'vendorID': this.props.vendorInfos.data.id,
				'keyword' : e.replace( /[^a-zA-Z0-9\s.]/g, "" )
			}
			const getSearchSuggestion = await webservice.httpPost( Api.method.getSupplierSearchSuggestion, params );
			if ( 'ok' === getSearchSuggestion.status ) {
				this.setState({
					searchSuggestion: getSearchSuggestion.data
				})
			}
		} else {
			this.onBlurStatus();
		}
	}

	onBlurStatus = () => {
		setTimeout( () => {
			this.setState({
				searchSuggestion: [],
			})
		}, 100 );
	}

	showLoginPopup() {
		document.getElementById("LoginPopup").click();
	}

	renderSuppliers() {
		const category = [];
		for (let i = 0; i < 24; i++) {
			// Try avoiding the use of index as a key, it has to be unique!
			category.push(
				<div className="suppliers-list-wrapper col-12 col-sm-6 col-md-4 col-lg-2" key={i}>
					<div className="suppliers-list" style={{ boxShadow: 'none', alignItems: 'center', display: 'flex', flexDirection: 'column', paddingBottom: 20, }}>
						<div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100px', }}>
							<img src={require('../assets/images/placeholder.png')} alt="productsimage" style={{ width: '30px' }} />
						</div>
						<div style={{ width: '70%', backgroundColor: '#ddd', height: '10px', borderRadius: '3px', marginBottom: '5px' }}>
						</div>
						<div style={{ width: '50%', backgroundColor: '#ddd', height: '10px', borderRadius: '3px', }}>
						</div>
					</div>
				</div>
			);
		}
		return category;
	}

	render() {
		const { photoIndex, isOpen } = this.state;
		let tagClass = ["product-name-taglist-all"];
		if(this.state.addClass) {
			tagClass.push('show-all-tag');
		}

		return (
			<div className="supplierPage">
				{/*For Meta tags Dynamic content*/}
				<MetaTags>
					<title>
						{this.state.pageTitle ? 'Gharbanau.com - ' + this.state.pageTitle : 'Gharbanau.com'}
					</title>
					<meta name="keywords" content="find suppliers, Gharbanau" />
					<meta
						name="description"
						content="A marketplace for construction materials for Nepal"
					/>
				</MetaTags>

				<div className="wrapper wrapper_1200">
					<div className="suppliers-contents">
						<div className="suppliers-header">
							<h2 className="name catTitle">
								{
									(this.state.totalSuppliers) ?
										<span>{this.state.totalSuppliers}</span>
										: null
								} {utils.Language('lang_Suppliers', this.props.language)}
							</h2>
							<div className="section_sort supplier_sort">
								<div className="section_sort-div">
									{/* <select defaultValue="distance-nearest-first" ref={(input) => this.menu = input} onChange={this.supplierFilter}> */}
									<select defaultValue="latest-updated" ref={(input) => this.menu = input} onChange={this.supplierFilter}>
										<option value="latest-updated">{utils.Language('lang_allSuppliers', this.props.language)}</option>
										<option value="verified">{utils.Language('lang_verifiedInDropDown', this.props.language)}</option>
										{/* <option value="distance-nearest-first">{utils.Language('lang_nearest_to_farthest', this.props.language)}</option> */}
									</select>
								</div>
							</div>
							<div className={"search_wrapper " + ('error' === this.state.type ? 'search_error' : '')}>
								<input type="text" className="search_input" placeholder={this.state.searchPlaceText} ref={el => this.searchValue = el}
									value={this.state.searchTexts || ''}
									onChange={e => { e.target.value && this.saveSearch(e.target.value) }}
									onKeyPress={event => {
										if (event.key === 'Enter') {
											this.supplierFilterText();
										}
									}}
									onBlur={() => {
										this.onBlurStatus();
									}}
								/>
								{
									this.state.searchTexts ?
									<button className="btn btn-radius search-btn" onClick={this.supplierFilterText}><span className="icon_search"></span></button>
									:
									<button disabled={true} className="btn btn-radius search-btn"><span className="icon_search"></span></button>
								}
								<ul className="autocompletelist">
								{
								( this.state.searchSuggestion && this.state.searchSuggestion.length > 0 ) ?
									this.state.searchSuggestion.map( (suggestion, index) => {
										return (
										<li className="searchsuggestion-list" key={index} onClick={ ( e ) => { this.supplierFilterText( suggestion ) } }>
										{ suggestion }
										</li>
										)
									})
								: null
								}
								</ul>
							</div>
						</div>

						<div className="row">
							{
								(this.state.findSupplierApi) ?
									this.state.getFindSuppliers.map((item, index) => {
										let supplierTag = index;

										return (
											<div className="suppliers-list-wrapper col-12 col-sm-6 col-md-4 col-lg-2" key={index}>
												<div className="suppliers-list">
													<div className={'top-section'}>
														{
															(item.logo_fullpath) ?
																<div className="suppliers-top suppliers-logo">
																	{(1 === parseInt(item.vendor_verified)) ? <span className="verified small-verified varified"><span className="verified-div"><img src={require('../assets/images/certifications.png')} alt="certificate" className="verified-logo" />{utils.Language('lang_verified_supplier', this.props.language)}</span></span> : null}				
																</div>
																:
																<div className="suppliers-top no-logo">
																	{(1 === parseInt(item.vendor_verified)) ? <span className="verified small-verified varified"><span className="verified-div"><img src={require('../assets/images/certifications.png')} alt="certificate" className="verified-logo" />{utils.Language('lang_verified_supplier', this.props.language)}</span></span> : null}
																</div>
														}
														<Link to={ isMobile ? '/suppliers/' + encodeURIComponent(item.slug) : '/suppliers/' + encodeURIComponent(item.slug) + '/products' } className="link">
															<div className="suppliers-company-wrapper">
																<div className="imgWrapper">
																	<img src={item.logo_fullpath ? item.logo_fullpath : noImage} alt="categoryimage" />
																</div>

																<div className="suppliers-company-name">{item.name}</div>
																<div className="suppliers-company-address">
																	{item.addressList}
																</div>
																
																{/*
																<div className="suppliers-phone">
																	{(item.vendor_phone && true === this.state.isUserLogin) ? <div className="soldby-phn">
																		<a className="click-to-call" href={"callto:" + this.vendor_phone}>
																			<span className="icon-mobile-alt"></span>
																			{item.vendor_phone}</a></div> : null}
																	{(item.vendor_phone_alt && true === this.state.isUserLogin) ? <div className="soldby-phn">
																		<a className="click-to-call" href={"callto:" + this.vendor_phone_alt}>
																			<span className="icon-call-answer"></span>
																			{item.vendor_phone_alt}</a></div> : null}
																</div>
																 */}
																<div className="star-ratings suppliers-company-ratings">
																	{
																		( item.rating ) ?
																			<StarRatings
																			rating={ parseFloat( item.rating ) }
																			starRatedColor="#ff6f00"
																			starHoverColor="#ff6f00"
																			numberOfStars={5}
																			name='rating'
																			starDimension="24px"
																			starSpacing="5px"
																			/>
																		: null
																	}
																</div>

																{/* commented removed google api */}
																{/* {
																	(item.distance) ?
																		<div className="suppliers-distance">
																			<span className="icon icon-map-marker-alt"></span>
																			{Number.parseFloat(item.distance).toFixed(2)} {utils.Language('lang_' + item.distanceType, this.props.language)}
																		</div>
																		:
																		null
																} */}
															</div>
														</Link>
														<div className="product-name-taglist">
															<div className={this.state.addClass === index && this.state.alreadyPressed ? 'show-all-tag' : ''}>
															{
																
																(item.categories) ? 
																item.categories.map((category,index) => {
																	return (
																		<div key={supplierTag + '_' + index} className={( 5 < index )? "product-name-tag more-tags" : "product-name-tag" }>
																		<span className="tag-name">{category.name}</span>	
																																				 
																		</div>
																	)
																})
																:null
															}
																																
															{
																(item.categories.length > 6) ?
																<span onClick={() => this.toggle(supplierTag)} className="tag-arrow"><i className="icon icon-arrow-down"></i></span>
																:null
															}
															</div>																
														</div>
														<div className="socialLink">
															{
																(item.facebook) ?
																	<a className="socialLink-icon socialLink-icon-facebook" href={item.facebook} target="_blank" rel="noopener noreferrer"><span className="icon-facebook-f"></span></a>
																	: null
															}
															{
																(item.instagram) ?
																	<a className="socialLink-icon socialLink-icon-instagram" href={item.instagram} target="_blank" rel="noopener noreferrer"><span className="icon-instagram"></span></a>
																	: null
															}
															{
																(item.twitter) ?
																	<a className="socialLink-icon socialLink-icon-twitter" href={item.twitter} target="_blank" rel="noopener noreferrer"><span className="icon-twitter"></span></a>
																	: null
															}
														</div>

														{
															(item.vendor_file && item.vendor_file.length > 0) ?
																<div className="dealership-certificate">
																	<button className="btn btn-primary" type="button" onClick={() => this.setState({ isOpen: true })}>{utils.Language('lang_DealershipCertificate', this.props.language)}</button>
																	{isOpen && (
																		<Lightbox
																			mainSrc={item.vendor_file[photoIndex]}
																			nextSrc={item.vendor_file[(photoIndex + 1) % item.vendor_file.length]}
																			prevSrc={item.vendor_file[(photoIndex + item.vendor_file.length - 1) % item.vendor_file.length]}
																			onCloseRequest={() => this.setState({ isOpen: false })}
																			onMovePrevRequest={() =>
																				this.setState({
																					photoIndex: (photoIndex + item.vendor_file.length - 1) % item.vendor_file.length
																				})
																			}
																			onMoveNextRequest={() =>
																				this.setState({
																					photoIndex: (photoIndex + 1) % item.vendor_file.length
																				})
																			}
																		/>
																	)}
																</div>
																:
																null
														}														
													</div>
													<div className="suppliers-contact-person">
														{(item.contact_fullname) ? <div className="soldby_icon soldby-seller"><span className="icon icon-user"></span> {item.contact_fullname}</div> : null}
														<div className="call_list_div">
															<span className="icon"><span className="icon-mobile-alt"></span></span>
															<div className="call_list">
																{(item.contact_phone) ? <a className="click-to-call" href={"callto:" + item.contact_phone}>{item.contact_phone}</a> : null}
																{(item.vendor_phone) ? <a className="click-to-call" href={"callto:" + item.vendor_phone}>{item.vendor_phone}</a>: null}
																{(item.vendor_phone_alt) ? <a className="click-to-call" href={"callto:" + item.vendor_phone_alt}>{item.vendor_phone_alt}</a> : null}
															</div>
														</div>
														{(item.contact_email) ? <div className="mailing soldby_icon"><a href={"mailto:" + item.contact_email} className="soldby_icon-link"><span className="icon icon-mailing"></span><span className="mailing-text">{item.contact_email}</span></a></div> : null}
													</div>
													
													 
													<div className="suppliers-contact-person call-suppliers-btn">
													<a href={(item.contact_email) ? "mailto:" + item.contact_email : "mailto:info@gharbanau.com" }>
													<button title="Send mail" className="click-to-call email-mobile cta-btn" ><span className="icon"><span className="icon icon-mailing"></span></span> {utils.Language('lang_sendMail', this.props.language)}</button>
													</a>
													<a className="click-to-call" href={(item.contact_phone) ? "callto:" + item.contact_phone :   (item.vendor_phone) ? "callto:" + item.vendor_phone :  (item.vendor_phone_alt) ? "callto:" + item.vendor_phone_alt : "callto:+977-01-4785677"   }>
													<button title="Call Gharbanau" className="click-to-call call-mobile cta-btn" ><span className="icon"><span className="icon icon-mobile-alt"></span></span> {utils.Language('lang_callNow', this.props.language)}</button>
													</a>
													</div>
															  
													 
												</div>
											</div>
										)
									})
									:
									this.renderSuppliers()
							}
						</div>
						{
							(this.state.loader) ?
								<div className="loader">Loading...</div>
							: null
						}
						{
							(parseInt(this.state.Offset) !== parseInt(this.state.totalSuppliers)) ?
								<div className="btnWrapper">
									{
										(!this.state.loader) ?
											<button type="button" className="btn btn-outline btn-outline-default btn-radius-20 btn-small-curve" onClick={this.loadMoreSuppliers} disabled={this.state.clicked}>{utils.Language('lang_viewMore', this.props.language)}</button>
											: null
									}
								</div>
								:
								null
						}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

export default connect(mapStateToProps, null)(FindSuppliers);