// News js
import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import webservice from '../services/webservice';
import { Api } from '../common/variables';
import renderHTML from 'react-render-html';

// Language
import utils from '../common/utils';

// Redux set data
import { connect } from "react-redux";

class News extends Component {
	constructor() {
		super()
		this.state = {
			limit: 6,
			offset: 0,
			loader : false,
			total_news : 0,
			clicked : false,
			allnewsList: [],
			newsAndBlogApi: false,
		}
	}

	async componentDidMount() {
		window.scrollTo({top: 0});

		var x = document.getElementById( "footer_bottom-right" );
		if ( "none" === x.style.display ) {
			x.style.display = "block";
		}

		let params = {
			lang  : this.props.language,
			limit : this.state.limit,
			offset : this.state.offset,
		}

		let getAllNews = await webservice.httpPost( Api.method.getAllNews, params );
		if ( getAllNews.status === 'ok' ) {
			this.setState({
				newsAndBlogApi : true,
				allnewsList : getAllNews.data,
				offset : getAllNews.data.length,
				total_news : getAllNews.total_news,
			})
		}
	}

	// Loadmore suppliers list
	loadMoreNews = async () => {
		this.setState({
			clicked: true,
			loader: true,
		});

		let params = {
			lang  : this.props.language,
			limit : this.state.limit,
			offset : this.state.offset,
		}

		let getAllNews = await webservice.httpPost( Api.method.getAllNews, params );
		if ( 'ok' === getAllNews.status ) {
			//update all suppliers
			let UpdateProducts = [...this.state.allnewsList];
			let loadMoreNewsLists = UpdateProducts.concat( getAllNews.data );

			this.setState({
				newsAndBlogApi : true,
				clicked        : false,
				loader         : false,
				allnewsList    : loadMoreNewsLists,
				offset         : loadMoreNewsLists.length,
				totalSuppliers : getAllNews.total_records
			})
		}
	}

	renderNewsAndBlogs() {
		const newslist = [];
		for ( let i = 0; i < 6; i++ ) {
			// Try avoiding the use of index as a key, it has to be unique!
			newslist.push(
				<div className="newsblogs-list-wrapper col-12 col-sm-6" key={i}>
					<div className="newsblogs-list" style={{boxShadow: 'none', alignItems: 'center', display: 'flex', flexDirection: 'column', paddingBottom: 20, }}>
						<div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', height: '100px',}}>
							<img src={ require('../assets/images/placeholder.png') } alt="productsimage" style={{width: '30px'}} />
						</div>
						<div style={{width: '70%', backgroundColor: '#ddd', height: '10px', borderRadius: '3px', marginBottom: '5px'}}>
						</div>
						<div style={{width: '50%', backgroundColor: '#ddd', height: '10px', borderRadius: '3px',}}>
						</div>
					</div>
				</div>
			);
		}
		return newslist;
	}

	render() {
		return (
			<div className="blogWrapper blogWrapperList">
				{/*For Meta tags Dynamic content*/}
				<MetaTags>
					<title>{ 'News and Blogs' }</title>
					<meta name="keywords" content={ 'news and blog, news, blog, gharbanau' } />
					<meta
					name="description"
					content={ 'Gharbanau - A marketplace for construction materials for Nepal' }
					/>
				</MetaTags>

				<div className="privacywrap">
					<div className="banner">
						<div className="image_sec bgColor_sec">
							<div className="bannercontent">
								<div className="wrapper wrapper_1200">
									<span className="title-small-banner">{ utils.Language( 'lang_newsAndBlog', this.props.language ) }</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="innerContent">
					<div className="wrapper wrapper_1200">
						<div className="row">
						{
							( this.state.newsAndBlogApi ) ?
								( this.state.allnewsList && this.state.allnewsList.length > 0 ) ?
									this.state.allnewsList.map( ( items, index ) => {
									return (
										<div className="col-sm-6" key={ index }>
											<div className="blog-wraper">
												{
													( items.Featured_Image ) ?
													<div className="news-images">
													<Link to={'/news/'+encodeURIComponent( items.slug )} className="link">
														<img src={ items.Featured_Image } alt="images" />
													</Link>
													</div>
													: null
												}
												<div className="news-content">
													<div className="news-content-top">
														<Link to={'/news/'+encodeURIComponent( items.slug )} className="link">
														<h2>{ items.title }</h2>
														<div className="user-date-wrap">
															{
															// <div className="user-profile-wrap">
															// 	{
															// 		( items.created_user_img ) ?
															// 			<span className="icon-user-wrap"><img src={ items.created_user_img } alt="userimages" /></span>
															// 		:
															// 			<span className="icon-user-wrap"><i className="icon-user"></i></span>
															// 	}
															// 	{ items.created_by }
															// </div>
															}
															<span className="date-publish">
															<span className="icon-date-wrap"><i className="icon-calendar-alt"></i></span>{ items.created_date }</span>
														</div>
														{
															( items.description ) ?
															<div className="description">
																{ renderHTML( items.description ) }
															</div>
															: null
														}
														</Link>
													</div>

													<div className="news-content-bot">
													<Link to={'/news/'+encodeURIComponent( items.slug )} className="link"><span className="btn btn-blue">{ utils.Language( 'lang_readMore', this.props.language ) }</span></Link>
													{
														( items.news_source || items.news_url ) ?
															<a href={ items.news_url } target="_blank" rel="noopener noreferrer" className="blog-link">{ items.news_source }</a>
														: null
													}
													</div>
												</div>
											</div>
										</div>
									)
								})
								: <h2>{ utils.Language( 'lang_newsNotFound', this.props.language ) }</h2>
							: this.renderNewsAndBlogs()
						}
						</div>
						{
							( this.state.loader ) ? 
							<div className="loader">Loading...</div>
							: null
						}
						{
							( parseInt( this.state.offset ) !== parseInt( this.state.total_news ) ) ?
							<div className="btnWrapper">
								{
									( ! this.state.loader ) ?
										<button type="button" className="btn btn-outline btn-outline-default btn-radius-20 btn-small-curve" onClick={ this.loadMoreNews } disabled={ this.state.clicked }>{ utils.Language( 'lang_viewMore', this.props.language ) }</button>
									: null
								}
							</div>
							:
							null
						}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

export default connect( mapStateToProps, null )(News);