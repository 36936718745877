// ProductMeta js
import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import { FormControl } from "react-bootstrap";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
// import noImage from '../../assets/images/no-image-available.png';
import Popup from "reactjs-popup";
import renderHTML from 'react-render-html';
// import Lightbox from 'react-image-lightbox';
import { FacebookShareButton, TwitterShareButton, EmailShareButton, TwitterIcon, FacebookIcon, EmailIcon, } from "react-share";

// Language
import utils from '../../common/utils';

class ProductMeta extends Component {
	constructor(props) {
		super(props)
		this.state = {
			productquantity: this.props.productdata.minorder,
			open: false,
			isOpen: false,
			isUserLogin: this.props.isUserLogin,
			photoIndex: 0,
			maxQty: 999999,
			photoIndexCertificate: 0,
			isOpenCertificate: false,
		}
	}

	openModalPopup = (e) => {
		this.setState({ open: true });
	}

	closeModalPopup = (e) => {
		this.setState({ open: false });
	}

	// on default qty change
	handleQtyChange = event => {
		let { value, min, max } = event.target;
		value = Math.max(Number(min), Math.min(Number(max), Number(value)));

		this.setState({ productquantity: value });
	}

	// On Quantity plus sign click increase qty
	stepQtyIncrement = () => {
		// document.getElementById('qtyUpdate').stepUp();
		// this.setState({ productquantity: this.state.productquantity + 1 });
		if (this.state.productquantity !== this.state.maxQty) {
			this.setState(prevState => ({
				productquantity: parseInt(this.state.productquantity) + 1
			}));
		}
	}

	// On Quantity minus sign click decrease qty
	stepQtyDecrement = () => {
		// document.getElementById('qtyUpdate').stepDown();
		// this.setState({ productquantity: this.state.productquantity - 1 });
		if (this.state.productquantity !== this.props.productdata.minorder) {
			this.setState(prevState => ({
				productquantity: parseInt(this.state.productquantity) - 1
			}));
		}
	}

	showLoginPopup() {
		document.getElementById("LoginPopup").click();
	}

	render() {
		// const { photoIndex, isOpen } = this.state;
		// const { photoIndexCertificate, isOpenCertificate } = this.state;

		return (
			<div className="col-lg-7 col-md-6">
				<div className="row">
					<div className="col-sm-12 col-lg-6">
						<div className="singletitlesection">
							<h1 className="product-title">{this.props.productdata.name}</h1>
							<div className="singleRating">
								<div className="singleRating-wrapper">
									{
										( this.props.rating ) ?
										<>
											<StarRatings
											rating={ parseFloat( this.props.rating ) }
											starRatedColor="#ff6f00"
											starHoverColor="#ff6f00"
											numberOfStars={5}
											name='rating'
											starDimension="20px"
											starSpacing="1px"
											/>
											<span style={{paddingRight: '10px'}}></span>
										</>
										: null
									}
								</div>

								{
									('seller' !== this.props.userType) ?
										(true === this.props.wishlistStatus) ?
											<span className="icon-heart icon-heart-red icon-fav" onClick={() => { this.props.removeFromWishlist(this.props.productdata.itemId, 'singleproduct') }}></span>
											:
											<span className="icon-heart icon-fav" onClick={() => { this.props.addToWishlist(this.props.productdata.itemId, 'singleproduct') }}></span>
										: null
								}
							</div>

							<div className="brandWrapper">
								{
									(this.props.productdata.brand) ?
										<div className="singleBrands">
											<span>{utils.Language('lang_brand', this.props.language)}:</span> {this.props.productdata.brand}
										</div>
										: null
								}

								{
									(this.props.productdata.update_date) ?
										<div className="singleBrands update_date">
											<span>{utils.Language('lang_lastUpdatedOn', this.props.language)}:</span>{this.props.productdata.update_date}
										</div>
										: null
								}

								<div className="socialShareWrap">
									<span className="social-text">
										{utils.Language('lang_socialShare', this.props.language)}
									</span>
									<EmailShareButton url={this.props.shareUrl}>
										<EmailIcon size={20} round={false} />
									</EmailShareButton>
									<FacebookShareButton url={this.props.shareUrl}>
										<FacebookIcon size={20} round={false} />
									</FacebookShareButton>
									<TwitterShareButton url={this.props.shareUrl}>
										<TwitterIcon size={20} round={false} />
									</TwitterShareButton>
									{/* <PinterestShareButton url={this.props.shareUrl}>
										<PinterestIcon size={20} round={false} />
									</PinterestShareButton> */}
								</div>

								{
									(this.props.productdata.categoryinfo) ?
										<div className="categoryinfo">
											<button className="button" onClick={this.openModalPopup}><span className="icon icon-info-circle"></span></button>
											<Popup
												open={this.state.open}
												closeOnDocumentClick
												onClose={this.closeModalPopup}
											>
												<div className="PopupBox">
													<div className="modalHeader">
														<button className="close" onClick={this.closeModalPopup}>&times;</button>
														<p>{utils.Language('lang_techInfo', this.props.language)}</p>
													</div>
													<div className="modalBody">
														{renderHTML(this.props.productdata.categoryinfo)}
													</div>
												</div>
											</Popup>
										</div>
										: null
								}
							</div>
						</div>
						<div className="detailsSec">
							<strong>{utils.Language('lang_stockStatus', this.props.language)}: </strong> <span className="instock">{(this.props.productdata.itemInStock) ? utils.Language('lang_inStock', this.props.language) : utils.Language('lang_outStock', this.props.language)}</span>
							<div className="singleprice">
								{
									(this.props.productdata.regularPrice !== this.props.productdata.salePrice && this.props.productdata.salePrice !== '0.00') ?
										<div>
											<div className="regularprice">{utils.Language('lang_currency', this.props.language)} {new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format(this.props.productdata.regularPrice)}</div>
											<div className="saleprice">
												{utils.Language('lang_currency', this.props.language)} {new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format(this.props.productdata.salePrice)}
												{
													(this.props.productdata.salePriceValidTill) ?
														<div className="singleBrands validtilldate">
															<span className="smallText">({utils.Language('lang_priceValidTill', this.props.language)} {this.props.productdata.salePriceValidTill})</span>
														</div>
														: null
												}
												{
													( this.props.productdata.updated_days ) ?
														<span className="updatedaysText">{ this.props.productdata.updated_days }</span>
													: null
												}
												<span className="smallText">{utils.Language('lang_taxText', this.props.language)}</span>
											</div>
										</div>
										:
										<div>
											<div className="saleprice">
												{utils.Language('lang_currency', this.props.language)} {new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format(this.props.productdata.regularPrice)}
												{
													( this.props.productdata.updated_days ) ?
														<span className="updatedaysText">{ this.props.productdata.updated_days }</span>
													: null
												}
												<span className="smallText">{utils.Language('lang_taxText', this.props.language)}</span>
											</div>
										</div>
								}
								{this.props.productdata.units}
							</div>
							{
								('seller' !== this.props.userType) ?
									<div className="singlebtnSection">
										<div className="singlebtnSectionLeft">
											{utils.Language('lang_qty', this.props.language)} :
											<button type="button" className="button-number button-minus" data-field="quantity" onClick={this.stepQtyDecrement}><span></span></button>
											<FormControl
												id="qtyUpdate"
												type="number"
												value={this.state.productquantity}
												className="inputBox"
												min={this.props.productdata.minorder}
												max={this.state.maxQty}
												onChange={this.handleQtyChange}
											/>
											<button type="button" className="button-number button-plus" data-field="quantity" onClick={this.stepQtyIncrement}><span></span></button>
										</div>

										<div className="singlebtnSectionRight">
											<button type="button" className="btn btn-outline-danger" onClick={(id, qty) => { this.props.addItemToCart(this.props.productdata.itemId, this.state.productquantity, 'cart/') }}>{utils.Language('lang_buyNow', this.props.language)}</button>
											<button type="button" className="btn btn-danger" onClick={(id, qty) => { this.props.addItemToCart(this.props.productdata.itemId, this.state.productquantity, '') }}><span className="icon-cart-add"></span>{utils.Language('lang_addToCart', this.props.language)}</button>
										</div>
									</div>
									: null
							}
						</div>
					</div>

					<div className="col-sm-12 col-lg-6">
						<div className="deliveryoptions">
							<Tabs defaultActiveKey="tab1" id="uncontrolled-tab-example">
								<Tab eventKey="tab1" title={utils.Language('lang_tabDelivery', this.props.language)}>
									<div className="deliveryoptions-wrapper">
										<div className="delivery-list-div">
											<div className="soldby-title">{utils.Language('lang_Delivery', this.props.language)}</div>
											<div className="delivery-list">
												<div className="delivery-list1">
													<span className="icon icon-truck"></span>
													<div className="deliver-name">{utils.Language('lang_homeDelivery', this.props.language)} <span className="smallText">{this.props.deliveryOptions.deliveryTime ? this.props.deliveryOptions.deliveryTime + utils.Language('lang_Days', this.props.language) : '-'}</span>
														{(this.props.productdata.shippingType) ? <div>{utils.Language('lang_shippingText', this.props.language)}: {this.props.productdata.shippingType}</div> : null}
														{(this.props.productdata.shippingInfo) ? <div>{this.props.productdata.shippingInfo}</div> : null}
													</div>
												</div>
											</div>
										</div>
									</div>
								</Tab>
								<Tab eventKey="tab2" title={utils.Language('lang_tabWarranty', this.props.language)}>
									<div className="deliveryoptions-wrapper">
										<div className="warranty-div">
											<div className="soldby-title">{utils.Language('lang_Warranty', this.props.language)}</div>
											<div className="warranty-list">
												<div className="warranty-list1">
													<span className="icon-tick"></span>
													<div className="warranty-text">{utils.Language('lang_WarrantyPeriod', this.props.language)}
														<span className="smallText">{this.props.deliveryOptions.warrantyPeriod ? this.props.deliveryOptions.warrantyPeriod : '-'}</span>
													</div>
												</div>
												<div className="warranty-list3">
													<span className="icon-tick"></span>
													<div className="warranty-text">{utils.Language('lang_WarrantyPolicy', this.props.language)}
														<span className="smallText">{this.props.deliveryOptions.warrantyPolicy ? this.props.deliveryOptions.warrantyPolicy : '-'}</span>
													</div>
													<div className="warranty-text"></div>
												</div>
											</div>
										</div>
									</div>
								</Tab>
								<Tab eventKey="tab3" title={utils.Language('lang_tabExchange', this.props.language)}>
									<div className="deliveryoptions-wrapper">
										<div className="warranty-div">
											<div className="soldby-title">{utils.Language('lang_Exchange', this.props.language)}</div>
											<div className="warranty-list">
												<div className="warranty-list1">
													<span className="icon-tick"></span>
													<div className="warranty-text">{utils.Language('lang_ExchangePeriod', this.props.language)}
														<span className="smallText">{this.props.deliveryOptions.exchangePeriod ? this.props.deliveryOptions.exchangePeriod : '-'}
														</span>
													</div>
												</div>
												<div className="warranty-list3">
													<span className="icon-tick"></span>
													<div className="warranty-text">{utils.Language('lang_ExchangePolicy', this.props.language)}
														<span className="smallText">{this.props.deliveryOptions.exchangePolicy ? this.props.deliveryOptions.exchangePolicy : '-'}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</Tab>
							</Tabs>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ProductMeta;