// Loader js
import React, {Component} from 'react';

class Loader extends Component {
	render() {
		return (
			<div className="loader-wrapper">
				<div id="content-loader"></div>
			</div>
		);
	}
}

export default Loader;