// RelatedNews js
import React, { useState } from 'react';
import Swiper from 'react-id-swiper';
import { Link } from 'react-router-dom';
import renderHTML from 'react-render-html';

// Language
import utils from '../../common/utils';

const ManipulatingrelatedNewsSwiper = ( relatednews ) => {
	const [swiper, updateSwiper] = useState(null);

	const goNext = () => {
		if (swiper !== null) {
			swiper.slideNext();
		}
	};

	const goPrev = () => {
		if (swiper !== null) {
			swiper.slidePrev();
		}
	};

	const params = {
		slidesPerView : 2,
		loop          : ( relatednews.data.length >= 2 ) ? true : false,
		shouldSwiperUpdate: true,
        breakpoints: {
        	1200: {
                slidesPerView: 2,
        	},
        	992: {
                slidesPerView: 2,
        	},
            575: {
                slidesPerView: 2,
            },
            260: {
                slidesPerView: 2,
            },
        },
		// spaceBetween: 30,
		// pagination: {
			// el: '.swiper-pagination',
			// clickable: true,
		// }
	}

return (
	<div className="relatedProduct">
		<div className="wrapper wrapper_1200 padding_side_15">
			<h3 className="catTitle title-withline"><span className="wrapwhite">{ utils.Language( 'lang_otherNews', relatednews.language ) }</span></h3>
			<div className="por">
				<Swiper className="row" {...params} getSwiper={updateSwiper}>
				{
					relatednews.data.map( (news, index) => {
						return(
						<div className="col-sm-6 blogWrapperList" key={ index }>
							<div className="blog-wraper">
								{
									( news.Featured_Image ) ?
									<div className="news-images">
									<Link to={'/news/'+encodeURIComponent( news.slug )} onClick={ relatednews.refreshData } className="link">
										<img src={ news.Featured_Image } alt="images" />
									</Link>
									</div>
									: null
								}
								<div className="news-content">
									<div className="news-content-top">
										<Link to={'/news/'+encodeURIComponent( news.slug )} onClick={ relatednews.refreshData } className="link">
										<h2>{ news.title }</h2>
										<div className="user-date-wrap">
											{
											// <div className="user-profile-wrap">
											// 	{
											// 		( news.created_user_img ) ?
											// 			<span className="icon-user-wrap"><img src={ news.created_user_img } alt="userimages" /></span>
											// 		:
											// 			<span className="icon-user-wrap"><i className="icon-user"></i></span>
											// 	}
											// 	{ news.created_by }
											// </div>
											}
											<span className="date-publish"><span className="icon-date-wrap"><i className="icon-calendar-alt"></i></span>{ news.created_date }</span>
										</div>
										{
											( news.description ) ?
											<div className="description">
												{ renderHTML( news.description ) }
											</div>
											: null
										}
										</Link>
									</div>

									<div className="news-content-bot">
									<Link to={'/news/'+encodeURIComponent( news.slug )} onClick={ relatednews.refreshData } className="link"><span className="btn btn-blue">{ utils.Language( 'lang_readMore', relatednews.language ) }</span></Link>
									{
										( news.news_source || news.news_url ) ?
											<a href={ news.news_url } target="_blank" rel="noopener noreferrer" className="blog-link">{ news.news_source }</a>
										: null
									}
									</div>
								</div>
							</div>
						</div>
						)
					})
				}
				</Swiper>
				<button onClick={goPrev} className="prev"><span className="icon-back"></span></button>
				<button onClick={goNext} className="next"><span className="icon-next"></span></button>
			</div>
		</div>
	</div>
	);
};

export default ManipulatingrelatedNewsSwiper;