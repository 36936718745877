import React, { Component } from 'react';
// import { Route, Switch } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { Api } from '../common/variables';

// Pages list
import Mainpage from '../components/MainPage';
import AboutUs from '../pages/AboutUs';
import News from '../pages/News';
import SingleNews from '../pages/SingleNews';
import FindSuppliers from '../pages/FindSuppliers';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsAndCondition from '../pages/TermsAndCondition';
import HowToSell from '../pages/HowToSell';
import FAQs from '../pages/FAQs';
import LatestProduct from '../pages/LatestProduct';
import PriceList from '../pages/PriceList';
import Pricing from '../pages/Pricing';
import Cart from '../pages/Cart';
import Page from '../pages/Page';
import Pagec from '../pages/HowToSell';
import Pageh from '../pages/AboutUs';
import Thankyou from '../pages/Thankyou';
import NotFoundPage from '../pages/404';
import SearchSite from '../pages/SearchSite';

// Product pages
import CheckOut from '../pages/Checkout';
import CategoriesList from '../pages/CategoriesList';
import CategoryProductListBySlug from '../pages/CategoriesProductList';
import SingleProduct from '../pages/SingleProduct';

// Supplies pages
import SupplierProduct from '../suppliers/SupplierProduct';
import SingleSupplier from '../pages/SingleSupplier';
import SupplierPages from '../pages/suppliers/SupplierPage';
import SupplierSingleProduct from '../suppliers/SupplierSingleProduct';
import SupplierCustomPages from '../suppliers/pages/SupplierCustomPage';
import SupplierCategoriesList from '../suppliers/CategoriesList';
import SupplierCategoryProductListBySlug from '../suppliers/CategoriesProductList';
import SupplierSearchSite from '../suppliers/SupplierSearchSite';
import SingleSupplierHome from '../pages/SingleSupplierHome';

class RouteCheck extends Component {
	render() {
		if ( Api.customUrl ) {
			return (
			<Switch>
				<Route exact path="/" component={Mainpage} />
				<Route exact path="/cart" component={Cart} />
				<Route exact path="/checkout" component={CheckOut} />
				<Route exact path="/about" component={AboutUs} />
				<Route exact path="/privacy-policy" component={PrivacyPolicy} />
				<Route exact path="/terms-and-conditions" component={TermsAndCondition} />
				<Route exact path="/how-to-sell" component={HowToSell} />
				<Route exact path="/faqs" component={FAQs} />
				<Route exact path="/latest-product" component={LatestProduct} />
				<Route exact path="/pricing" component={Pricing} />
				<Route exact path="/price-list" component={PriceList} />
				<Route exact path="/news" component={News} />
				<Route exact path="/news/:slug" component={SingleNews} />
				<Route exact path="/suppliers" component={FindSuppliers} />
				<Route exact path="/suppliers/:slug" component={SingleSupplierHome} />
				<Route exact path="/suppliers/:slug/products" component={SingleSupplier} />
				<Route exact path="/suppliers/:slug/:slug" component={SupplierPages} />
				<Route exact path="/product-categories" component={CategoriesList} />
				<Route exact path="/category/:slug" component={CategoryProductListBySlug} />
				<Route exact path="/thankyou" component={Thankyou} />
				<Route exact path="/search" component={SearchSite} />
				<Route exact path="/page/:slug" component={Page} />
				<Route exact path="/page-h/:slug" component={Pageh} />
				<Route exact path="/page-c/:slug" component={Pagec} />
				<Route exact path="/products/:slug" component={SingleProduct} />
				<Route path="*" component={NotFoundPage} />
			</Switch>
			)
		} else {
			return (
			<Switch>
				<Route exact path="/" component={Mainpage} />
				<Route exact path="/cart" component={Cart} />
				<Route exact path="/search" component={SupplierSearchSite} />
				<Route exact path="/checkout" component={CheckOut} />
				<Route exact path="/thankyou" component={Thankyou} />
				<Route exact path="/products" component={SupplierProduct} />
				<Route exact path="/products/:slug" component={SupplierSingleProduct} />
				<Route exact path="/product-categories" component={SupplierCategoriesList} />
				<Route exact path="/category/:slug" component={SupplierCategoryProductListBySlug} />
				<Route exact path="/:slug/" component={SupplierCustomPages} />
				<Route path="*" component={NotFoundPage} />
			</Switch>
			)
		}
	}
}

export default RouteCheck;