 // Thankyou js
import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import webservice from '../services/webservice';
import { Api } from '../common/variables';
// import noImage from '../assets/images/no-image-available.png';
import MetaTags from 'react-meta-tags';
import Loader from '../common/Loader';

// Language
import utils from '../common/utils';

// Redux set data
import { connect } from "react-redux";
import { setCartCount } from '../action/commonAction';

class Thankyou extends Component {
	constructor() {
		super()
		this.state = {
			orderDetailsApi : false,
			orderDetails    : null,
			Invoice_Id      : null,
			Shipping_Amount : null,
			Sub_Total       : null,
			Total_Amount    : null
		}
	}

	async componentDidMount() {

		let CartItems   = await webservice.httpPost( Api.method.cartItems );
		if ( 'ok' === CartItems.status && null !== CartItems.data ) {
			// parameter
			let params = {
				lang     : this.props.language,
				vendorID : this.props.vendorInfos.data.id
			}
			let getCartItem = await webservice.httpPost( Api.method.placeOrder, params );

			if ( getCartItem.status === 'ok' ) {
				this.setState({
					// orderDetails    : getCartItem.data,
					orderDetails    : getCartItem.data.Order_Items,
					Invoice_Id      : getCartItem.data.Invoice_Id,
					Shipping_Amount : new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format( getCartItem.data.Shipping_Amount ),
					Sub_Total       : new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format( getCartItem.data.Sub_Total ),
					Total_Amount    : new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format( getCartItem.data.Total_Amount ),
					orderDetailsApi : true,
				})
				this.props.setCartCount( 0 );		
			}
		} else {
			window.location.href = "/";
		}
	}

	render() {
		return (
			<div className="thankyouPage bgGray">
				{/*For Meta tags Dynamic content*/}
				<MetaTags>
					<title>
						Gharbanau - A marketplace for construction materials for Nepal
					</title>
					<meta name="keywords" content="thankyou, gharbanau" />
					<meta
					name="description"
					content="A marketplace for construction materials for Nepal"
					/>
				</MetaTags>

				<div className="wrapper wrapper_1200">
					{
						( this.state.orderDetails ) ?
						<div className="row">
							<div className="col-12">
								<div className="whiteBox thank-msg">
									<div className="thank-msg-lft">
										<span className="thank-msg-bigtext">{ utils.Language( 'lang_ThanksMsg', this.props.language ) }</span>
										<p>{ utils.Language( 'lang_ThanksSecondMsg', this.props.language ) } { this.state.Invoice_Id }</p>
									</div>

									<div className="thank-msg-rgt">
										<p>{ utils.Language( 'lang_MakeAmountReadyMsg', this.props.language ) }</p>
										<span className="thank-msg-bigtext">{ utils.Language( 'lang_currency', this.props.language ) } { this.state.Total_Amount }</span>
									</div>
								</div>

								{/*<div className="whiteBox">
									<div className="cart-items cart-header">
										<div className="cartItems col1 col">
											<span>Items and delivey date(s)</span>
										</div>

										<div className="cartDate col2 col">
											<span>Estimated delivery date</span>
										</div>

										<div className="cartPrice col3 col">
											<span>Pricing</span>
										</div>

										<div className="cartQty col4 col">
											<span>Qty.</span>
										</div>
									</div>

									{
										( this.state.orderDetails ) ?
											this.state.orderDetails.map( (orderlists,index) => {
												return(
												<div className="cart-items userList" key={index}>
													<div className="cartItems col1">
														<div className="hideinmobile cartproduct-image">
															<div className="imgWrapper ">
																<img src={ orderlists.imageUrl ? orderlists.imageUrl : noImage } alt="" />
															</div>
														</div>
														<div className="productdetails">
															<div className="showinmobile cartproduct-image">
																<div className="imgWrapper showinmobile">
																	<img src={ orderlists.imageUrl ? orderlists.imageUrl : noImage } alt="" />
																</div>
															</div>

															<h3>{ orderlists.name }</h3>
															<p>{ orderlists.description }</p>
															<div className="cart-shiptext">
															{ ( orderlists.vendorName ) ? <span className="cart-shipname"><span className="lightText">Shipped By :</span>{ orderlists.vendorName }</span> : null  }
															</div>
														</div>
													</div>

													<div className="cartDate col2">June 28 2019</div>

													<div className="cartPrice col3">
														<div className="price">
															{ orderlists.unit_price }
															{
																( orderlists.shipping_price !== '0.00' ) ?
																<span className="shippingprice">
																{ orderlists.shipping_price } <span className="shippingprice_text">(Shipping Price)</span>
																</span>
																:
																null
															}
															<span className="smallText">* Inclusive of all Taxes</span>
														</div>
													</div>

													<div className="cartQty col4">
														<div className="quantity">{ orderlists.quantity }</div>
													</div>									
												</div>
												)
											})
										: null
									}
								</div>

								<div className="whiteBox checkoutSummary">
									<div className="checkoutSummaryTop">
										<div className="checkoutStatus">
											<p>
												For more details, track your delivery status under <Link className="linkText-black">My Account</Link> / <Link className="linkText-black">My Order</Link>
											</p>
										</div>
										<div className="">
											<button className="btn btn-outline-primary">View Order</button>
										</div>
									</div>

									<div className="checkoutEmail">
										<span className="icon-mailing"></span>
										Please check <Link className="linkText-black">badesanjeeb@hotmail.com</Link> for the order details.
									</div>

									<div className="checkoutSummaryBottom">
										<div className="order_summary">
											<span>Order Summary</span>
											<span className="price">{ utils.Language( 'lang_currency', this.props.language ) } 5,9000</span>
										</div>

										<div className="btn-continue">
											<button className="btn btn-danger">Continue</button>
										</div>
									</div>
								</div>*/}
								<p className="emptyData"><Link to="/" className="linkText-black">{ utils.Language( 'lang_ContinueShoppingLabel', this.props.language ) }</Link></p>
							</div>						
						</div>
						: <Loader />
					}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({
	setCartCount: ( count ) => dispatch( setCartCount( count ) )
});

export default connect( mapStateToProps, mapDispatchToProps )(Thankyou);