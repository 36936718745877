import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { setGeoLocation } from '../action/commonAction';
import { connect } from "react-redux";

// Language
import utils from '../common/utils';
import {GoogleMapLink} from "../common/variables";
const _ = require("lodash");
const { compose, withProps, lifecycle } = require("recompose");
const { SearchBox } = require("react-google-maps/lib/components/places/SearchBox");
const {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	Marker
} = require("react-google-maps");

const google = window.google = window.google ? window.google : {}

// console.log('GoogleMapLink', GoogleMapLink);

const MapWithASearchBox = compose(
	withProps({
		// googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyAvL-t2e8e1GA7PT6rpUh5rqoCq5Cy8Sc8&v=3.exp&libraries=geometry,drawing,places",
		googleMapURL: GoogleMapLink,
		loadingElement: <div style={{ height: `100%` }} />,
		containerElement: <div style={{ height: `400px` }} />,
		mapElement: <div style={{ height: `100%` }} />
	}),
	lifecycle({
		componentWillMount() {
			const refs = {};

			this.setState({
				bounds: null,
				center: {
					lat: this.props.lat,
					lng: this.props.lng
				},
				markers: [
					{
						name: "Current position",
						position: {
							lat: this.props.lat,
							lng: this.props.lng
						}
					}
				],
				onMapMounted: ref => {
					refs.map = ref;
				},
				onBoundsChanged: () => {
					this.setState({
						bounds: refs.map.getBounds(),
						center: refs.map.getCenter()
					});
				},
				onSearchBoxMounted: ref => {
					refs.searchBox = ref;
				},
				onPlacesChanged: () => {
					const places = refs.searchBox.getPlaces();
					const bounds = new google.maps.LatLngBounds();

					places.forEach(place => {
						if (place.geometry.viewport) {
							bounds.union(place.geometry.viewport);
						} else {
							bounds.extend(place.geometry.location);
						}

						const lat = place.geometry.location.lat();
						const lng = place.geometry.location.lng();
						if ( lat && lng ) {
							let geoLocation = {
								latitude: lat,
								longitude: lng,
							}

							if ( this.props.signup ) {
								this.props.companyAddressLatLng( lat, lng, place.formatted_address );
							} else {
								localStorage.setItem( 'gharbanu_location', JSON.stringify( { 'location' : geoLocation } ) );
								this.props.setGeoLocation( geoLocation );
								this.props.locationChangeFunction( true );
							}

						}
					});
					const nextMarkers = places.map(place => ({
						position: place.geometry.location
					}));
					const nextCenter = _.get(
						nextMarkers,
						"0.position",
						this.state.center
					);

					this.setState({
						center: nextCenter,
						markers: nextMarkers
					});
				},
				onMarkerDragEnd: (coord, index) => {
					const { latLng } = coord;
					const lat = latLng.lat();
					const lng = latLng.lng();
					this.setState(prevState => {
						const markers = [...this.state.markers];
						markers[index] = {
							...markers[index],
							position: { lat, lng }
						};
						return { markers };
					});
				}
			});
		}
	}),
	withScriptjs,
	withGoogleMap
)(props => (
	<GoogleMap
		ref={props.onMapMounted}
		defaultZoom={13}
		center={props.center}
		onBoundsChanged={props.onBoundsChanged}
	>
		<SearchBox
			ref={props.onSearchBoxMounted}
			bounds={props.bounds}
			controlPosition={google.maps.ControlPosition.BOTTOM_LEFT}
			onPlacesChanged={props.onPlacesChanged}
		>
			<input
				type="text"
				placeholder={props.languages}
				className="searchboxfield"
				style={{
					boxSizing: `border-box`,
					border: `1px solid transparent`,
					width: `75%`,
					height: `32px`,
					marginBottom: `25px`,
					padding: `0 12px`,
					borderRadius: `3px`,
					boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
					fontSize: `16px`,
					outline: `none`,
					textOverflow: `ellipses`
				}}
			/>
		</SearchBox>
		{props.markers.map((marker, index) => (
			<Marker
				key={index}
				position={marker.position}
				draggable={true}
				onDragEnd={props.onDragMarker}
			/>
		))}
	</GoogleMap>
));

class MapPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpenMap: null,
			place: null,
			locationChange: false,
			markers1: [
				{
					name: "Current position",
					position: {
						lat: 27.6915362249657,
						lng: 85.34215052394256
					}
				}
			]
		};
	}

	openModalPopupMap = (e) => {
		if ( this.props.signup ) {
			this.props.checkStatus( true );
		}
		this.setState({ isOpenMap: true });
	}

	closeModalPopupMap = (e) => {
		this.setState({ isOpenMap: false });
		if ( this.state.locationChange && ! this.props.signup ) {
			window.location.reload();
		}
		if ( this.props.signup ) {
			this.props.checkStatus( false );
		}
	}

	onMarkerDragEnd = (coord, index) => {
		const { latLng } = coord;
		const lat = latLng.lat();
		const lng = latLng.lng();
		this.setState({
			...this.state.markers1[index],
			position: { lat, lng }
		});

		if ( lat && lng ) {
			var address = this.props;
			let geoLocation = {
				latitude: lat,
				longitude: lng,
			}

			if ( this.props.signup ) {
				this.getCompanyAddress( lat, lng, address );
			} else {
				localStorage.setItem( 'gharbanu_location', JSON.stringify( { 'location' : geoLocation } ) );
				this.props.setGeoLocation( geoLocation );
				this.setState({ locationChange: true });
			}
		}
	};

	locationChangeFunction = (e) => {
		this.setState({ locationChange: e });
	}

	getCompanyAddress = ( lat, lng, address ) => {
		var latlng = new google.maps.LatLng( lat, lng );
		var geocoder = new google.maps.Geocoder();
		geocoder.geocode({ 'latLng': latlng }, function ( results, status ) {
			if ( status === google.maps.GeocoderStatus.OK ) {
				if ( results[1] ) {
					address.companyAddressLatLng( lat, lng, results[1].formatted_address );
				}
			}
		});
	}

	render() {
		const { latitude, longitude } = this.props.geoLocation;

		return (
			<div className="map-popup">
				<button className="button" onClick={this.openModalPopupMap}>{/*<span className="icon icon-info-circle"></span>*/}
				<span className="icon-location"> </span>
				<span className="menu-label">Location</span></button>
				<Popup
				open={this.state.isOpenMap}
				closeOnDocumentClick
				onClose={this.closeModalPopupMap}
				>
					<div className="PopupBox">
						<div className="modalHeader">
							<button className="close" onClick={this.closeModalPopupMap}>&times;</button>
							<p>{ utils.Language( 'lang_SetLocation', this.props.language ) }</p>
						</div>
						<div className="modalBody">
							{/*<p><strong>Note: </strong>After changing location site is reloaded for correct distance</p>*/}
							<MapWithASearchBox
							onDragMarker={this.onMarkerDragEnd.bind(this)}
							languages={utils.Language( 'lang_searchLocation', this.props.language )}
							lat={latitude}
							lng={longitude}
							signup={this.props.signup}
							companyAddressLatLng={ (lat, lng, addrs) => this.props.companyAddressLatLng( lat, lng, addrs ) }
							setGeoLocation={this.props.setGeoLocation}
							locationChangeFunction={this.locationChangeFunction}
							/>
						</div>
					</div>
				</Popup>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({
	setGeoLocation: ( location ) => dispatch( setGeoLocation( location ) )
});

export default connect( mapStateToProps, mapDispatchToProps )(MapPopup);
