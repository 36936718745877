import { createStore } from "redux";
import commonReducer from "../reducers/commonReducer";

const initialState = {
	language      : 'En',
	cartCount     : null,
	userType      : null,
	geoLocation   : {},
	alertMessages : null,
	vendorInfos   : {},
}

function configureStore( state = initialState ) {
	return createStore( commonReducer, state );
}

export default configureStore;